const { User } = require('../../../eodvuecomponents');
const { default: Contact } = require('../../../eodvuecomponents/src/models/contact');
const { default: DataConnector } = require('../../../eodvuecomponents/src/models/dataConnector');

function getUserDetail() {
    return {
        route: 'organizationUser',
        endpoint: 'user',
        objectName: 'User',
        fields: ['id', 'companies {id name}', 'name', 'vehicle{id name}', 'acls{id topic, acc}', 'hash', 'isSuper', 'skills{id name threshold}', 'availability{startDateTime endDateTime endTimeUnspecified recurrence}', 'depot{id name description streetAddress postalCode locality region country category}', 'projects {id name company{id}}', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}', 'attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone description}'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        },
        {
            tab: 'address',
            text: 'Adres'
        },
        {
            tab: 'topics',
            text: 'MQTT Topics',
        },
        {
            tab: 'access',
            text: 'Toegang'
        },
        {
            tab: 'availability',
            text: 'Beschikbaarheid',
        },
        ],
        getTitle(item) {
            return item.firstName?(item.firstName + ' ' + item.lastName):(item.username?item.username:'');
        }
    };
}

function getUserIndex() {
    return {
        route: 'organizationUsers',
        endpoint: 'users',
        fields: ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{name}', 'createdTimestamp'],
        headers: [{
            text: 'Voornaam',
            value: 'firstName',
            component: () => import('./../../default/columns/link.vue')
        },
        {
            text: 'Familienaam',
            value: 'lastName',
            component: () => import('./../../default/columns/link.vue')
        },
        {
            text: 'Gebruikersnaam',
            value: 'username',
            component: () => import('./../../default/columns/link.vue')
        },
        {
            text: 'E-mailadres',
            value: 'email',
            component: () => import('./../../default/columns/link.vue')
        },
        {
            text: 'Rol',
            sortable: false,
            value: 'assignedRealmRoles',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-roles.vue')
        },
        {
            text: 'Aangemaakt',
            width: 250,
            value: 'createdTimestamp',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue')
        }, {
            width: 120,
            sortable: false,
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
        }
        ],
        tabs: [{
                path: 'organization',
                text: 'Algemeen',
                permissions: ['organization.general.menu']
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers',
                permissions: ['organization.employees.menu']
            },
            {
                path: 'organizationContacts',
                text: 'Contacten',
                permissions: ['organization.contacts.menu']
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors',
                permissions: ['organization.responsibles.menu']
            },
            {
                path: 'Teams',
                text: 'Teams',
                permissions: ['organization.teams.menu']
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen',
                permissions: ['organization.integrations.menu']
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.menu']
            }
        ],
    };
}

function getContactsIndex() {
    return {
        endpoint: 'users',
        fields: ['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{name}', 'createdTimestamp'],
        headers: [{
            text: 'Voornaam',
            value: 'firstName',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
        },
        {
            text: 'Familienaam',
            value: 'lastName',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
        },
        {
            text: 'Gebruikersnaam',
            value: 'username',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
        },
        {
            text: 'E-mailadres',
            value: 'email',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-edit.vue')
        },
        {
            text: 'Rol',
            sortable: false,
            value: 'assignedRealmRoles',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-roles.vue')
        },
        {
            text: 'Aangemaakt',
            width: 250,
            value: 'createdTimestamp',
            component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue')
        }, {
            width: 120,
            sortable: false,
            component: () => import('./columns/actions.vue')
        }
        ],
    };
}

module.exports = {
    routes: [
        {
            path: '/organization/users',
            name: 'organizationUsers',
            meta:{
                permissions:['organization.employees.read'],
                roles: 'collaborators'
            },
            component: () => import('./views/index.vue')
        }, {
            path: '/organization/users/detail/:id?',
            name: 'organizationUser',
            meta:{
                permissions:['organization.employees.update'],
                roles: 'collaborators'
            },
            component: () => import('./views/detail.vue')
        },
        {
            path: '/organization/contacts',
            name: 'organizationContacts',
            meta:{
                permissions:['organization.contacts.read'],
                roles: 'contacts'
            },
            component: () => import('./views/index.vue')
        }, {
            path: '/organization/contacts/detail/:id?',
            name: 'organizationContact',
            meta:{
                permissions:['organization.contacts.update'],
                roles: 'contacts'
            },
            component: () => import('./views/detail.vue')
        },
        {
            path: '/organization/connectors',
            name: 'organizationConnectors',
            meta: {
                permissions: ['organization.responsibles.read'],
                roles: 'connectors'
            },
            component: () => import('./views/index.vue')
        }, {
            path: '/organization/connectors/detail/:id?',
            name: 'organizationConnector',
            meta: {
                permissions: ['organization.responsibles.update'],
                roles: 'connectors'
            },
            component: () => import('./views/detail.vue')
        }
    ],
    search: User.searchConfig,
    itemObject: User,
    index: getUserIndex(),
    detail: getUserDetail(),
    connectors: {
        itemObject: DataConnector,
        search: User.searchConfig,
        index: {
            ...getUserIndex(),
            route: 'organizationConnectors',
            fields: ['id', 'username', 'assignedRealmRoles{name}', 'attributes{description}', 'activeJobOwner{id parent{id name} project{id name}}'],
            headers: [{
                text: 'Naam',
                value: 'username',
                component: () => import('./../../default/columns/link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'attributes.description.0',
                sortable:false,
                component: () => import('./../../default/columns/link.vue')
            },
            {
                text: 'Project',
                value: 'activeJobOwner.0.project.name',
                sortable:false,
                component: () => import('./../../default/columns/link.vue')
            },
            {
                text: 'Workflow',
                value: 'activeJobOwner.0.parent.name',
                sortable:false,
                component: () => import('./../../default/columns/link.vue')
            },
            {
                text: 'Rol',
                sortable: false,
                value: 'assignedRealmRoles',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-roles.vue')
            }
            ],
        },
        detail: {
            ...getUserDetail(),
            route: 'organizationConnector',
            tabs: [{
                tab: 'general',
                text: 'Algemeen'
            },
            {
                tab: 'configuration',
                text: 'Configuratie'
            }, 
            {
                tab: 'topics',
                text: 'MQTT Topics',
            },
            {
                tab: 'access',
                text: 'Toegang'
            },
            {
                tab: 'availability',
                text: 'Beschikbaarheid',
            },
            ],
        },
    },
    contacts: {
        itemObject: Contact,
        search: User.searchConfig,
        index: {
            ...getUserIndex(),
            ...getContactsIndex(),
            route: 'organizationContacts',
        },
        detail: {
            ...getUserDetail(),
            route: 'organizationContact',
        },
    },
    import: {
        endpoint: 'users',
        objectName: 'User',
    },
}
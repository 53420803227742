import Base from "./base";

export default class Tool extends Base {
    static searchConfig = [{
        icon: 'mdi-format-text',
        label: 'Naam',
        type: 'property',
        settings: {
            fields: ['name'],
            title: '{name}',
        },
        filter: 'tree_objects_view.name'
    },
    {
        icon: 'mdi-file-sign',
        label: 'Type',
        type: 'module',
        settings: {
            module: 'toolTypes',
            fields: ['name'],
            title: '{name}',
        },

        filter: 'tree_objects_view.type_id'
    },
    {
        icon: 'mdi-alarm',
        label: 'Wijzigingsdatum',
        type: 'date-between',
        filter: 'updatedAt'
    },
    {
        filter:'tree_objects_view.id',
        icon:'mdi-folder-open-outline',
        operator: '=',
        filterMethod:'where',
        label:'Depot',
        settings:{
            fields:['name'],
            module:'depots',
            title:'{name}'
        },
        type:'module'
    },
    {
        icon: 'mdi-calendar',
        label: 'Aanmaakdatum',
        type: 'date-between',
        filter: 'createdAt'
    },
    ];
    static exportFields = ['id', 'name', 'description', 'depot{name}', 'toolType{name}'];
    static moduleName = 'tools';
    static relations = {
        oneToMany: ['toolType']
    }
}
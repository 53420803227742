<template>
  <v-dialog v-model="value" width="1600" max-width="100%" scrollable persistent>
    <eod-card id="card_workflow_dialog" v-if="workflow">
      <v-container fluid>
        <v-row>

          <v-col cols="12" md="12" lg="4">
            <v-row>
              <v-col cols="auto">
                <v-avatar size="52" color="primary"><v-icon
                    dark>mdi-clipboard-outline</v-icon></v-avatar>
              </v-col>
              <v-col>
                <v-text-field class="mb-1" hide-details v-if="(workflow.id && typeof workflow.id != 'undefined')"
                  :value="workflow.name" @input="setWorkflowName" dense></v-text-field>
                <div class="text-h7" v-if="(workflow.id && typeof workflow.id != 'undefined')">
                  {{ workflow.taskType.name }}</div>
              </v-col>
            </v-row>

          </v-col>
          <v-col cols="12" md="12" lg="8" v-if="workflow.id">
            <v-tabs v-model="workflowTab" right>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab href="#form">
                <v-icon small left>mdi-play-outline</v-icon>
                Workflow
              </v-tab>
              <v-tab href="#company">
                <v-icon small left>mdi-office-building-outline</v-icon>
                {{ workflow.company.name }}
              </v-tab>
              <v-tab href="#project">
                <v-icon small left>mdi-folder-open-outline</v-icon>
                {{ workflow.project.name }}
              </v-tab>
              <v-tab href="#service">
                <v-icon small left>mdi-file-sign</v-icon>
                {{ workflow.service.name }}
              </v-tab>

            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="elevation-2" style="z-index:5;"></v-divider>
      <v-card-text style="" class="pt-4">
        <v-tabs-items v-model="workflowTab">
          <v-tab-item value="form" transition="fade-transition">
            <eod-workflow ref="addTaskModal" :default-planning="defaultPlanning" :mobile="mobile"  :value="workflow"
              :nameAppend="nameAppend" @saved="saved" @saving="value => { saving = value }" @loaded="workflowLoaded"
              @createWorkflow="(data, task, response) => $emit('createWorkflow', data, task, response)"></eod-workflow>
          </v-tab-item>
          <v-tab-item v-if="workflow.id" value="project" transition="fade-transition">
            <eod-project-info :value="workflow.project.id" :is-popup="false"></eod-project-info>
          </v-tab-item>
          <v-tab-item v-if="workflow.id" value="company" transition="fade-transition">
            <eod-company-info :value="workflow.company.id" :is-popup="false"></eod-company-info>
          </v-tab-item>
          <v-tab-item v-if="workflow.id" value="service" transition="fade-transition">
            <eod-service-info :value="workflow.service.id" :is-popup="false"></eod-service-info>
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
      <v-card-actions class="grey lighten-3 py-3 px-3" v-if="value">
        <v-row>
          <v-col cols=12 sm=6 md="auto">
            <v-btn text @click="$emit('input', false)" rounded block>Annuleren</v-btn>
          </v-col>
          <v-col cols=12 sm=6 md="auto" v-if="workflow && workflow.statusCode != 'done' && cell && cell.typeId == 'form' && shareUrl">
            <v-btn @click="shareDialogShown = true" rounded depressed><v-icon>mdi-share-variant</v-icon>Delen</v-btn>
            <eod-share-dialog v-if="shareDialogShown" v-model="shareDialogShown" :url="shareUrl"></eod-share-dialog>
          </v-col>
          </v-row>
          <v-spacer></v-spacer>
        <v-row style="flex-grow:0;">
          <v-col cols=12 sm=6 md="auto" v-if="workflow && workflow.statusCode != 'done' && !hideSave">
            <v-btn id="btn_save_workflow" color="secondary" depressed rounded @click="save" block :disabled="saving" :loading="saving">
              <v-icon left>mdi-content-save</v-icon> Opslaan
            </v-btn>
          </v-col>
          <v-col cols=12 sm=6 md="auto"
            v-if="workflow && workflow.statusCode != 'done' && cell && cell.typeId == 'fixed_form' && cell.settings.type == 'verify'">
            <v-btn id="btn_reject_workflow" color="error" depressed rounded @click="reject" block :disabled="saving" :loading="saving">
              <v-icon left>mdi-close-octagon-outline</v-icon> Afwijzen
            </v-btn>
          </v-col>
          <v-col cols=12 sm=6 md="auto" v-if="workflow && workflow.statusCode != 'done'">
            <v-btn id="btn_submit_workflow" color="success" depressed rounded @click="submit" block :disabled="saving" :loading="saving">
              <v-icon left>mdi-send</v-icon>
              <span
                v-if="!(workflow && workflow.id) || (cell && cell.typeId == 'fixed_form' && cell.settings.type == 'info')">Starten</span>
              <span
                v-else-if="workflow && workflow.statusCode != 'done' && cell && cell.typeId == 'fixed_form' && cell.settings.type == 'verify'">Accepteren</span>
              <span v-else>Verzenden</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </eod-card>
  </v-dialog>
</template>
<script>
import eodWorkflow from './eod-workflow';
import eodProjectInfo from './eod-project-info';
import eodCompanyInfo from './eod-company-info';
import eodServiceInfo from './eod-service-info';
import eodProducts from './eod-products';
import eodProductTypes from './eod-productTypes';
import eodShareDialog from './eod-share-dialog';
import getEnv from '../classes/utils';

export default {
  components: {
    'eod-workflow': eodWorkflow,
    'eod-project-info': eodProjectInfo,
    'eod-company-info': eodCompanyInfo,
    'eod-service-info': eodServiceInfo,
    'eod-products': eodProducts,
    'eod-product-types': eodProductTypes,
    eodShareDialog
  },
  props: {
    value: Boolean,
    workflow: Object,
    nameAppend: String,
    hideSave: {
      type: Boolean,
      default: false
    },
    defaultPlanning: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      workflowTab: 'form',
      saving: false,
      cell: null,
      shareDialogShown: false,
      shareUrl: 'https://'+this.$eod.getRealm()+'.'+getEnv('VUE_APP_MOBILE_URL')+'/form/'+this.workflow.id
    }
  },
  methods: {
    setWorkflowName(name) {
      if (this.$refs.addTaskModal) {
        this.$refs.addTaskModal.setWorkflowName(name);
      }
    },
    workflowLoaded(cell) {
      this.cell = cell;
    },
    save() {
      if (this.$refs.addTaskModal) {
        this.$refs.addTaskModal.save();
      }
    },
    submit() {
      if (this.$refs.addTaskModal) {
        this.$refs.addTaskModal.submit();
      }
    },
    reject() {
      if (this.$refs.addTaskModal) {
        this.$refs.addTaskModal.reject();
      }
    },
    saved(task, job) {
      this.saving = false;
      this.$emit('saved', task, job);
    }
  }
}
</script>
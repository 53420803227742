import moment from "moment";
import { rrulestr } from "rrule";

export default class Base{
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'name'
    },
    ];
    
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);

        delete data.isReadonly;
        
        const relations = this.constructor.relations;
        if(relations && relations.oneToMany){
            for (let i = 0; i < relations.oneToMany.length; i++) {
                const relation = relations.oneToMany[i];
                if (data.hasOwnProperty(relation)) {
                    if (data[relation]){
                        data[relation + 'Id'] = data[relation].id;
                    }else{
                        data[relation + 'Id'] = null;
                    }
                }
                delete data[relation];
            }
        }

        if(relations && relations.manyToMany){
            for (let i = 0; i < relations.manyToMany.length; i++) {
                const relationName = relations.manyToMany[i];
                if (data.hasOwnProperty(relationName)) {
                    if(data[relationName]){
                        for (let j = 0; j < data[relationName].length; j++) {
                            const relationData = {
                                id: data[relationName][j].id
                            };
                            if(!isNaN(data[relationName][j].quantity)){
                                relationData.quantity = parseFloat(data[relationName][j].quantity);
                            }
                            data[relationName][j] = relationData;
                        }
                    }
                }
            }
        }

        return data;
    }
    isAvailable(dateFrom, dateUntil){
        let available = false;

        const momentFrom = moment(dateFrom);
        const momentUntil = moment(dateUntil);

        if(this.availabilities){
            for (let i = 0; i < this.availabilities.length; i++) {
                const availability = this.availabilities[i];
                if(availability.rrule){
                    const availStart = moment(availability.start);
                    const rruleSet =  rrulestr(availability.rrule);
                    const dates = rruleSet.between(momentFrom.toDate(), momentUntil.toDate(), true);
                    dates.unshift(rruleSet.before(momentFrom.toDate(), true));
                    //dates.push(rruleSet.after(momentFrom.toDate(), true));
                    for (let d = 0; d < dates.length; d++) {
                        const date = dates[d];
                        const startDate = moment(date)
                        startDate.set({
                            hour:   availStart.get('hour'),
                            minute: availStart.get('minute'),
                            second: availStart.get('second')
                        });
                        const endDate = startDate.clone();
                        endDate.add(availability.duration, 'millisecond');
                        if(momentFrom.isSame(startDate) || momentFrom.isBetween(startDate, endDate) || momentUntil.isSame(startDate) || momentUntil.isBetween(startDate, endDate)){
                            if(availability.type == 'unavailable'){
                                return false;
                            } else {
                                available = true;
                            }
                        } else if(availability.duration < 86400000 && availability.type == 'available') {
                            return false;
                        }
                    }
                } else {
                    const startDate = moment(availability.start);
                    const endDate = moment(availability.end);
    
                    if(momentFrom.isSame(startDate) || momentFrom.isBetween(startDate, endDate) || momentUntil.isSame(startDate) || momentUntil.isBetween(startDate, endDate)){
                        if(availability.type == 'unavailable'){
                            return false;
                        } else {
                            available = true;
                        }
                    }
                }
            }
        }

        return available;
    }
}
import Task from './task';

export default class Workflow extends Task {
    static exportFilters = {
        isActive: false,
        where: [{
            column: 'category',
            operator: '=',
            value: 'TASK'
        }],
    }
    static moduleName = 'workflows';
    static searchConfig = [
        {
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Omschrijving',
            'type': 'property',
            'filter': 'description'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Stap',
            'type': 'property',
            'filter': 'stepName'
        },
        /*{
            'icon': 'mdi-format-text',
            'label': 'Stap type',
            'type': 'options',
            'settings': {
                'options': settings.stepTypes
            },
            'filter': 'stepType'
        },*/
        /*{
            'icon': 'mdi-map-marker',
            'label': 'Postcode',
            'type': 'property',
            'filter': 'postalCode'
        },*/
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Workflow',
            'type': 'module',
            'settings': {
                'module': 'taskTypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'taskTypeId'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'module',
            'settings': {
                'module': 'projects',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'projectId'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'module',
            'settings': {
                'module': 'services',
                'fields': ['name'],
                'title': '{name}',
            },

            'filter': 'serviceId'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'companyId'
        },
        {
            'icon': 'mdi-tag-outline',
            'label': 'Status',
            'type': 'options',
            'settings': {
                'options': (settings) => {
                    let statusCodes = [];

                    for (let i = 0; i < settings.statusCodes.length; i++) {
                        const statusCode = settings.statusCodes[i];
                        if (statusCode.workflow_enabled) {
                            statusCodes.push(statusCode);
                        }
                    }

                    return statusCodes;
                }
            },
            'filter': 'statusCode'
        },
        /*{
            'icon': 'mdi-office-building-marker-outline',
            'label': 'Stocklocatie',
            'type': 'list',
            'settings': {
                'list': 'products.stocklocations.listId',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'stockLocationId'
        },*/
        /*{
            'icon': 'mdi-calendar',
            'label': 'Datum',
            'type': 'date-between',
            'filter': ['due_after_date', 'due_before_date']
        }, */
        {
            'icon': 'mdi-calendar',
            'label': 'Uitvoeringsdatum',
            'type': 'date-between',
            'filter': 'reportedAt'
        },
        {
            'icon': 'mdi-calendar',
            'label': 'Planningsdatum',
            'type': 'date-between',
            'filter': 'dueRequestDate'
        },
        {
            'icon': 'mdi-alarm',
            'label': 'Wijzigingsdatum',
            'type': 'date-between',
            'filter': 'updatedAt'
        },
        {
            icon: 'mdi-calendar',
            label: 'Aanmaakdatum',
            type: 'date-between',
            filter: 'createdAt'
        },
        /*{
            'icon': 'mdi-account-outline',
            'label': 'Verantwoordelijke',
            'type': 'relation',
            'settings': {
                'module': 'searchUsers',
                'fields': ['firstName', 'lastName', 'username'],
                'title': '{firstName} {lastName} ({username})',
                'options': {
                    enabled: true
                }
            },
            'filter': 'responsibleUsers'
        },*/
    ];
}
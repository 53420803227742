// Components
import eodCard from './src/components/eod-card.vue'
import eodTextField from './src/components/eod-text-field.vue'
import eodTextarea from './src/components/eod-textarea.vue'
import eodAutocomplete from './src/components/eod-autocomplete.vue'
import eodTaskForm from './src/components/eod-task-form.vue'
import eodTask from './src/components/eod-task.vue'
import eodWorkflow from './src/components/eod-workflow.vue'
import eodWorkflowDialog from './src/components/eod-workflow-dialog.vue'
import eodMeasurement from './src/components/eod-measurement.vue'
import eodCell from './src/components/eod-cell.vue'
import eodItemTreeview from './src/components/eod-item-treeview.vue'
import eodItemTreeviewAutocomplete from './src/components/eod-item-treeview-autocomplete.vue'
import eodItemTreeviewSelector from './src/components/eod-item-treeview-selector.vue'
import eodStorageLibrary from './src/components/eod-storage-library.vue'
import eodStorageObject from './src/components/eod-storage-object.vue'
import eodStorageObjectsCarousel from './src/components/eod-storage-objects-carousel.vue'
import eodItemSelector from './src/components/eod-item-selector.vue'
import eodDateTimePicker from './src/components/eod-datetime-picker.vue'
import eodListItemAutocomplete from './src/components/eod-list-item-autocomplete.vue'
import eodListItemSelect from './src/components/eod-list-item-select.vue'
import eodObjectAutocomplete from './src/components/eod-object-autocomplete.vue'
import eodObjectsTreeview from './src/components/eod-objects-treeview.vue'
import eodObjectsTreeviewInput from './src/components/eod-objects-treeview-input.vue'
import eodObjectsTreeviewDetail from './src/components/eod-objects-treeview-detail.vue'
import eodObjectSelector from './src/components/eod-object-selector.vue'
import eodSearch from './src/components/eod-search.vue'
import eodColorPicker from './src/components/eod-color-picker.vue'
import eodDateRangePicker from './src/components/eod-date-range-picker.vue'
import eodErrorsDialog from './src/components/eod-errors-dialog.vue'
import eodTranslations from './src/components/eod-translations.vue'
import eodContacts from './src/components/eod-contacts.vue'
import eodContactsDialog from './src/components/eod-contacts-dialog.vue'
import eodAddContact from './src/components/eod-add-contact.vue'
import eodProducts from './src/components/eod-products.vue'
import eodProductDetail from './src/components/eod-product-detail.vue'
import eodProductsDialog from './src/components/eod-products-dialog.vue'
import eodProductsList from './src/components/eod-products-list.vue'
import eodAddProduct from './src/components/eod-add-product.vue'
import eodProductTypes from './src/components/eod-productTypes.vue'
import eodProductTypeDetail from './src/components/eod-product-type-detail.vue'
import eodAddProductType from './src/components/eod-add-productType.vue'
import eodMeasurementType from './src/components/eod-measurementType.vue'
import eodMeasurementTypeIndicative from './src/components/eod-measurementType-indicative.vue'
import eodUserDetail from './src/components/eod-user-detail.vue'
import eodUserAddress from './src/components/eod-user-address.vue'
import eodUserSelector from './src/components/eod-user-selector.vue'
import eodDashboards from './src/components/eod-dashboards.vue'
import eodDashboardsProjects from './src/components/eod-dashboards-projects.vue'
import eodDashboardsMapProjectPopup from './src/components/eod-dashboards-map-project-popup.vue'
import eodDashboardsMap from './src/components/eod-dashboards-map.vue'
import eodDashboardsList from './src/components/eod-dashboards-list.vue'
import eodCalendar from './src/components/eod-calendar.vue'
import eodTaskOverview from './src/components/eod-task-overview.vue'
import eodResponseOverview from './src/components/eod-response-overview.vue'
import eodResponsesList from './src/components/eod-responses-list.vue'
import eodTaskInfo from './src/components/eod-task-info.vue'
import eodAddTimetracking from './src/components/eod-add-timetracking.vue'
import eodTimetrackings from './src/components/eod-timetrackings.vue'
import eodComments from './src/components/eod-comments.vue'
import eodIconPicker from './src/components/eod-icon-picker.vue'
import eodReportEditor from './src/components/eod-report-editor.vue'
import eodReportEditorPreview from './src/components/eod-report-editor-preview.vue'
import eodFormEditor from './src/components/eod-form-editor.vue'
import eodListItems from './src/components/eod-list-items.vue'
import eodQueryFixedFilters from './src/components/eod-query-fixed-filters.vue'
//import eodFormEditor from './src/components/eod-form-editor.vue'
import eodExportDialog from './src/components/eod-export-dialog.vue'
import eodLocations from './src/components/eod-locations.vue'
import eodLocationsInfo from './src/components/eod-locations-info.vue'
import eodLocationsDialog from './src/components/eod-locations-dialog.vue'
import eodLocationForm from './src/components/eod-location-form.vue'
import eodLocationsMap from './src/components/eod-locations-map.vue'
import eodContactsInfo from './src/components/eod-contacts-info.vue'
import eodObjectsTable from './src/components/eod-objects-table.vue'
import eodTags from './src/components/eod-tags.vue'
import eodItemDialog from './src/components/eod-item-dialog.vue'
import eodImportDialog from './src/components/eod-import-dialog.vue'
import eodProjectDetail from './src/components/eod-project-detail.vue'
import eodProjectInfo from './src/components/eod-project-info.vue'
import eodProjectGeneral from './src/components/eod-project-general.vue'
import eodJobTypesList from './src/components/eod-job-types-list.vue'
import eodJobsList from './src/components/eod-jobs-list.vue'
import eodServiceInfo from './src/components/eod-service-info.vue'
import eodServiceDetail from './src/components/eod-service-detail.vue'
import eodCompanyInfo from './src/components/eod-company-info.vue'
import eodStatusCodeAutocomplete from './src/components/eod-statuscode-autocomplete.vue'
import eodScheduler from './src/components/eod-scheduler.vue'
import eodSchedulerSidebar from './src/components/eod-scheduler-sidebar.vue'
import eodRoutes from './src/components/eod-routes.vue'
import eodRoutesSidebar from './src/components/eod-routes-sidebar.vue'
import eodRoutesScheduler from './src/components/eod-routes-scheduler.vue'
import eodDeleteDialog from './src/components/eod-delete-dialog.vue'
import eodTimeSelector from './src/components/eod-time-selector.vue'
import eodExportBackupDialog from './src/components/eod-export-backup-dialog.vue'
import eodJobCard from './src/components/eod-job-card.vue'
import eodTreeview from './src/components/eod-treeview.vue'
import eodTreeviewMultiple from './src/components/eod-treeview-multiple.vue'
import eodTreeviewMultipleInput from './src/components/eod-treeview-multiple-input.vue'
import eodTreeviewInput from './src/components/eod-treeview-input.vue'
import eodDepotDetail from './src/components/eod-depot-detail.vue'
import eodDialog from './src/components/eod-dialog.vue'
import eodLoader from './src/components/eod-loader.vue'
import eodObjectAvailability from './src/components/eod-object-availability.vue'
import eodAvailabilities from './src/components/eod-availabilities.vue'
import eodUserJobTypesConfig from './src/components/eod-job-types-config.vue'
import eodCompanyDetail from './src/components/eod-company-detail.vue'
import eodAlarmDetail from './src/components/eod-alarm-detail.vue'
import eodAlarmList from './src/components/eod-alarm-list.vue'
import eodUserNotifications from './src/components/eod-user-notifications.vue'
import eodEventsDrawer from './src/components/eod-events-drawer'
import eodEventsDrawerCollapse from './src/components/eod-events-drawer-collapse'
import eodEventsWidget from './src/components/eod-events-widget'
import eodEventsSidebar from './src/components/eod-events-sidebar'
import eodEventsTable from './src/components/eod-events-table'
import eodEventsDrawerTable from './src/components/eod-events-drawer-table'
import eodEventsTableCompact from './src/components/eod-events-table-compact'
import eodEvent from './src/components/eod-event'
import eodLogs from './src/components/eod-logs'
import eodRte from './src/components/eod-rte'
import eodWorkflowMailConfig from './src/components/eod-workflow-mail-config'
import eodShareDialog from './src/components/eod-share-dialog'
import eodProjectAccessDialog from './src/components/eod-project-access-dialog'
import eodButtonCopy from './src/components/eod-button-copy'
import eodMenu from './src/components/eod-menu'
import eodAcls from './src/components/eod-acls'
import eodFilterAutocomplete from './src/components/eod-filter-autocomplete'
import eodExpansionPanels from './src/components/eod-expansion-panels.vue'
import eodTranslated from './src/components/eod-translated.vue'
import eodToolDetail from './src/components/eod-tool-detail.vue'
import eodToolTypeDetail from './src/components/eod-tool-type-detail.vue'
import eodArticleDetail from './src/components/eod-article-detail.vue'
import eodArticleTypeDetail from './src/components/eod-article-type-detail.vue'
import eodArticleStockMovements from './src/components/eod-article-stock-movements.vue'
import eodProducttypesProductsSelector from './src/components/eod-producttypes-products-selector.vue'

// Graph
import eodGraph from './src/components/graphs/eod-graph.vue'
import eodGraphTable from './src/components/graphs/eod-graph-table.vue'
import eodGraphLine from './src/components/graphs/eod-graph-line.vue'
import eodGraphProfile from './src/components/graphs/eod-graph-profile.vue'
import eodGraphEvents from './src/components/graphs/eod-graph-events.vue'
import eodEvolutionGraph from './src/components/eod-evolution-graph.vue'

import eodGraphFilters from './src/components/graphs/eod-graph-filters.vue'

// Columns
import eodColumnActions from './src/components/columns/eod-column-actions.vue';
import eodColumnProject from './src/components/columns/eod-column-project.vue';
import eodColumnCompany from './src/components/columns/eod-column-company.vue';
import eodColumnStatusCode from './src/components/columns/eod-column-status-code.vue';

// Models
import Project from './src/models/project';
import Event from './src/models/event';
import Product from './src/models/product';
import ProductType from './src/models/productType';
import Task from './src/models/task';
import TaskType from './src/models/taskType';
import Response from './src/models/response';
import User from './src/models/user';
import DataConnector from './src/models/dataConnector';
import Service from './src/models/service';
import Route from './src/models/route';
import MeasurementType from './src/models/measurementType';
import Measurement from './src/models/measurement';
import Template from './src/models/template';
import Query from './src/models/query';
import Export from './src/models/export';
import Availability from './src/models/availability';
import AlarmRule from './src/models/alarmRule';
import ExternalStorage from './src/models/externalStorage';
import StorageObject from './src/models/storageObject';
import TreeObject from './src/models/treeObject';
import Company from './src/models/company';
import Tool from './src/models/tool';
import ToolType from './src/models/toolType';
import Article from './src/models/article';
import ArticleType from './src/models/articleType';

// Classes
import eod from './src/classes/eod';
import eodShare from './src/classes/eodShare';
import settings from './src/classes/settings';
import helper from './src/classes/helper';
import validator from './src/classes/validator';
import vuetify from './src/classes/vuetify';
import getEnv from './src/classes/utils';
import Translator from './src/classes/trans';

const globalComponents = [
    eodCard,
    eodTextField,
    eodTextarea,
    eodAutocomplete,
    eodExpansionPanels,
    eodDialog,
    eodTaskForm,
    eodTranslated
]

export {
    globalComponents,
    getEnv,
    eodLoader,
    eodJobCard,
    eodRte,
    eodWorkflowMailConfig,
    eodExportBackupDialog,
    eodUserJobTypesConfig,
    eodRoutes,
    eodAlarmDetail,
    eodAlarmList,
    eodAcls,
    eodRoutesSidebar,
    eodRoutesScheduler,
    eodTimeSelector,
    eodDeleteDialog,
    eodStatusCodeAutocomplete,
    eodProjectInfo,
    eodJobsList,
    eodJobTypesList,
    eodProjectGeneral,
    eodCompanyInfo,
    eodServiceInfo,
    eodServiceDetail,
    eodImportDialog,
    eodListItems,
    eodFormEditor,
    eodContactsInfo,
    eodContactsDialog,
    eodLocationsInfo,
    eodLocationsMap,
    eodLocationsDialog,
    eodLocations,
    eodLocationForm,
    eodExportDialog,
    eodObjectsTable,
    eodObjectsTreeview,
    eodObjectsTreeviewInput,
    eodObjectsTreeviewDetail,
    eodFilterAutocomplete,
    eodTags,
    eodMenu,
    eodItemDialog,
    eodScheduler,
    eodSchedulerSidebar,
    eodCompanyDetail,
    eodProjectDetail,
    eodEventsDrawer,
    eodEventsDrawerCollapse,
    eodEventsWidget,
    eodEventsTable,
    eodEventsDrawerTable,
    eodEventsTableCompact,
    eodEventsSidebar,
    eodEvent,
    eodLogs,
    //eodFormEditor,
    eodIconPicker,
    eodReportEditor,
    eodReportEditorPreview,
    eodCalendar,
    eodComments,
    eodTimetrackings,
    eodAddTimetracking,
    eodResponseOverview,
    eodResponsesList,
    eodTaskInfo,
    eodTaskOverview,
    eodDateRangePicker,
    eodDashboards,
    eodDashboardsProjects,
    eodDashboardsMapProjectPopup,
    eodDashboardsMap,
    eodDashboardsList,
    eodGraph,
    eodGraphTable,
    eodGraphLine,
    eodGraphFilters,
    eodGraphProfile,
    eodGraphEvents,
    eodEvolutionGraph,
    eodColorPicker,
    eodSearch,
    eodItemSelector,
    eodListItemAutocomplete,
    eodListItemSelect,
    eodObjectAutocomplete,
    eodObjectSelector,
    eodDateTimePicker,
    eodStorageLibrary,
    eodStorageObject,
    eodStorageObjectsCarousel,
    eodTaskForm,
    eodTask,
    eodWorkflow,
    eodWorkflowDialog,
    eodMeasurement,
    eodShareDialog,
    eodProjectAccessDialog,
    eodButtonCopy,
    eodCell,
    eodTreeview,
    eodTreeviewMultiple,
    eodTreeviewMultipleInput,
    eodTreeviewInput,
    eodItemTreeview,
    eodItemTreeviewAutocomplete,
    eodItemTreeviewSelector,
    eodErrorsDialog,
    eodTranslations,
    eodContacts,
    eodAddContact,
    eodProducts,
    eodProductsDialog,
    eodProductsList,
    eodProductDetail,
    eodProductTypeDetail,
    eodToolDetail,
    eodToolTypeDetail,
    eodArticleDetail,
    eodArticleTypeDetail,
    eodArticleStockMovements,
    eodAddProduct,
    eodProductTypes,
    eodAddProductType,
    eodMeasurementType,
    eodMeasurementTypeIndicative,
    eodUserDetail,
    eodUserNotifications,
    eodUserAddress,
    eodUserSelector,
    eodColumnActions,
    eodColumnProject,
    eodColumnCompany,
    eodColumnStatusCode,
    eodDepotDetail,
    eodObjectAvailability,
    eodAvailabilities,
    eodQueryFixedFilters,
    eodProducttypesProductsSelector,
    eodDialog,
    ProductType,
    Query,
    Product,
    Project,
    Task,
    TaskType,
    Response,
    User,
    DataConnector,
    Service,
    Route,
    Measurement,
    MeasurementType,
    Template,
    Availability,
    Event,
    Tool,
    ToolType,
    Article,
    ArticleType,
    AlarmRule,
    Export,
    ExternalStorage,
    StorageObject,
    TreeObject,
    Company,
    Translator,
    eod,
    eodShare,
    settings,
    helper,
    validator,
    vuetify,
}
import eod from "../classes/eod";
import { cloneDeep } from "lodash";

export default class Template {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    static searchConfig = [{
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'settings': {
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'name'
        }
    ];
    static accessTypes = [
        {
            value:'PUBLIC',
            text: 'Publiek',
            info: 'Deze tab is publiek beschikbaar voor alle gebruikers met interne rollen beheerder, manager, medewerker en externe rollen verantwoordelijke en gast.',
            icon:'mdi-eye'
        },
        {
            value:'PROTECTED',
            text: 'Intern',
            info: 'Deze tab is intern beschikbaar voor alle gebruikers met interne rollen beheerder, manager en medewerker.',
            icon:'mdi-eye-lock'
        },
        {
            value:'PRIVATE',
            text: 'Privé',
            info: 'Deze tab is privé beschikbaar en beperkt tot de gebruiker die deze tab heeft aangemaakt.',
            icon:'mdi-eye-off'
        }
    ];
    static getAccessType(value){
        for (let i = 0; i < this.accessTypes.length; i++) {
            const type = this.accessTypes[i];
            if(type.value == value){
                return type;
            }
        }

        return null;
    }
    getActiveRequestDate() {
        const activeCell = this.getActiveCell();

        if (!activeCell) {
            return null;
        }

        const cell = activeCell.cell;

        if (cell.typeId == 'fixed_form' && cell.settings.type == 'info') {
            return null;
        }

        if (cell.typeId == 'form') {
            return cell.settings.requestDate;
        } else if (cell.typeId == 'fixed_form' && cell.settings.type == 'verify') {
            const formCell = this.getCellById(cell.settings.cellId);
            return formCell.settings.requestDate;
        } else if (cell.typeId == 'fixed_form' && cell.settings.type == 'plan') {
            const formCell = this.getCellById(cell.settings.cellId);
            return formCell.settings.requestDate;
        }

        return null;
    }
    getExternalId(name) {
        if (!this.externalIds) {
            return null;
        }

        for (let i = 0; i < this.externalIds.length; i++) {
            const externalId = this.externalIds[i];
            if (externalId.name == name) {
                return externalId.id;
            }
        }

        return null;
    }
    setExternalId(name, value) {
        if (!this.externalIds) {
            this.externalIds = [];
        }

        let found = false;
        for (let i = 0; i < this.externalIds.length; i++) {
            const externalId = this.externalIds[i];
            if (externalId.name == name) {
                this.externalIds[i].id = '' + value;
                found = true;
            }
        }

        if (!found) {
            this.externalIds.push({
                name: name,
                id: '' + value
            });
        }
    }
    getCellById(id) {
        for (let i = 0; i < this.content.pages.length; i++) {
            const page = this.content.pages[i];
            for (let ci = 0; ci < page.cells.length; ci++) {
                const cell = page.cells[ci];
                if (cell.id == id) {
                    return cell;
                }
            }
        }

        return null;
    }
    getLinkedPlanCell(formCell) {
        for (let i = 0; i < this.content.pages.length; i++) {
            const page = this.content.pages[i];
            for (let ci = 0; ci < page.cells.length; ci++) {
                const cell = page.cells[ci];
                if (cell.typeId == 'fixed_form' && cell.settings.type == 'plan' && cell.settings.cellId == formCell.id) {
                    return cell;
                }
            }
        }

        return null;
    }
    setCellValue(cellId, property, value) {
        const cellInfo = this.getCellInfo(cellId);
        this.content.pages[cellInfo.pageIndex].cells[cellInfo.cellIndex][property] = value;
    }
    setPageValue(pageId, property, value) {
        const pageInfo = this.getPageInfo(pageId);
        this.content.pages[pageInfo.pageIndex][property] = value;
    }
    fillTemplateDefaults(cellId, template) {
        for (let i = 0; i < template.content.pages.length; i++) {
            const page = template.content.pages[i];
            for (let j = 0; j < page.cells.length; j++) {
                const cell = page.cells[j];
                if (cell.id == cellId) {
                    if (cell.settings.defaultVariant) {
                        this.content.variant = cell.settings.defaultVariant;
                    }
                    if (cell.settings.defaultVariables && this.content.variables && this.content.variables.length > 0) {
                        for (const variableId in cell.settings.defaultVariables) {
                            const product = cell.settings.defaultVariables[variableId];
                            for (let variableIndex = 0; variableIndex < this.content.variables.length; variableIndex++) {
                                const variable = this.content.variables[variableIndex];
                                if (variable.value == variableId) {
                                    this.content.variables[variableIndex].product = product;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    async save() {
        const data = await this.getSaveData();
        return eod.save('Template', data);
    }
    getSaveData() {
        let data = cloneDeep(this);

        const manyToManyRelations = ['measurementTypes', 'projects', 'services', 'companies', 'products', 'productTypes', 'jobTypes', 'users'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                if(!data[relationName]){
                    data[relationName] = null;
                }else {
                    for (let j = 0; j < data[relationName].length; j++) {
                        const relationData = {
                            id: data[relationName][j].id,
                        }
    
                        if(data[relationName][j].hasOwnProperty('default')){
                            relationData.default = data[relationName][j].default;
                        }
    
                        data[relationName][j] = relationData;
                    }
                }
                
            }
        }

        delete data.default;
        delete data.createdAt;
        delete data.updatedAt;

        return data;
    }
    getCellInfo(cellId) {
        for (let i = 0; i < this.content.pages.length; i++) {
            const page = this.content.pages[i];
            for (let ci = 0; ci < page.cells.length; ci++) {
                const cell = page.cells[ci];
                if (cell.id == cellId) {
                    return {
                        pageIndex: i,
                        page: page,
                        cellIndex: ci,
                        cell: cell,
                    };
                }
            }
        }

        return null;
    }
    getPageInfo(pageId) {
        for (let i = 0; i < this.content.pages.length; i++) {
            const page = this.content.pages[i];
            if (page.id == pageId) {
                return {
                    pageIndex: i,
                    page: page,
                };
            }
        }

        return null;
    }
}
module.exports = {
    routes: [{
        path: '/depots',
        name: 'depots',
        meta:{
            permissions:['depots.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/depots/detail/:id?',
        name: 'depot',
        meta:{
            permissions:['depots.update', 'depots.create']
        },
        component: () => import('./views/detail.vue')
    }],
    search: [
        {
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Omschrijving',
            'type': 'property',
            'filter': 'description'
        },
        {
            icon: 'mdi-calendar',
            label: 'Aanmaakdatum',
            type: 'date-between',
            filter: 'createdAt'
        },
    ],
    itemObject: require('./../../eodvuecomponents/src/models/depot').default,
    detail: {
        route: 'depot',
        endpoint: 'depot',
        objectName: 'Depot',
        fields: ['id', 'name', 'description', 'streetAddress','postalCode','locality','region','country','category', 'lat','lon', 'access', 'notes'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        }],
    },
    index: {
        route: 'depots',
        endpoint: 'depots',
        fields: ['id', 'name', 'description', 'isActive', 'streetAddress', 'postalCode', 'locality', 'region', 'country', 'category'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'description',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Adres',
                value: null,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-address.vue')
            },
            {
                width: 120,
                sortable: false,
                component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue'),
                extra:{
                    hideRelated: true
                }
            }
        ],
        tabs: [{
            path: 'depots',
            text: 'Overzicht'
        }],
    },
    export: {
        endpoint: 'depots',
        fields: ['id', 'name', 'description', 'streetAddress', 'postalCode', 'locality', 'region', 'country', 'category', 'access', 'notes']
    },
    import: {
        endpoint: 'depots',
        objectName: 'Depot',
    },
}
<template>
    <div>
        <v-btn class="mb-4" @click="addStockMovement" color="primary" depressed rounded><v-icon left>mdi-plus</v-icon>Stockbeweging toevoegen</v-btn>
        <eod-dialog width="800" ok-text="Toevoegen" @okAction="saveNewStockMovement" :loading="isNewStockMovementLoading" v-if="isNewStockMovementDialogVisible && newStockMovement" v-model="isNewStockMovementDialogVisible" icon="mdi-plus" icon-color="primary" title="Stockbeweging toevoegen">
            <v-row class="mt-4">
                        <v-col><eod-date-time-picker label="Van*" v-model="newStockMovement.start"></eod-date-time-picker></v-col>
                        <v-col><eod-date-time-picker label="Tot" v-model="newStockMovement.end"></eod-date-time-picker></v-col>
                    </v-row>
                    <v-row>
                        <v-col><eod-text-field label="Omschrijving" v-model="newStockMovement.name"></eod-text-field></v-col>
                        <v-col>
                            <eod-stockmovement-quantity label="Aantal*" v-model="newStockMovement.quantity"></eod-stockmovement-quantity>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <eod-object-autocomplete label="Stocklocatie" endpoint="depots" v-model="newStockMovement.depotId"></eod-object-autocomplete>
                        </v-col>
                        <v-col>
                            <eod-objects-treeview-input return-object v-model="newStockMovement.products" multiple :classes="['COMPANY','PROJECT','DEVICE','DEVICE_FOLDER']" :selectable="['DEVICE']"></eod-objects-treeview-input>
                        </v-col>
                    </v-row>
        </eod-dialog>
        <eod-expansion-panels>
            <v-expansion-panel v-for="(stockMovement, stockMovementIndex) in items" :key="stockMovementIndex">
                <v-expansion-panel-header>
                    <div>
                    <strong>{{ stockMovement.name?stockMovement.name:'' }}</strong>
                    | {{ $moment(stockMovement.start).format('YYYY-MM-DD HH:mm') }}{{ stockMovement.end?' - ' + $moment(stockMovement.end).format('YYYY-MM-DD HH:mm'):'' }}
                </div>
                <div class="text-right px-4">
                    <strong>{{ stockMovement.quantity }}</strong>
                </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row class="mt-4">
                        <v-col><eod-date-time-picker disabled label="Van*" :value="stockMovement.start"></eod-date-time-picker></v-col>
                        <v-col><eod-date-time-picker disabled label="Tot" :value="stockMovement.end"></eod-date-time-picker></v-col>
                    </v-row>
                    <v-row>
                        <v-col><eod-text-field disabled label="Omschrijving" :value="stockMovement.name"></eod-text-field></v-col>
                        <v-col>
                            <eod-stockmovement-quantity readonly label="Aantal*" :value="stockMovement.quantity"></eod-stockmovement-quantity>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <eod-object-autocomplete disabled label="Stocklocatie" endpoint="depots" :value="stockMovement.depotId"></eod-object-autocomplete>
                        </v-col>
                        <v-col>
                            <eod-objects-treeview-input label="Geproduceerd door" disabled return-object :value="stockMovement.products" multiple :classes="['COMPANY','PROJECT','DEVICE','DEVICE_FOLDER']" :selectable="['DEVICE']"></eod-objects-treeview-input>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </eod-expansion-panels>
    </div>
</template>
<script>
import eodDateTimePicker from './eod-date-time-picker.vue'
import eodObjectAutocomplete from './eod-object-autocomplete.vue'
import eodStockmovementQuantity from './eod-stockmovement-quantity.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import ArticleStockMovement from '../models/articleStockMovement';
export default{
    name: 'eod-article-stock-movements',
    components:{
        eodDateTimePicker,
        eodObjectAutocomplete,
        eodStockmovementQuantity,
        eodObjectsTreeviewInput
    },
    props:{
        value: String
    },
    created(){
        this.loadItems();
    },
    data: () => ({
        items: [],
        newStockMovement: null,
        isNewStockMovementDialogVisible: false,
        isNewStockMovementLoading: false,
    }),
    methods:{
        loadItems(){
            if(this.value){
                this.$eod.get('articleStockMovements', ['start end name depot{id} article{id} quantity products{id}'], {
                    where: [
                        {
                            column: 'articleId',
                            operator: '=',
                            value:this.value
                        }
                    ]
                }).then(response => {
                    if(response.data.data && response.data.data.articleStockMovements){
                        this.items = response.data.data.articleStockMovements.edges;
                    }
                })
            }
        },
        addStockMovement(){
            this.newStockMovement = new ArticleStockMovement({
                articleId: this.value,
                name: 'Stock',
                start: this.$moment().toISOString(),
                end: this.$moment().toISOString(),
                quantity: 1,
            });

            this.isNewStockMovementDialogVisible = true;
        },
        saveNewStockMovement(){
            this.isNewStockMovementLoading = true;

            const data = this.newStockMovement.getSaveData();

            this.$eod.create('ArticleStockMovement', data, {
                returnfields: ['start']
            })
                .finally(() => {
                    this.isNewStockMovementLoading = false;
                    this.isNewStockMovementDialogVisible = false;
                    this.loadItems();
                })
        },
    }
}
</script>
import moment from 'moment';

export default class Service {
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'name'
    },
    {
        'icon': 'mdi-office-building-outline',
        'label': 'Bedrijf',
        'type': 'module',
        'settings': {
            'module': 'companies',
            'fields': ['name'],
            'title': '{name}',
        },

        'filter': 'companyId'
    },
    {
        'icon': 'mdi-alarm',
        'label': 'Wijzigingsdatum',
        'type': 'date-between',
        'filter': 'updatedAt'
    },
    {
        icon: 'mdi-calendar',
        label: 'Aanmaakdatum',
        type: 'date-between',
        filter: 'createdAt'
    },
    ];
    static exportFields = ['id', 'name', 'description', 'notes', 'company{name}', 'serviceType{name}', 'startDate', 'endDate', 'periodicityCode'];
    static moduleName = 'services';
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    get startDateFormatted() {
        if (this.startDate) {
            return moment(this.startDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set startDateFormatted(value) {
        this.startDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get endDateFormatted() {
        if (this.endDate) {
            return moment(this.endDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set endDateFormatted(value) {
        this.endDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get serviceTypeId() {
        if (this.serviceType) {
            return this.serviceType.id;
        }
        return null;
    }
    set serviceTypeId(value) {
        this.serviceType = {
            id: value
        };
    }
    get companyId() {
        if (this.company) {
            return this.company.id;
        }
        return null;
    }
    set companyId(value) {
        this.company = {
            id: value
        };
    }
    getSaveData() {
        let data = Object.assign({}, this);
        data.companyId = this.companyId;
        data.serviceTypeId = this.serviceTypeId;
        delete data.serviceType;
        delete data.company;

        if (data.tags) {
            for (let i = 0; i < data.tags.length; i++) {
                data.tags[i] = {
                    id: data.tags[i].id,
                }
            }
        }

        return data;
    }
}
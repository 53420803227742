import Base from "./base.js";
import ArticleStockMovement from './articleStockMovement.js';

export default class Article extends Base {
    static searchConfig = [{
        icon: 'mdi-format-text',
        label: 'Naam',
        type: 'property',
        settings: {
            fields: ['name'],
            title: '{name}',
        },
        filter: 'tree_objects_view.name'
    },
    {
        icon: 'mdi-file-sign',
        label: 'Type',
        type: 'module',
        settings: {
            module: 'articleTypes',
            fields: ['name'],
            title: '{name}',
        },

        filter: 'tree_objects_view.type_id'
    },
    {
        icon: 'mdi-alarm',
        label: 'Wijzigingsdatum',
        type: 'date-between',
        filter: 'updatedAt'
    },
    {
        icon: 'mdi-calendar',
        label: 'Aanmaakdatum',
        type: 'date-between',
        filter: 'createdAt'
    },
    ];
    static exportFields = ['id', 'name', 'description', 'articleType{name}'];
    static moduleName = 'articles';
    static relations = {
        oneToMany: ['articleType', 'unit']
    }

    getSaveData(){
        const data = super.getSaveData();

        if(data.articleStockMovements){
            for (let i = 0; i < data.articleStockMovements.length; i++) {
                data.articleStockMovements[i] = (new ArticleStockMovement(data.articleStockMovements[i])).getSaveData();
            }
        }

        console.log('article data', data);

        return data;
    }
}
<template>
<div>
    <div v-for="endpoint in endpoints" :key="endpoint.type" class="mb-4">
        <div class="d-flex align-center">
            <v-label class="mr-4">{{endpoint.title}}</v-label>
            <v-btn v-if="!readonly" small rounded depressed color="primary" @click="addItem(endpoint)"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
        </div>
        <v-data-table :key="updateTable[endpoint.type]" dense hide-default-footer :items-per-page="-1" v-if="value[endpoint.type] && value[endpoint.type][0]" :items="items[endpoint.type]" item-key="index" :headers="tableHeaders">
            <template v-slot:item.depot="{ item, index }">
                <span v-if="item.depot">{{item.depot.name}}</span>
                <span v-else>/</span>
            </template>
            <template v-slot:item.id="{ item, index }">
                <eod-objects-treeview-input :disabled="readonly" :hide-details="false" :rules="itemRequired?[val => $validator.required(val, 'Specifiëren ' + endpoint.title.toLowerCase())]:[]" return-object :selectable="[endpoint.className, endpoint.className+'_TYPE']" :search-config="endpoint.class.searchConfig" @input="val => updateItem(endpoint, index, val)" :search="getItemFilters(endpoint, item)" :value="item" :classes="endpoint.classes"></eod-objects-treeview-input>
            </template>
            <template v-slot:item.quantity="{ item, index }">
                <div class="d-flex">
                <eod-autocomplete :disabled="readonly" :value="item.quantity>=0?'+':'-'" @change="val => changeType(item, val)" :items="[{value:'+', text:'Geproduceerd'}, {value:'-', text:'Verbruikt'}]"></eod-autocomplete>
                <eod-text-field type="number" :value="Math.abs(item.quantity)" @input="val => updateQuantity(item, val)" :disabled="readonly"></eod-text-field>
            </div>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-btn v-if="!readonly" @click="showDeleteItemDialog(item, endpoint)" color="error" icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
            </template>
        </v-data-table>
    </div>
    <eod-delete-dialog v-if="deleteDialogItem" v-model="isDeleteDialogVisible" :item="{name:deleteDialogItemEndpoint.title}" @delete="deleteItem"></eod-delete-dialog>
</div>
</template>
<script>
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue'
import Article from './../models/article'
import Tool from './../models/tool'
import eodDeleteDialog from './eod-delete-dialog.vue';

export default {
    name: 'eod-producttypes-products-selector',
    components:{
        eodObjectAutocomplete,
        eodObjectsTreeviewInput,
        eodDeleteDialog
    },
    props:{
        value: Object,
        itemRequired: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isDeleteDialogVisible: false,
        deleteDialogItem: null,
        deleteDialogItemEndpoint: null,
        updateTable:{
            tools: 34093,
            articles: 30943
        },
        items: {},
        endpoints: [
            // {
            //     title:'Gereedschappen',
            //     type: 'tools',
            //     classes: ['TOOL_TYPE', 'DEPOT', 'TOOL', 'TOOL_FOLDER'],
            //     class: Tool,
            //     className: 'TOOL',
            //     object: 'Tool',
            //     typeName: 'toolTypes',
            //     typeObject: 'toolType',
            // },
            {
                title:'Artikelen',
                type: 'articles',
                classes: ['ARTICLE_TYPE', 'ARTICLE', 'ARTICLE_FOLDER'],
                class: Article,
                className: 'ARTICLE',
                object: 'Article',
                typeName: 'articleTypes',
                typeObject: 'articleType',
            }
        ],
        tableHeaders: [
            {
                value:'id',
                text:'Item',
                sortable:false,
            },
            // {
            //     value:'depot',
            //     text:'Depot',
            //     sortable:false,
            // },
            {
                value:'quantity',
                text:'Aantal',
                sortable:false,
            },
            {
                value:'actions',
                text:'',
                sortable:false,
                width:20
            }
        ]
    }),
    created(){
        this.convertTypesToItems();
    },
    methods:{
        updateQuantity(item, quantity){
            const val = this.value
            if(val[item.endpoint][item.origIndex].quantity >= 0){
                val[item.endpoint][item.origIndex].quantity = quantity;
            } else {
                val[item.endpoint][item.origIndex].quantity = quantity * -1;
            }

            this.$emit('input', val);
        },
        changeType(item, type){
            const val = this.value;
            if(type == '+'){
                val[item.endpoint][item.origIndex].quantity = Math.abs(val[item.endpoint][item.origIndex].quantity);
            } else {
                val[item.endpoint][item.origIndex].quantity *= -1;
            }

            this.$emit('input', val);
        },
        deleteItem(){
            const val = this.value;
            val[this.deleteDialogItem.endpoint].splice(this.deleteDialogItem.origIndex, 1);
            this.$emit('input', val);
            
            this.isDeleteDialogVisible = false;
            this.updateView();
        },
        showDeleteItemDialog(item, endpoint){
            this.deleteDialogItem = item;
            this.deleteDialogItemEndpoint = endpoint;
            this.isDeleteDialogVisible = true;
        },
        convertTypesToItems(){
            const val = this.value;
            for (let i = 0; i < this.endpoints.length; i++) {
                const endpoint = this.endpoints[i];
                this.items[endpoint.type] = [];
                if(val[endpoint.type]){
                    for (let j = 0; j < val[endpoint.type].length; j++) {
                        const item = val[endpoint.type][j];
                        item.endpoint = endpoint.type;
                        item.origIndex = j;
                        item.index = this.items[endpoint.type].length;
                        this.items[endpoint.type].push(item);
                    }
                }
                if(val[endpoint.typeName]){
                    for (let j = 0; j < val[endpoint.typeName].length; j++) {
                        const item = val[endpoint.typeName][j];
                        item.endpoint = endpoint.typeName;
                        item.index = this.items[endpoint.type].length;
                        item.origIndex = j;
                        this.items[endpoint.type].push(item);
                    }
                }
            }
        },
        addItem(endpoint){
            const val = this.value;
            if(!val[endpoint.typeName]){
                val[endpoint.typeName] = [];
            }

            const data = {
                id: null,
                quantity: 1,
            }

            val[endpoint.typeName].push(data);

            this.$emit('input', val);

            this.updateView();
        },
        updateView(){
            this.convertTypesToItems();

            this.updateTable.tools++;
            this.updateTable.articles++;
            this.$nextTick(() => {
                this.$forceUpdate();
            })
        },
        convertItemsToRelations(endpoint){
            const newArrs = {};
            newArrs[endpoint.type] = [];
            newArrs[endpoint.typeName] = [];

            for (const itemsEndpoint in this.items) {
                const items = this.items[itemsEndpoint];
                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    newArrs[item.endpoint].push({
                        id: item.id,
                        quantity: item.quantity,
                    });
                }
            }
            
            const val = {
                ...this.value,
                ...newArrs
            };
            
            this.$emit('input', val);
        },
        async updateItem(endpoint, index, value){
            this.items[endpoint.type][index].id = value.id;
            this.items[endpoint.type][index].name = value.name;

            if(value.class == endpoint.className){
                this.items[endpoint.type][index].endpoint = endpoint.type;
            } else {
                this.items[endpoint.type][index].endpoint = endpoint.typeName;
            }

            this.convertItemsToRelations(endpoint);
        },
        getItemFilters(endpoint, item){
            const filters = [];

            if(item[endpoint.typeObject]){
                const searchConfig = this.$helper.getArrayItemByProperty(Article.searchConfig, 'label', 'Type');
                filters.push({
                    config: {
                        ...searchConfig, 
                        //readonly: true
                    },
                    result: {
                        id: item[endpoint.typeObject].id,
                        name: 'Type',
                        search_title: item[endpoint.typeObject].name,
                        selected: false,
                    }
                });
            }

            return filters;
            
            
        }
    }
}
</script>
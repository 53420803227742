<template>
    <div style="position:relative;">
        <div style="position:absolute;top:0;left:0;width:100%;padding:15px;z-index:2;" >
        <v-text-field v-if="!disabled" id="input_find_address" class="align-items-center" hide-details v-model="search" dense solo type="search" label="Zoek een adres..." rounded @keyup="searchKeyUp">
            <template v-slot:append-outer>
                <v-btn :loading="searching" color="primary" style="margin-top:-7px;margin-bottom:-4px;" fab small @click="searchAddress"><v-icon>mdi-magnify</v-icon></v-btn>
            </template>
        </v-text-field>
    </div>
    <div class="map" :ref="mapId"></div>
</div>
</template>
<style lang="scss" scoped>
    .map{
        width:100%;
        padding-bottom: 100%;
        z-index: 1;
    }
</style>
<script>

    import {v4 as uuidv4} from 'uuid';
    export default{
        name: 'eod-location-form',
        props:{
            value:{
                type: Object,
                default: null
            },
            disabled: Boolean
        },
        data(){
            return {
                mapId: 'map-'+(uuidv4()),
                map: null,
                search: null,
                searching: false,
                marker: null
            }
        },
        mounted(){
            this.initMap();
        },
        watch:{
            value(val){
                if(val && val.lat && val.lon){
                    this.positionMarker(val.lat, val.lon);
                }
            }
        },
        methods:{
            searchKeyUp(event){
                if(event && event.key && event.key.toLowerCase() == 'enter'){
                    this.searchAddress();
                }
            },
            searchAddress(){
                this.searching = true;

                this.$helper.geocode(this.search).then(response => {
                    if(response.address){
                        this.$emit('update', response.address);
                    }
                    this.positionMarker(response.lat, response.lon);
                    
                }).finally(() => {
                    this.searching = false;
                });
            },
            positionMarker(lat, lon){
                let bounds = [];
                const pos = [lat, lon];
                bounds.push(pos);

                if(!this.marker){
                    this.marker = this.createMarker(pos);
                }else{
                    this.marker.setLatLng(pos, {
                        draggable: this.disabled?false:true
                    }).update();
                }

                this.updateValue(lat, lon);
                
                if (bounds.length > 0) {
                    this.map.fitBounds(bounds, { padding: [25, 25] });
                }
            },
            createMarker(pos) {
                const self = this;

                let icon = this.$leaflet.divIcon({
                    html: this.getMarkerHtml(),
                    iconSize: [30, 34],
                    iconAnchor: [15, 38],
                    className: ''
                });

                const marker = this.$leaflet.marker(pos, { icon: icon, draggable: this.disabled?false:true });

                marker.on('dragend', function(event) {
                    var position = marker.getLatLng();
                    self.positionMarker(position.lat, position.lng);
                });

                marker.addTo(this.map);

                return marker;
            },
            updateValue(lat, lon){
                if(this.value){
                    const val = this.value;
                    val.lat = lat?parseFloat(lat):null;
                    val.lon = lon?parseFloat(lon):null;
                    this.$emit('input', val);
                }
            },
            getMarkerHtml() {
                return '<div class="eod-marker primary"><div class="triangle" style="border-top-color:var(--v-primary-base);"></div><i style="color:#ffffff;" class="mdi mdi-circle"></i></div>';
            },
            initMap() {
                if (!this.$refs[this.mapId]) {
                    return false;
                }

                if (this.map) {
                    this.map.remove();
                    this.map = null;
                }

                this.map = this.$leaflet.map(this.$refs[this.mapId], {
                    center: [51.022684, 3.187897],
                    zoom: 13,
                    zoomControl: false
                });

                if(this.value && this.value.lat && this.value.lon){
                    this.positionMarker(this.value.lat, this.value.lon);
                }

                this.$leaflet.control.zoom({
                    position: 'bottomleft'
                }).addTo(this.map);

                this.map.createPane('labels');
                this.map.getPane('labels').style.pointerEvents = 'none';

                this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>'
                }).addTo(this.map);
                this.$leaflet.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a target="_blank" href="https://www.eyeondata.be">Eye on Data</a>',
                    pane: 'labels'
                }).addTo(this.map);
            },
        }
    }
</script>
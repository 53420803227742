<template>
    <div>
        <eod-stockmovement-quantity label="Aantal" :readonly="readonly" :value="value?value.quantity:null" @input="updateQuantity"></eod-stockmovement-quantity>
        <div class="mt-4">
        <eod-objects-treeview-input label="Geproduceerd door" return-object :value="value.products" @input="updateProducts" multiple :classes="['COMPANY','PROJECT','DEVICE','DEVICE_FOLDER']" :selectable="['DEVICE']"></eod-objects-treeview-input>
    </div>
    </div>
</template>
<script>
import eodObjectsTreeviewInput from '../eod-objects-treeview-input.vue';
import eodStockmovementQuantity from '../eod-stockmovement-quantity.vue';
export default {
    name: 'eod-article-stock-movement',
    components:{
        eodObjectsTreeviewInput,
        eodStockmovementQuantity
    },
    props:{
        cell: Object,
        value: Object,
        readonly: Boolean
    },
    data:() => ({
        type: '-'
    }),
    created(){
        if(this.value && this.value.quantity){
            if(this.value.quantity < 0){
                this.type = '-';
            } else {
                this.type = '+';
            }
        }
    },
    methods:{
        updateQuantity(quantity){
            const val = this.value
            if(this.type == '+'){
                val.quantity = quantity;
            } else {
                val.quantity = quantity * -1;
            }

            this.$emit('input', val);
        },
        changeType(type){
            const val = this.value;
            if(type == '+'){
                val.quantity = Math.abs(val.quantity);
            } else {
                val.quantity = val.quantity * -1;
            }

            this.$emit('input', val);
        },
        updateProducts(products){
            const val = this.value;
            val.products = products;

            this.$emit('input', val);
        }
    }
}
</script>
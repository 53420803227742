import { eod } from '../..';

export default class MeasurementType {
    static Metric_DataTypes = [{value:'BOOLEAN', text:'Boolean'},{value:'FLOAT', text:'Float'},{value:'STRING', text:'String'}];
    static Metric_Methods = [{value:'MONITORED', text:'Monitored', info:'Abonneer u op de waarde op de IoT gateway en ontvang de waarde ervan wanneer deze is gewijzigd. Dit wordt meestal gebruikt voor booleaanse gegevens (zoals klepposities) of stringgegevens (zoals batchnummers)'},{value:'POLLED', text:'Polled', 'info':'verzamel de waarde met regelmatige tussenpozen, bijvoorbeeld elke seconde. Het resulterende datapunt krijgt de tijd waarop de peiling is gestart'}];

    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'name'
    },
    {
        icon: 'mdi-calendar',
        label: 'Aanmaakdatum',
        type: 'date-between',
        filter: 'createdAt'
    },];
    static exportFields = ['id', 'name', 'description', 'unit{id}', 'isWritable', 'field', 'metricConfig{hardwareId dataType factor offset interval method}', 'valueConfigList{name}', 'formConfig{enabled required photo photoRequired comment commentRequired type category subCategory defaultValue values}'];
    static moduleName = 'measurementTypes';

    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getListItems(){
        if (this.valueConfigListItems && this.valueConfigListItems.length > 0) {
            return this.valueConfigListItems;
        } else if (this.valueConfigList && this.valueConfigList.listItems && this.valueConfigList.listItems.length > 0) {
            return this.valueConfigList.listItems;
        }else if (this.formConfig && this.formConfig.type == 'yesno'){
            return [
                {
                    threshold:'0',
                    name: 'Nee'
                },
                {
                    threshold:'1',
                    name: 'Ja'
                }
            ];
        }

        return [];
    }
    getListItemsByThreshold() {
        let itemsByThreshold = {};
        let listItems = this.getListItems();
        
        for (let i = 0; i < listItems.length; i++) {
            const listItem = listItems[i];
            itemsByThreshold[listItem.threshold] = listItem;
        }

        return itemsByThreshold;
    }
    async getSaveData() {
        let data = Object.assign({}, this);

        if(data.hasOwnProperty('templates')){
            data.templates = [];
            if (this.templates) {
                for (let i = 0; i < this.templates.length; i++) {
                    let template = this.templates[i];
                    let id = await eod.save('Template', template).then(result => {
                        if (result.data.data.createTemplate) {
                            return result.data.data.createTemplate.id;
                        } else {
                            return result.data.data.updateTemplate.id;
                        }
                    });

                    this.templates[i].id = id;

                    data.templates.push({
                        id: id,
                        default: template.default ? template.default : false
                    });
                }
            }
        }

        if(data.hasOwnProperty('valueConfigList')){

            if(!data.valueConfigList){
                data.valueConfigListId = null;
            }else{
                const listItems = data.valueConfigList.listItems;
    
                if(!data.valueConfigList.name){
                    data.valueConfigList.name = data.name;
                }

                delete data.valueConfigList.listItems;

                data.valueConfigListId = await eod.save('List', data.valueConfigList).then(response => {
                    if(response && response.data.data){
                        if(response.data.data.createList){
                            return response.data.data.createList.id;
                        }else if (response.data.data.updateList){
                            return response.data.data.updateList.id;
                        }
                    }
                });
    
                if(listItems){
                    for (let i = 0; i < listItems.length; i++) {
                        const listItem = listItems[i];
                        if(listItem.name && !isNaN(listItem.threshold)){
                            listItem.listId = data.valueConfigListId;

                            await eod.save('ListItem', listItem).then((response) => {
                                if(response && response.data.data){
                                    if(response.data.data.createListItem){
                                        return {id:response.data.data.createListItem.id};
                                    }else if (response.data.data.updateListItem){
                                        return {id:response.data.data.updateListItem.id};
                                    }
                                }
                            });
                            
                        }
                    }
                }
            }
            
            delete data.valueConfigList;
        }

        if(data.hasOwnProperty('metricConfig')){
            const floats = ['factor', 'offset', 'interval'];
            if(data.metricConfig){
                for (let i = 0; i < floats.length; i++) {
                    const propName = floats[i];
                    if(data.metricConfig[propName] != null){
                        data.metricConfig[propName] = parseFloat(data.metricConfig[propName]);
                    }
                }
            }
        }

        if(data.hasOwnProperty('unit')){
            data.unitId = data.unit?data.unit.id:null;
        }
        delete data.unit;

        

        return data;
    }
};
import Template from './template';

export default class AlarmRule extends Template {
    static types = [
        { value: 'thresholds', text: 'Bovengrens/ondergrens' },
        { value: 'deadman', text: 'Timeout' },
        { value: 'listitems', text: 'Absolute waardes' }
    ];

    static getType(typeName){
        for (let i = 0; i < this.types.length; i++) {
            const type = this.types[i];
            if(type.value == typeName){
                return type;
            }
        }

        return null;
    }

    static listItemSeverities = [
        {
            text: 'Oké',
            value: 'ok',
            color: 'success lighten-5'
        },
        {
            text: 'Informatief',
            value: 'info',
            color: 'info lighten-5'
        },
        {
            text: 'Waarschuwing',
            value: 'warning',
            color: 'warning lighten-5'
        },
        {
            text: 'Kritiek',
            value: 'critical',
            color: 'error lighten-5'
        }
    ];

    static severities = [
        {
            value:'criticalLow',
            color: 'error lighten-5',
            hex: '#ff5252',
            text: 'Kritiek',
            icon: 'mdi-arrow-down'
        },
        {
            value:'warningLow',
            color: 'warning lighten-5',
            hex: '#ffc107',
            text: 'Waarschuwing',
            icon: 'mdi-arrow-down'
        },
        {
            value:'infoLow',
            color: 'info lighten-5',
            hex: '#2196f3',
            text: 'Informatief',
            icon: 'mdi-arrow-down'
        },
        {
            value:'ok',
            color: 'success lighten-5',
            hex: '#4caf50',
            text: 'Oké',
            icon: null
        },
        {
            value:'infoHigh',
            color: 'info lighten-5',
            hex: '#2196f3',
            text: 'Informatief',
            icon: 'mdi-arrow-up'
        },
        {
            value:'warningHigh',
            color: 'warning lighten-5',
            hex: '#ffc107',
            text: 'Waarschuwing',
            icon: 'mdi-arrow-up'
        },
        {
            value:'criticalHigh',
            color: 'error lighten-5',
            hex: '#ff5252',
            text: 'Kritiek',
            icon: 'mdi-arrow-up'
        },
    ];
    static searchConfig = [
        {
            'icon': 'mdi-form-select',
            'label': 'Jobtype',
            'type': 'relation',
            'settings': {
                'module': 'jobTypes',
                'id': 'jobTypeId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'jobTypes_templates'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'relation',
            'settings': {
                'module': 'projects',
                'id': 'projectId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'projects_templates'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'relation',
            'settings': {
                'module': 'companies',
                'id': 'companyId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'companies_templates'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'relation',
            'settings': {
                'module': 'services',
                'id': 'serviceId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'services_templates'
        },
        {
            'icon': 'mdi-waveform',
            'label': 'Type meetwaarde',
            'type': 'relation',
            'settings': {
                'module': 'measurementTypes',
                'id': 'measurementTypeId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'measurementTypes_templates'
        },
        {
            'icon': 'mdi-package-variant',
            'label': 'Toestel',
            'type': 'relation',
            'settings': {
                'module': 'products',
                'id': 'productId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'products_templates'
        },
        {
            'icon': 'mdi-account-outline',
            'label': 'Eigenaar',
            'type': 'relation',
            'settings': {
                'module': 'users',
                'id': 'userId',
                'fields': ['name'],
                'title': '{name}',
                'options': {
                    enabled: true
                }
            },
            'filter': 'users_templates'
        },
        {
            'icon': 'mdi-toggle-switch-outline',
            'label': 'Status',
            'type': 'active',
            'filter': 'isActive'
        },
        {
            'icon': 'mdi-calendar',
            'label': 'Aanmaakdatum',
            'type': 'date-between',
            'filter': 'createdAt'
        },
    ];
}
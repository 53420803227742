import Response from './response';
import eod from './../classes/eod';
import moment from 'moment-timezone';
import Project from './project';
import TaskType from './taskType';

export default class Task {
    static exportFields = ['id', 'name', 'parent{id name}', 'category', 'organization{id}', 'project{name}', 'company{name}', 'service{name}', 'stepName', 'stepType', 'reportedAt', 'description', 'isCompleted', 'isJobActive', 'postalCode', 'statusCode', 'dueRequestDate', 'dueAfterDate', 'dueBeforeDate', 'createdAt', 'updatedAt'];
    static exportFilters = {
        isActive: false,
        where: [{
            column: 'category',
            operator: '=',
            value: 'JOB'
        }, {
            column: 'statusCode',
            operator: '!=',
            value: 'done'
        }]
    }
    static moduleName = 'tasks';
    static searchConfig = [
        {
            'icon': 'mdi-format-text',
            'label': 'Stap',
            'type': 'property',
            'filter': 'stepName'
        },
        {
            'icon': 'mdi-clipboard-outline',
            'label': 'Workflow',
            'type': 'module',
            'settings': {
                'module': 'taskTypes',
                'fields': ['name'],
                'title': '{name}',
            },
            'filter': 'taskTypeId'
        },
        {
            'icon': 'mdi-folder-open-outline',
            'label': 'Project',
            'type': 'module',
            'settings': {
                'module': 'projects',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'projectId'
        },
        {
            'icon': 'mdi-file-sign',
            'label': 'Dienst',
            'type': 'module',
            'settings': {
                'module': 'services',
                'fields': ['name'],
                'title': '{name}',
            },

            'filter': 'serviceId'
        },
        {
            'icon': 'mdi-office-building-outline',
            'label': 'Bedrijf',
            'type': 'module',
            'settings': {
                'module': 'companies',
                'fields': ['name', 'description'],
                'title': '{name} ({description})',
            },

            'filter': 'companyId'
        },
        {
            'icon': 'mdi-tag-outline',
            'label': 'Status',
            'type': 'options',
            'settings': {
                'options': (settings) => {

                    let statusCodes = [];

                    for (let i = 0; i < settings.statusCodes.length; i++) {
                        const statusCode = settings.statusCodes[i];
                        if (statusCode.job_enabled) {
                            statusCodes.push(statusCode);
                        }
                    }

                    return statusCodes;
                }
            },
            'filter': 'statusCode'
        },
        {
            'icon': 'mdi-calendar',
            'label': 'Uitvoeringsdatum',
            'type': 'date-between',
            'filter': 'reportedAt'
        },
        {
            'icon': 'mdi-calendar',
            'label': 'Planningsdatum',
            'type': 'date-between',
            'filter': 'dueRequestDate'
        },
        {
            'icon': 'mdi-alarm',
            'label': 'Wijzigingsdatum',
            'type': 'date-between',
            'filter': 'updatedAt'
        },
        {
            'icon': 'mdi-account-outline',
            'label': 'Verantwoordelijke',
            'type': 'relation',
            'settings': {
                'module': 'searchUsers',
                'fields': ['firstName', 'lastName', 'username'],
                'title': '{firstName} {lastName} ({username})',
                'options': {
                    enabled: true
                }
            },
            'filter': 'responsibleUsers'
        },
        {
            icon: 'mdi-calendar',
            label: 'Aanmaakdatum',
            type: 'date-between',
            filter: 'createdAt'
        },
    ];
    static fields = {
        name: String,
        responses_count: Number,
        responses: Response,
    }
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    static getStatusCodes() {
        const settings = require('./../classes/settings');
        return settings.statusCodes;
    }
    static getStatusCode(code) {
        const settings = require('./../classes/settings');
        const codes = settings.statusCodes;
        for (let i = 0; i < codes.length; i++) {
            if (codes[i].value == code) {
                return codes[i];
            }
        }

        return null;
    }
    _statusCode = null;
    getStatusCode() {
        if (!this._statusCode) {
            this._statusCode = this.constructor.getStatusCode(this.statusCode);
        }

        return this._statusCode;
    }
    getResponsesWithStatus(arr_statusses) {
        let responses = [];

        if (this.responses) {
            for (let i = 0; i < this.responses.length; i++) {
                const response = this.responses[i];
                if (arr_statusses.includes(response.statusCode)) {
                    responses.push(response);
                }
            }
        }

        return responses;
    }
    getResponse() {
        if (this.responses && this.responses[this.responses.length - 1]) {
            return this.responses[this.responses.length - 1];
        }

        return null;
    }
    getTotalTime() {
        var total = 0;
        if (this.timetrackings) {
            for (let i = 0; i < this.timetrackings.length; i++) {
                const timetracking = this.timetrackings[i];
                total += timetracking.value;
            }
        }
        return moment.duration(total, 'milliseconds');
    }
    getTotalTimeString() {
        var total = this.getTotalTime();
        if (total) {
            return total.hours().pad(2) + ':' + total.minutes().pad(2) + ':' + total.seconds().pad(2);
        }
        return '';
    }
    loadTaskType() {
        return eod.getById('taskType', this.taskType.id, ['id name description templates{id type content} measurementTypes{id name description unit{id name} valueConfigList{id name itemType description listItems{id name isActive op low high threshold stepSize} isActive} valueConfigListItems{id name isActive op low high threshold stepSize} formConfig{category type subCategory enabled required photo photoRequired comment commentRequired default defaultValue values}}'])
            .then(response => {
                if (response.data.data.taskType) {
                    this.taskType = response.data.data.taskType;
                    return true;
                }
            }).catch(() => {
                return false;
            });
    }
    loadProducts() {
        return eod.getById('task', this.id, ['products{id name description quantity stockLocation{id name}}'])
            .then(response => {
                if (response.data.data.task) {
                    this.products = response.data.data.task.products;
                    return this.products;
                }
            }).catch(() => {
                return false;
            });
    }
    loadProductTypes() {
        return eod.getById('task', this.id, ['productTypes{id name description quantity stockLocation{id name}}'])
            .then(response => {
                if (response.data.data.task) {
                    this.productTypes = response.data.data.task.productTypes;
                    return this.productTypes;
                }
            }).catch(() => {
                return false;
            });
    }
    isNew() {
        return !isNaN(this.id);
    }
    addTimetracking(tracking) {
        if (!(this.responses && this.responses[0])) {
            return false;
        }

        if (!this.responses[0].timetrackings) {
            this.responses[0].timetrackings = [];
        }

        this.responses[0].timetrackings.push(tracking);
    }
    set companyId(companyId) {
        this.company = {
            id: companyId
        }
    }
    get companyId() {
        return this.company ? this.company.id : null;
    }
    set projectId(projectId) {
        this.project = {
            id: projectId
        }
    }
    get projectId() {
        return this.project ? this.project.id : null;
    }
    set serviceId(serviceId) {
        this.service = {
            id: serviceId
        }
    }
    get serviceId() {
        return this.service ? this.service.id : null;
    }
    set taskTypeId(taskTypeId) {
        this.taskType = {
            id: taskTypeId
        }
    }
    get taskTypeId() {
        return this.taskType ? this.taskType.id : null;
    }
    get taskType() {
        return new TaskType(this._taskType);
    }
    set taskType(taskType) {
        this._taskType = taskType;
    }
    get project() {
        return new Project(this._project);
    }
    set project(project) {
        this._project = project;
    }
    get dueBeforeDateFormatted() {
        if (this.dueBeforeDate) {
            return moment(this.dueBeforeDate).format('YYYY-MM-DD HH:mm');
        }
        return null;
    }
    set dueBeforeDateFormatted(value) {
        this.dueBeforeDate = moment(value, 'YYYY-MM-DD HH:mm').toISOString();
    }
    get dueAfterDateFormatted() {
        if (this.dueAfterDate) {
            return moment(this.dueAfterDate).format('YYYY-MM-DD HH:mm');
        }
        return null;
    }
    set dueAfterDateFormatted(value) {
        this.dueAfterDate = moment(value, 'YYYY-MM-DD HH:mm').toISOString();
    }
    get dueRequestDateFormatted() {
        if (this.dueRequestDate) {
            return moment(this.dueRequestDate).format('YYYY-MM-DD');
        }
        return null;
    }
    set dueRequestDateFormatted(value) {
        this.dueRequestDate = moment(value, 'YYYY-MM-DD').toISOString();
    }
    get completedDateFormatted() {
        if (this.completedDate) {
            return moment(this.completedDate).format('YYYY-MM-DD HH:mm');
        }
        return null;
    }
    set completedDateFormatted(value) {
        this.completedDate = moment(value, 'YYYY-MM-DD HH:mm').toISOString();
    }
    setValue(property, value) {
        this[property] = value;
    }
    async generateDuplicate(data) {

        let jobData = await this.getSaveData();

        delete jobData.id;

        jobData = {
            ...jobData,
            ...data,
        }

        return jobData;
    }
    async generateJob(data) {
        let taskData = await this.getSaveData();

        delete taskData.id;
        delete taskData.responses;
        delete taskData.templates;

        let job = {
            ...taskData,
            parentTaskId: this.id,
            category: 'JOB',
            isCompleted: false,
            statusCode: 'to_do',
            ...data,
        }

        return job;
    }
    getSendData() {
        let data = Object.assign({}, this);

        data.taskType = data._taskType;
        data.project = data._project;

        delete data._taskType;
        delete data._project;

        return data;
    }
    async save() {
        let data = await this.getSaveData();
        return eod.save('Task', data);
    }
    getRawData(){
        let data = Object.assign({}, this);
        
        if (data.dueRequestDate) {
            data.dueRequestDate = this.dueRequestDateFormatted;
        }

        const oneToManyRelations = ['company', 'owner', 'service', 'project', 'taskType', 'jobType', 'stockLocation', 'responsibleGroup', 'route'];
        for (let i = 0; i < oneToManyRelations.length; i++) {
            const relation = oneToManyRelations[i];
            if (data.hasOwnProperty(relation)) {
                if (data[relation] && data[relation].id) {
                    data[relation] = {id:data[relation].id};
                } else {
                    data[relation] = data[relation];
                }
            } else if (data.hasOwnProperty('_' + relation)) {
                if (data['_' + relation] && data['_' + relation].id) {
                    data[relation] = {id:data['_' + relation].id};
                } else if (data['_' + relation]) {
                    data[relation] = data['_' + relation];
                } else {
                    data[relation] = null;
                }
            }

            delete data['_' + relation];
        }

        const manyToManyRelations = ['skills', 'responsibleUsers', 'products', 'productTypes', 'articles', 'articleTypes','tools', 'toolTypes'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                if(data[relationName]){
                    for (let j = 0; j < data[relationName].length; j++) {
                        const relationData = {
                            id: data[relationName][j].id
                        };
                        if(!isNaN(data[relationName][j].quantity)){
                            relationData.quantity = parseFloat(data[relationName][j].quantity);
                        }
                        if(['articles'].includes(relationName) && data[relationName][j].hasOwnProperty('products')){
                            relationData.products = data[relationName][j].products?data[relationName][j].products.map(item => ({id:item.id})):null;
                        }
                        data[relationName][j] = relationData;
                    }
                }
            }
        }

        delete data._statusCode;

        return data;
    }
    async getSaveData() {
        let data = Object.assign({}, this);

        if (this.parent) {
            data.parentTaskId = this.parent.id;
        }

        if (data.dueRequestDate) {
            data.dueRequestDate = this.dueRequestDateFormatted;
        }

        const oneToManyRelations = ['company', 'owner', 'service', 'project', 'taskType', 'jobType', 'stockLocation', 'responsibleGroup', 'route'];
        for (let i = 0; i < oneToManyRelations.length; i++) {
            const relation = oneToManyRelations[i];
            if (data.hasOwnProperty(relation)) {
                if (data[relation]) {
                    data[relation + 'Id'] = data[relation].id;
                } else {
                    data[relation + 'Id'] = null;
                }
            } else if (data.hasOwnProperty('_' + relation)) {
                if (data['_' + relation]) {
                    data[relation + 'Id'] = data['_' + relation].id;
                } else {
                    data[relation + 'Id'] = null;
                }
            }

            delete data['_' + relation];
            delete data[relation];
        }

        const manyToManyRelations = ['skills', 'responsibleUsers', 'products', 'productTypes', 'articles', 'articleTypes','tools', 'toolTypes'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relationName = manyToManyRelations[i];
            if (data.hasOwnProperty(relationName)) {
                if(data[relationName]){
                    for (let j = 0; j < data[relationName].length; j++) {
                        const relationData = {
                            id: data[relationName][j].id
                        };
                        if(!isNaN(data[relationName][j].quantity)){
                            relationData.quantity = parseFloat(data[relationName][j].quantity);
                        }
                        if(['articles'].includes(relationName) && data[relationName][j].hasOwnProperty('products')){
                            relationData.products = data[relationName][j].products?data[relationName][j].products.map(item => ({id:item.id})):null;
                        }
                        data[relationName][j] = relationData;
                    }
                }
            }
        }

        if (this.hasOwnProperty('templates')) {
            data.templates = [];
            if (this.templates) {
                for (let i = 0; i < this.templates.length; i++) {
                    let template = this.templates[i];
                    template.object = 'TASK';

                    if(!template.id && data.projectId){
                        template.projects = [{id: data.projectId}];
                    }

                    let id = await eod.save('Template', template).then(result => {
                        if (result.data.data.createTemplate) {
                            return result.data.data.createTemplate.id;
                        } else {
                            return result.data.data.updateTemplate.id;
                        }
                    });

                    data.templates.push({
                        id: id,
                        default: template.default ? template.default : false
                    });
                }
            }
        }


        delete data._statusCode;
        delete data.storageObjects;
        delete data.parent;
        delete data.isTrusted;
        delete data.responses;
        delete data.children;

        return data;
    }
}
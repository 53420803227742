module.exports = {
    props:{
        variables: Object,
    },
    data() {
        return {
            historyDialog: false,
            showSetValueDialog: false,
            requests: [],
            newValue: {
                serie: null,
                tile: null,
                valueItems: null,
                value: null
            },
        }
    },
    methods: {
        getRequestSettings(settings){
            if(settings.request){
                if(!settings.request.isWritable){
                    return null;
                }

                return this.resolveRequestParams(settings.request);
            }else if(this.getValueQuery()){
                if(!this.getValueQuery().isWritable){
                    return null;
                }

                return this.resolveRequestParams(this.getValueQuery());
            }
        },
        resolveRequestParams(settings){
            if(!settings){
                return settings;
            }

            settings = {...settings};

            for(const key in settings){
                const value = settings[key];

                if(value && value.isVariable){
                    if(this.variables && this.variables[value.id] && this.variables[value.id].id){
                        settings[key] = this.variables[value.id].id;
                    }else{
                        settings[key] = null;
                    }
                }
            }

            return settings;
        },
        getValueQuery(){
            return this.value?this.value[0]:null;
        },
        getValueTemplate(){
            if(this.template && this.template[0]){
                return this.template[0];
            }

            return null;
        },
        async getMeasurementType() {
            return new Promise((resolve, reject) => {
                if(this.value && this.getValueQuery()){
                    const measurementType = Object.values(this.getValueQuery().measurementTypes)[0];
                    resolve({
                        ...measurementType,
                        valueItems: this.$eod.getMeasurementTypeItems(measurementType),
                        unitFields: this.$eod.getMeasurementTypeUnitFields(measurementType)
                    });
                }else{
                    if(this.getValueTemplate()){
                        const templateMeasurementType = this.getValueTemplate().content.measurementTypes[0].object;
                        this.$eod.getById('measurementType', templateMeasurementType.id, ['id', 'name', 'typeConfig', 'valueConfigList{id itemType listItems{id name description isActive op low high threshold stepSize}}', 'valueConfigListItems{id name description isActive op low high threshold stepSize}']).then(response => {
                            if(response.data.data.measurementType){
                                const measurementType = response.data.data.measurementType;
                                resolve({
                                    ...measurementType,
                                    valueItems: this.$eod.getMeasurementTypeItems(measurementType),
                                    unitFields: this.$eod.getMeasurementTypeUnitFields(measurementType)
                                });
                            }else{
                                resolve(null);
                            }
                        }).catch(() => {
                            resolve(null);
                        });
                    }else{
                        resolve(null);
                    }
                }
            });
        },
        async showDialog(val) {
            const {owner_id, project_id, product_id, job_type_id} = this.resolveRequestParams(this.cell.settings.request?this.cell.settings.request:this.getValueQuery());
            const measurementType = await this.getMeasurementType();

            this.newValue = {
                value: val,
                measurementType: measurementType,
                ownerId: owner_id,
                projectId: project_id,
                productId: product_id,
                jobTypeId: job_type_id,
            };

            this.showSetValueDialog = true;
        },
        showSetValueButton() {

            if(this.cell && this.cell.settings && this.getRequestSettings(this.cell.settings)){
                return true;
            }

            return false;
        },
        async getRequests(amount) {

            const {owner_id, project_id, product_id, job_type_id} = this.resolveRequestParams(this.cell.settings.request?this.cell.settings.request:this.cell.settings.queries[0]);
            const measurementType = await this.getMeasurementType();

            let filter = {
                orderBy: {
                    column: 'createdAt',
                    type: 'DESC'
                },
                limit: amount,
                offset: 0,
                where: [
                    {
                        column: 'measurementTypeId',
                        operator: '=',
                        value: measurementType.id
                    }
                ]
            };

            if (owner_id) {
                filter.where.push({
                    column: 'ownerId',
                    operator: '=',
                    value: owner_id
                });
            }

            if (job_type_id) {
                filter.where.push({
                    column: 'jobTypeId',
                    operator: '=',
                    value: job_type_id
                });
            }

            if (project_id) {
                filter.where.push({
                    column: 'projectId',
                    operator: '=',
                    value: project_id
                });
            }

            if (product_id) {
                filter.where.push({
                    column: 'productId',
                    operator: '=',
                    value: product_id
                });
            }

            return this.$eod.get('requests', ['id', 'value', 'statusCode', 'createdAt', 'user{id firstName lastName username}'], filter);
        },
    }
}
module.exports = {
    routes: [{
        path: '/vehicles',
        name: 'vehicles',
        meta:{
            permissions:['vehicles.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/vehicles/detail/:id?',
        name: 'vehicle',
        meta:{
            permissions:['vehicles.create', 'vehicles.update']
        },
        component: () => import('./views/detail.vue')
    }],
    search: [
        {
            'icon': 'mdi-format-text',
            'label': 'Naam',
            'type': 'property',
            'filter': 'name'
        },
        {
            'icon': 'mdi-format-text',
            'label': 'Omschrijving',
            'type': 'property',
            'filter': 'description'
        },
        {
            icon: 'mdi-calendar',
            label: 'Aanmaakdatum',
            type: 'date-between',
            filter: 'createdAt'
        },
    ],
    itemObject: require('./../../eodvuecomponents/src/models/vehicle').default,
    detail: {
        route: 'vehicle',
        endpoint: 'vehicle',
        objectName: 'Vehicle',
        fields: ['id', 'name', 'description', 'vehicleSkills{id name threshold}', 'trailerSkills{id name threshold}', 'availability{startDateTime endDateTime endTimeUnspecified recurrence}', 'brand', 'catalogueValue', 'category', 'chassisNumber', 'co2GKm', 'color', 'consumptionCost', 'contractType', 'depot{id,name}', 'ecoScore', 'fuel', 'hp', 'isActive', 'licensePlate', 'maxPersons', 'maxSpeed', 'maxTrailerVolume', 'maxTrailerWeight', 'maxVolume', 'maxWeight', 'model', 'owner{id}', 'range', 'trailerConsumptionCost', 'trailerSkills{id,name}', 'createdAt', 'updatedAt', 'vehicleSkills{id,name}', 'supplier{id}', 'yearlyCost'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        },
        {
            tab: 'specifications',
            text: 'Specificaties'
        },
        {
            tab: 'availability',
            text: 'Beschikbaarheid',
        },
    ],
    },
    index: {
        route: 'vehicles',
        endpoint: 'vehicles',
        fields: ['id', 'name', 'description', 'category', 'depot{id,name}', 'isActive', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Omschrijving',
                value: 'description',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Depot',
                value: 'depot.name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue'),
                extra:{
                    hideRelated: true
                }
            }
        ],
        tabs: [{
            path: 'vehicles',
            text: 'Overzicht'
        }],
    },
    export: {
        endpoint: 'vehicles',
        fields: ['id', 'name', 'description', 'streetAddress', 'postalCode', 'locality', 'region', 'country', 'category', 'access', 'notes']
    },
    import: {
        endpoint: 'vehicles',
        objectName: 'Vehicle',
    },
}
<template>
    <eod-card v-bind="$attrs">
      <template v-slot:header>
        <v-card-title class="align-center">
          <div>
        <v-btn @click="goToPrev" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        {{calendarTitle}}
        <v-btn @click="gotoNext" icon><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn-toggle dense rounded :value="eventType" @change="val => changeEventType(val)">
      <v-btn :color="eventType=='available'?'success':null" :class="eventType=='available'?'white--text':''" value="available"><v-icon :class="eventType=='available'?'white--text':''" left>mdi-check</v-icon>Beschikbaarheid</v-btn>
      <v-btn :color="eventType=='unavailable'?'error':null" :class="eventType=='unavailable'?'white--text':''" value="unavailable"><v-icon :class="eventType=='unavailable'?'white--text':''" left>mdi-cancel</v-icon>Niet-beschikbaarheid</v-btn>
    </v-btn-toggle>
      </v-card-title>
      </template>
      <v-card-text>
    <FullCalendar
        ref="calendar"
        :options='calendarOptions'
      >
        
      </FullCalendar>
      <v-menu
      v-model="contextMenu.show"
      :position-x="contextMenu.xPos"
      :position-y="contextMenu.yPos"
      fixed
      offset-y
    >
      <v-list dense>
        <v-list-item @click="eventClicked({event:{id:activeEvent.id}})">
          <v-list-item-title><v-icon left>mdi-pencil-outline</v-icon>Aanpassen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteEvent(activeEvent.id)" class="error--text">
          <v-list-item-title><v-icon color="error" left>mdi-trash-can-outline</v-icon>Verwijderen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
      <eod-dialog width="800" okText="Opslaan" @okAction="saveEvent" v-if="activeEvent" v-model="isEventDialogVisible" title="Beschikbaarheid" icon="mdi-calendar" iconColor="primary">
        <eod-text-field v-if="eventType == 'unavailable'" label="Titel" class="mt-4" v-model="activeEvent.title"></eod-text-field>
        <eod-date-time-picker label="Van" class="mt-4 d-block" v-model="activeEvent.start"></eod-date-time-picker>
        <eod-date-time-picker label="Tot" class="mt-4 d-block" v-model="activeEvent.end"></eod-date-time-picker>
        <template>
        <eod-autocomplete v-model="activeEvent.rruleRaw" @change="changeEventRepeat" class="mt-4" label="Herhaal" dense outlined :items="Availability.BASIC_RECURRING_RULES"></eod-autocomplete>
        <v-card outlined class="px-4 py-4 mt-4"
              v-if="activeEvent.rruleRaw && activeEvent.rruleRaw.hasOwnProperty('until')">
              <div class="d-flex align-items-center mb-2">
                <span>Herhaal elke</span>
                <v-text-field class="mx-3" outlined dense hide-details type="number"
                  v-model="activeEvent.rruleRaw.interval"></v-text-field>
                <v-autocomplete outlined dense hide-details :items="Availability.ADVANCED_RECURRING_RULES"
                  v-model="activeEvent.rruleRaw.freq"></v-autocomplete>
              </div>
              <div class="mb-2">
                Herhalen op
                <v-chip-group multiple active-class="primary" v-model="activeEvent.rruleRaw.byweekday">
                  <v-chip v-for="day in Availability.ADVANCED_RECURRING_DAYS" :key="day.value.weekday">
                    {{ day.text }}
                  </v-chip>
                </v-chip-group>
              </div>
              Eindigt
              <v-radio-group v-model="rruleEnding" @change="changeEventEnding" hide-details>
                <v-radio :value="null">
                  <template v-slot:label>
                    <div>Nooit</div>
                  </template>
                </v-radio>
                <v-row align="center" class="align-items-center mb-2" no-gutters>
                  <v-radio value="date" label="Op" class="mb-0"></v-radio>
                  <eod-date-time-picker class="ml-2" :icon="null" mode="date" :disabled="rruleEnding != 'date'" v-model="activeEvent.rruleRaw.until"></eod-date-time-picker>
                </v-row>
                <v-radio value="count">
                  <template v-slot:label>
                    <div class="d-flex">
                      <div class="d-flex align-items-center">Na</div><v-text-field
                        v-model="activeEvent.rruleRaw.count"
                        :disabled="rruleEnding != 'count'" class="ml-2" dense outlined
                        hide-details type="number" style="width:80px;"></v-text-field>
                      <div class="grey lighten-2 px-2 d-flex align-items-center"
                        style="border-radius: 0 5px 5px 0;margin-left:-4px;">keer</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-card>
          </template>
      </eod-dialog>
    </v-card-text>
    </eod-card>
</template>
<style lang="css" scoped>
.fc>>>.fc-toolbar.fc-header-toolbar {
  display: none;
}

.fc>>>.fc-timegrid-slots tr {
    line-height: 14px !important;
}
.fc>>>.fc-timegrid-slots td {
    height: unset !important;
}
.fc>>>.fc-timegrid-col.fc-day-today {
    background-color: transparent;
}
.fc>>>.fc-col-header-cell.fc-day-today {
    background-color: var(--v-primary-base);
}
.fc>>>.fc-col-header-cell.fc-day-today a {
  color: #ffffff !important;
}
</style>
<script>
import { Availability } from '@/eodvuecomponents';
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'
import nlLocale from '@fullcalendar/core/locales/nl';
import { v4 as uuidv4 } from 'uuid';
import { RRule } from 'rrule';
import eodDateTimePicker from './eod-date-time-picker.vue';

export default {
  name: 'eod-object-availability',
  props: {
    value: Array,
    endpoint: String,
    itemId: String,
  },
  components:{
    FullCalendar,
    eodDateTimePicker
  },
  data() {
    return {
      eventType: 'unavailable',
      rruleEnding: null,
      calendarTitle: null,
      contextMenu:{
        show: false,
        xPos: 0,
        yPos: 0
      },
      events: [],
      Availability: Availability,
      activeEvent: null,
      isEventDialogVisible: false,
      calendarOptions: {
        locale:nlLocale,
        contentHeight:'auto',
        plugins: [
          rrulePlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        headerToolbar: {
          left: '',
          center: '',
          right: ''
        },
        initialView: 'timeGridWeek',
        views: {
          timeGridWeek: {
            allDaySlot:false,
          }
        },
        events: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: false,
        weekends: true,
        select: this.dateSelected,
        eventClick: this.eventClicked,
        eventChange:this.eventChanged,
        eventClassNames: this.eventClassNames,
        eventDidMount: this.eventMounted
      },
    }
  },
  created(){
    this.loadAvailabilities();
  },
  mounted(){
    this.updateTitle();
  },
  methods: {
    deleteEvent(eventId){
      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if(event.id == eventId){
          if(isNaN(event.id)){
            this.events.splice(i, 1);
          } else {
            this.events[i].isDeleted = true;
          }
          break;
        }
      }

      this.refreshEvents();
      this.saveEvents();
    },
    eventMounted(info){
      if(info.event.display == 'auto'){
        const eventId = info.event.id;
        info.el.addEventListener("contextmenu", (e)=>{
            e.preventDefault();
            this.setActiveEvent(eventId);
            this.contextMenu.yPos = e.y;
            this.contextMenu.xPos = e.x;
            this.contextMenu.show = true;
        });
      }
    },
    eventClassNames(info){
      if(info.isMirror){
        return this.eventType == 'available'?['success']:['error'];
      } 
    },
    changeEventType(type){
      this.eventType = type;
      this.refreshEvents();
    },
    updateTitle(){
      if(this.$refs.calendar){
        this.calendarTitle = this.$refs.calendar.getApi().view.title;
      }
    },
    gotoNext(){
      if(this.$refs.calendar){
        this.$refs.calendar.getApi().next();
        this.updateTitle();
      }
    },
    goToPrev(){
      if(this.$refs.calendar){
        this.$refs.calendar.getApi().prev();
        this.updateTitle();
      }
    },
    changeEventRepeat(val){
      this.rruleEnding = null;
      if (val && val.hasOwnProperty('until')) {
        this.activeEvent.rruleRaw.byweekday = [this.$moment(this.activeEvent.start).day()-1]
      }
    },
    changeEventEnding(val){
      this.activeEvent.rruleRaw.until = null;
      this.activeEvent.rruleRaw.count = null;
    },
    eventChanged(info){
      this.setActiveEvent(info.event.id);
      this.activeEvent.start = this.$moment(info.event.start).toISOString();
      this.activeEvent.end = this.$moment(info.event.end).toISOString();
      this.saveEvent();
    },
    saveEvent(){
      this.activeEvent.rrule = null;
      if(this.activeEvent.rruleRaw){
        const rule = new RRule({
          dtstart: this.$moment(this.activeEvent.start).toDate(),
          ...this.activeEvent.rruleRaw
        });
        this.activeEvent.rrule = rule.toString();
      }

      this.activeEvent.duration = 0;
      if(this.activeEvent.end){
        this.activeEvent.duration = this.$moment.duration(this.$moment(this.activeEvent.end).diff(this.activeEvent.start)).asMilliseconds();
      }

      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if(event.id == this.activeEvent.id){
          this.events[i] = this.activeEvent;
          break;
        }
      }

      this.saveEvents();
      this.refreshEvents();
      this.isEventDialogVisible = false;
    },
    dateSelected(info){
      const calendarApi = info.view.calendar;
      calendarApi.unselect() // clear date selection

      this.events.push({
          id: uuidv4(),
          title:this.eventType == 'available'?'Beschikbaar':'Niet-beschikbaar',
          type: this.eventType,
          start: this.$moment(info.startStr).toISOString(),
          end: this.$moment(info.endStr).toISOString(),
          allDay: info.allDay,
          duration: this.$moment.duration(this.$moment(info.endStr).diff(info.startStr)).asMilliseconds(),
          rrule: null,
          rruleRaw: null
        })

      this.saveEvents();
      this.refreshEvents();
    },
    refreshEvents(){
      this.calendarOptions.events = [];
      for (let i = 0; i < this.events.length; i++) {
        const event = {...this.events[i]};

        if(event.isDeleted){
          continue;
        }

        if(this.eventType == 'unavailable'){
          if(event.type == 'available'){
            event.groupId = 'available';
            event.display = 'background';
            event.color = 'var(--v-success-base)';
          } else {
            event.color = 'var(--v-error-base)';
          }
        } else {
          if(event.type == 'available'){
            event.color = 'var(--v-success-base)';
          } else {
            continue;
          }
        }

        this.calendarOptions.events.push(event);
      }
    },
    setActiveEvent(eventId){
      for (let i = 0; i < this.events.length; i++) {
        const event = this.events[i];
        if(event.id == eventId){
          this.activeEvent = {...event};
          break;
        }
      }
    },
    eventClicked(info){
      this.setActiveEvent(info.event.id);

      this.rruleEnding = null;
      if(this.activeEvent.rruleRaw){
        if(this.activeEvent.rruleRaw.until && this.activeEvent.rruleRaw.until != ''){
          this.rruleEnding = 'date';
        } else if(this.activeEvent.rruleRaw.count && this.activeEvent.rruleRaw.count != ''){
          this.rruleEnding = 'count';
        }
      }

      this.isEventDialogVisible = true;
    },
    saveEvents(){
      const availabilities = [];
      for (let i = 0; i < this.events.length; i++) {
        const event = {...this.events[i]};
        if(isNaN(event.id)){
          delete event.id;
        }
        availabilities.push(event);
      }
      this.$emit('input', availabilities);
    },
    loadAvailabilities(){
      if(this.itemId){
        this.$eod.get('availabilities', ['id','start','end','allDay', 'rrule', 'rruleRaw', 'title','duration','type'], {
          where: [{
            column: 'available_type',
            operator:'=',
            value: this.endpoint
          },
          {
            column: 'available_id',
            operator:'=',
            value: this.itemId
          }]
        }).then(response => {
          if(response && response.data && response.data.data && response.data.data.availabilities){
            this.events = response.data.data.availabilities.edges;
            this.refreshEvents();
          }
        })
      } else {
        // Get default availability from settings
        this.events = this.value;
        this.refreshEvents();
      }
    }
  }
}
</script>
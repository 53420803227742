<template>
    <v-data-table fixed-header :ref="id" :custom-sort="customSort" :footer-props="{itemsPerPageOptions:[5,10,20,50,100]}" dense :items-per-page="itemsPerPage" :headers="headers" :items="items" :sort-by="['time_interval','project_id_resolved.name', 'projects_name']">
        <template v-slot:item.time_interval="{ item }">
            {{ $moment(item.time_interval).format('YYYY-MM-DD HH:mm') }}
        </template>
        <template v-for="header in getMeasurementHeaders(template[0].content)" v-slot:[header.slotName]="{ item, header }">
        <div v-if="header.settings && header.settings.visualization == 'graph'" style="width:260px;height:120px;">
            <eod-evolution-graph :key="'graph_'+header.measurementType.id+item.project_id" ref="evolutionGraph" :from="from" :until="until" :filters="{projectId: item.project_id, productId:item.product_id}" :measurementTypeId="header.measurementType.id"></eod-evolution-graph>
        </div>
        <div class="d-flex items-center" v-else-if="header.settings && header.settings.visualization == 'time'">
            <template v-if="item['time_'+header.measurementType.id]">
                <v-icon small left>mdi-clock-outline</v-icon>
                {{$moment(item['time_'+header.measurementType.id]).format('DD/MM/YYYY HH:mm')}}
            </template>
        </div>
            <span v-else-if="header.measurementTypeItems[parseInt(item[header.value])]">{{ header.measurementTypeItems[parseInt(item[header.value])].name }}</span>
            <span v-else>{{ item[header.value] }}</span>
        </template>
        <template v-slot:item.project_id_resolved.name="{ item }">
            <router-link v-if="item.project_id_resolved" class="decoration-none" :to="{name:'dashboard.project', params:{id:item.project_id}}">{{ item.project_id_resolved.name }}</router-link>
        </template>
        <template v-slot:item.product_id_resolved.name="{ item }">
            <router-link v-if="item.product_id_resolved" class="decoration-none" :to="{name:'dashboard.product', params:{id:item.product_id}}">{{ item.product_id_resolved.name }}</router-link>
        </template>
        <template v-slot:item.severity_code="{ item }">
            <v-chip v-if="item.severity_code" small :color="Event.getSeverityByCode(item.severity_code) ? Event.getSeverityByCode(item.severity_code).color : 'default'"
                dark>
                {{ Event.getSeverityByCode(item.severity_code) ? Event.getSeverityByCode(item.severity_code).text : item.severity_code }}
            </v-chip>
        </template>
        <template v-slot:item.projects_name="{ item }">
            <router-link v-if="item.project_id_resolved" class="decoration-none" :to="{name:'dashboard.project', params:{id:item.project_id}}">
                <div v-if="!cell || !cell.settings.queries[0] || !cell.settings.queries[0].showFields || (cell.settings.queries[0] && cell.settings.queries[0].showFields && cell.settings.queries[0].showFields.includes('name'))"><strong>{{ item.project_id_resolved.name }}</strong></div>
                <div v-if="cell && cell.settings.queries[0] && cell.settings.queries[0].showFields && cell.settings.queries[0].showFields.includes('description')">{{ item.project_id_resolved.description }}</div>
            </router-link>
        </template>
        <template v-slot:item.products_name="{ item }">
            <router-link v-if="item.product_id_resolved" class="decoration-none" :to="{name:'dashboard.product', params:{id:item.product_id}}">
                <div v-if="!cell || !cell.settings.queries[0] || !cell.settings.queries[0].showFields || (cell.settings.queries[0] && cell.settings.queries[0].showFields && cell.settings.queries[0].showFields.includes('name'))"><strong>{{ item.product_id_resolved.name }}</strong></div>
                <div v-if="cell && cell.settings.queries[0] && cell.settings.queries[0].showFields && cell.settings.queries[0].showFields.includes('description')">{{ item.product_id_resolved.description }}</div>
            </router-link>
        </template>
    </v-data-table>
</template>
<script>
import Event from './../../models/event';
export default {
    name: 'eod-graph-table',
    components:{
        'eod-evolution-graph': () => import('./../eod-evolution-graph.vue')
    },
    props: {
        id: String,
        value: Object | Array,
        template: Object | Array,
        cell: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        from: Object,
        until: Object,
    },
    data: () => {
        return {
            itemsPerPage: 100,
            Event:Event
        }
    },
    computed: {
        items() {
            let items = [];

            if (this.template && this.value) {
                for (let i = 0; i < this.value.length; i++) {
                    const val = this.value[i];
                    items = items.concat(val.data);
                }
            }

            return items;
        },
        headers() {
            let headers = [];

            if (this.template && this.template[0]) {
                const content = this.template[0].content;
                if (content.source == 'responses' || content.source == 'measurements') {
                    if(content.group.key == 'time'){
                        headers.push({
                            text: 'Tijdstip',
                            value: 'time_interval'
                        });

                        headers.push({
                            text: 'Toestel',
                            value: 'product_id_resolved.name'
                        });

                        headers.push({
                            text: 'Eigenaar',
                            value: 'owner_id_resolved.username'
                        });
                    }else{
                        headers.push({
                            text: content.group.title,
                            value: content.group.key+'_resolved.name'
                        });
                    }

                    headers = headers.concat(this.getMeasurementHeaders(content));
                } else if (content.source == 'events') {
                    if(content.group.key == 'time'){
                        headers.push({
                            text: 'Tijdstip',
                            value: 'time_interval'
                        });
                        headers.push({
                            text: 'Status',
                            value: 'severity_code'
                        });
                    }else{
                        headers.push({
                        text: 'Tijdstip',
                        value: 'time'
                    });
                    }
                    
                } else if (content.source == 'projects') {
                    let label = 'Project';
                    if(this.cell && this.cell.settings.queries[0] && this.cell.settings.queries[0].label && this.cell.settings.queries[0].label != ''){
                        label = this.cell.settings.queries[0].label;
                    }

                    headers.push({
                        text: label,
                        value: 'projects_name'
                    });

                    headers = headers.concat(this.getMeasurementHeaders(content));
                } else if (content.source == 'products') {
                    let label = 'Product';
                    if(this.cell && this.cell.settings.queries[0] && this.cell.settings.queries[0].label && this.cell.settings.queries[0].label != ''){
                        label = this.cell.settings.queries[0].label;
                    }

                    headers.push({
                        text: label,
                        value: 'products_name'
                    });

                    headers = headers.concat(this.getMeasurementHeaders(content));
                } else {
                    headers = [
                        {
                            text: 'Taak',
                            value: 'name'
                        }
                    ];

                    for (const key in this.xAxis) {
                        if (Object.hasOwnProperty.call(this.xAxis, key)) {
                            const label = this.xAxis[key];
                            headers.push({
                                text: label,
                                value: key
                            });
                        }
                    }
                }
            }

            return headers;
        }
    },
    methods: {
        customSort(items, index, isDesc) {
            items.sort((a, b) => {
                if (a[index] === b[index]) { // equal items sort equally
                    return 0;
                } else if (a[index] === null) { // nulls sort after anything else
                    return isDesc[0]?1:-1;
                } else if (b[index] === null) {
                    return isDesc[0]?-1:1;
                } else if (!isDesc[0]) { // otherwise, if we're ascending, lowest sorts first
                    return a[index] < b[index] ? -1 : 1;
                } else { // if descending, highest sorts first
                    return a[index] < b[index] ? 1 : -1;
                }
            });

            return items;
        },
        getCellMeasurementTypeSettings(index){
            if(this.cell && this.cell.settings && this.cell.settings.queries && this.cell.settings.queries[0] && this.cell.settings.queries[0].measurementTypes){
                if(this.cell.settings.queries[0].measurementTypes[index]){
                    return this.cell.settings.queries[0].measurementTypes[index];
                }
            }

            return null;
        },
        getMeasurementHeaders(content){
            let headers = [];
            if(this.value && this.value[0] && this.value[0].measurementTypes && content.measurementTypes){
                for (let i = 0; i < content.measurementTypes.length; i++) {
                    const aggMeasurementType = content.measurementTypes[i];
                    if(aggMeasurementType.object && this.value[0].measurementTypes[aggMeasurementType.object.id]){
                        const measurementType = this.value[0].measurementTypes[aggMeasurementType.object.id];
                        let label = measurementType.name;
                        if(measurementType.unit && measurementType.unit.fields && measurementType.unit.fields[2]){
                            label += ' (' + measurementType.unit.fields[2].name + ')';
                        }

                        const setting = this.getCellMeasurementTypeSettings(i);
                        let valueName = aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                        

                        if(setting){
                            if(setting.visualization){
                                valueName = setting.visualization+'_'+aggMeasurementType.object.id;
                            }

                            if(setting.label && setting.label != ''){
                                label = setting.label;
                            }
                        }

                        headers.push({
                            text: label,
                            measurementType:measurementType,
                            measurementTypeItems: this.$eod.getMeasurementTypeItems(measurementType),
                            value: valueName,
                            slotName: 'item.'+valueName,
                            settings: setting,
                            sortable:(setting && setting.visualization && setting.visualization == 'graph')?false:true
                        });
                    }
                }
            }
            return headers;
        },
        update() {
            if (this.cell) {
                if(this.cell.settings && this.cell.settings.options && this.cell.settings.options.paging && this.cell.settings.options.paging.itemsPerPage){
                    this.itemsPerPage = this.cell.settings.options.paging.itemsPerPage;
                }
            }

            this.$emit('finished', this.id, this.$refs[this.id])
        }
    }
}
</script>
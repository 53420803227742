<template>
    <div id="eod-scheduler" class="eod-scheduler">
        <div id="eod-scheduler-wrapper" class="eod-scheduler__wrapper">
            <table class="eod-scheduler__table">
                <thead id="eod-scheduler__header">
                    <tr>
                        <th class="position-sticky col-header" rowspan="2">
                            <slot name="column-action"></slot>
                        </th>
                        <th v-for="(header, date) in headers" :key="date" :colspan="Object.keys(header.children).length">
                            {{ date }}
                        </th>
                    </tr>
                    <tr>
                        <template v-for="(header, date) in headers">
                            <th v-for="child in getSubHeaders(header)" :colspan="child.colspan"
                                :key="date + '_' + child.date"
                                :style="'min-width:' + (child.width) + 'px;max-width:' + (child.width) + 'px;'">
                                {{ child.date }}
                            </th>
                        </template>
                    </tr>
                </thead>
                    <template v-if="rows && rows.length > 0">
                    <draggable id="eod-scheduler__body"  :value="rows" @input="val => $emit('rowsMoved', val)" :move="checkIfRowCanBeMoved" tag="tbody" handle=".handle" @start="dragging=true" @end="dragging=false;">
                            <tr v-for="(row, rowIndex) in rows" :key="row.id" :id="'row_' + rowIndex" :class="{'planner-row':!row.id}">
                                <th class="position-sticky col-header draggable-row" :class="{'deletable-row':view.userRowSelector, 'editable-row':view.canEdit}" :style="'height: ' + (rowInfo[rowIndex]?rowInfo[rowIndex].height:itemHeight)  + 'px;'">
                                    <div class="eod-scheduler__row-title d-flex align-items-center">
                                        <v-btn v-if="row.id && view.userRowSelector" class="btn-delete" color="error" small icon @click="$emit('rowDelete', row)"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                                        
                                        <v-icon class="handle" v-if="row.id">mdi-drag-vertical</v-icon>
                                        <v-btn v-if="row.id && view.canEdit" class="btn-edit mr-2" x-small icon @click="showItemAvailability(row)"><v-icon x-small>mdi-pencil-outline</v-icon></v-btn>

                                        <div class="d-flex flex-column content">
                                            <slot name="column-item" v-bind:item="row">
                                                <strong>{{ row.name }}</strong>
                                            </slot>
                                        </div>
                                        
                                    </div>
                                </th>
                                <template v-for="(header, date) in headers">
                                    <td @contextmenu="(event) => contextMenuNew(event, child, row)" @click="contextMenuNew($event, child, row)" v-for="(child, childDate) in header.children" :key="date + '_' + childDate" :class="{'unavailable': !isAvailable(row, child), 'last-child':(Object.keys(header.children).length-1) == Object.keys(header.children).indexOf(childDate)}"></td>
                                </template>
                            </tr>
                    </draggable>
                    <slot name="row-footer"></slot>
                    </template>
                    <tbody v-else>
                        <tr>
                            <td :colspan="allHeaders.length + 1">
                                <div class="px-2 py-2">Geen resultaten gevonden!</div>
                            </td>
                        </tr>
                    </tbody>
            </table>
            <template v-for="(item, index) in scheduledItems" v-if="!dragging">
                <eod-scheduler-item :key="index + '_' + item.id" :show-route="$eod.getUser().can('routes.read')" :dense="dense" :value="item"
                    :ref="index+'_'+item.id"
                    :loading="scheduledItemsLoading[item.id]"
                    :rowIndex="item.rowIndex" :colWidth="colWidth" :rowY="rowInfo[item.rowIndex].yPos" :offsetY="headerHeight"
                    :row="rows[item.rowIndex]"
                    :view="view"
                    :offsetX="titleWidth"
                    :columnIndex="item.columnIndex"
                    :height="item.height"
                    :width="item.width"
                    :available="item.dueAfterDate?checkValidDate(rows[item.rowIndex], item.dueAfterDate, item.dueBeforeDate):true"
                    @resize="(newWidth) => resize(newWidth, index)" @resizeEnd="() => resized(index)"
                    @showProjectAvailability="showProjectAvailability"
                    @showItemAvailability="showItemAvailability"
                    @drag="(event) => drag(event, index)" @dragEnd="(event, itemPos) => dragEnd(event, index, itemPos)"
                    @contextmenu="(event) => contextMenu(event, index)" @acceptItem="val => $emit('acceptItem', val)"
                    @cancelItem="val => $emit('cancelItem', val)" @workflowClicked="editWorkflow" @startNewWorkflow="startNewWorkflow"></eod-scheduler-item>
            </template>
            <div v-if="nowShow" class="eod-scheduler__now"
                :style="'left:' + nowLeft + 'px;top:' + nowTop + 'px;height:calc(100% - ' + nowTop + 'px)'"></div>
            <v-menu v-model="showContextMenu" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
                <v-list dense>
                    <v-list-item @click="addExistingTask">
                        <v-list-item-icon>
                            <v-icon>mdi-clipboard-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Bestaande Taak</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="addNewTask">
                        <v-list-item-icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Nieuwe Taak</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteContextMenuItem">
                        <v-list-item-icon>
                            <v-icon color="error">mdi-trash-can-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="error--text">Ontplannen</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu v-model="showContextMenuNew" :position-x="contextMenuX" :position-y="contextMenuY" absolute offset-y>
                <v-list dense>
                    <v-list-item @click="addExistingTask">
                        <v-list-item-icon>
                            <v-icon>mdi-clipboard-plus-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Bestaande Taak</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="addNewTask">
                        <v-list-item-icon>
                            <v-icon>mdi-plus</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Nieuwe Taak</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
            <eod-delete-dialog delete-text="Ontplannen" title="Ontplannen?" v-if="isDeleteTaskDialogVisible" v-model="isDeleteTaskDialogVisible" :item="deleteItemObject" @delete="deleteItemAction">
                Weet u zeker dat u <strong>{{deleteItemObject.parent.name}}</strong> wilt ontplannen?
                <template v-if="deleteItemObject.responsibleUsers.length > 1">
                    <v-checkbox hide-details class="mt-0" :label="'Ontplannen bij '+user.getFullName()" v-model="deleteItemObjectForUsers" :value="user.id" v-for="user in deleteItemObject.responsibleUsers" :key="user.id"></v-checkbox>
                </template>
            </eod-delete-dialog>
            <eod-dialog v-if="isAddTaskDialogVisible" @okAction="addExistingTasks(addTaskSelection)" icon="mdi-plus" iconColor="primary" :hide-ok="!isAddTaskButtonVisible" ok-text="Toevoegen" :loading="isAddTaskDialogLoading" v-model="isAddTaskDialogVisible" title="Taak inplannen" width="1400">
                <eod-task-list :value="addTaskSelection" @input="tasksSelectionChanged" :defaultFilters="addTaskFilters" @click="val => addExistingTasks([val])" multiple></eod-task-list>
            </eod-dialog>
            <div v-if="loader.show" :style="loader.style" class="eod-scheduler__spinner">
                <v-progress-circular :size="20" color="primary" indeterminate></v-progress-circular>
            </div>
            <eod-workflow-dialog v-if="showWorkflowDialog" v-model="showWorkflowDialog" :workflow="activeWorkflow"
                @saved="workflowSaved" :defaultPlanning="contextMenuNewRow && contextMenuNewRow.id?true:false" @createWorkflow="showCreateWorkflow"></eod-workflow-dialog>
            <eod-dialog :loading="isPlanStepValidationLoading" width="1200" icon="mdi-exclamation" okText="Inplannen" @cancelAction="planStepValidationCancelAction" persistent @okAction="planStepValidationAction" iconColor="warning" title="Verplichte velden" v-if="isPlanStepDialogVisible" v-model="isPlanStepDialogVisible">
                <v-form ref="planStepValidationForm">
                    <p class="mt-4">Er zijn nog verplichte velden niet ingevuld voor de workflow <strong>'{{ planStepValidation.extra.workflow.name }}'</strong></p>
                    <template v-if="planStepValidation.errors.includes('variant')">
                        <eod-autocomplete class="mt-4" label="Variant*" :rules="[val => $validator.required(val, 'Variant')]" hide-details :items="planStepValidation.extra.jobTypeTemplate.content.variants" v-model="planStepValidation.extra.workflowTemplate.content.pages[planStepValidation.extra.formPageIndex].cells[planStepValidation.extra.formCellIndex].settings.defaultVariant"></eod-autocomplete>
                    </template>
                    <template v-if="planStepValidation.errors.includes('articles')">
                        <eod-producttypes-products-selector class="mt-4" item-required v-model="planStepValidation.extra.job"></eod-producttypes-products-selector>
                    </template>
                </v-form>
            </eod-dialog>
            <eod-workflow-dialog v-if="startWorkflowDialog && $eod.getUser().can('tasks.workflows.create')"
                v-model="startWorkflowDialog" :workflow="createWorkflowData" :nameAppend="createWorkflowNameAppend"
                @saved="workflowSaved"></eod-workflow-dialog>
            <eod-dialog v-model="isUnavailableItemDialogVisible" title="Onbeschikbaar" icon="mdi-exclamation" okText="Inplannen" iconColor="warning" @okAction="invalidDateCallback" persistent @cancelAction="cancelDateCallback">
                <div class="mt-4">{{view.itemTitle}} is niet beschikbaar op het gekozen moment, weet u zeker dat u hier wilt inplannen?</div>
            </eod-dialog>
            <eod-dialog v-model="isUnavailableProjectDialogVisible" title="Onbeschikbaar" icon="mdi-exclamation" okText="Inplannen" iconColor="warning" @okAction="isUnavailableProjectDialogAction" persistent @cancelAction="isUnavailableProjectDialogCancelAction">
                <div class="mt-4">Het project is niet beschikbaar op het gekozen moment, weet u zeker dat u hier wilt inplannen?</div>
            </eod-dialog>
            <eod-dialog :loading="isEditDialogLoading" iconColor="primary" icon="mdi-pencil-outline" v-if="isEditDialogVisible" width="800" v-model="isEditDialogVisible" title="Beschikbaarheid aanpassen" okText="Opslaan" @okAction="saveAvailabilities">
                <eod-availabilities class="mt-4" outlined v-if="activeRowItem" v-model="activeRowItem.availabilities" :endpoint="activeRowItemEndpoint" :itemId="activeRowItem.id"></eod-availabilities>
            </eod-dialog>
        </div>
    </div>
</template>
<style scoped lang="scss">
.eod-scheduler {
    width: 100%;
    //height: 100%;
    overflow: auto;
    background-color: #FFFFFF;
    border: 1px solid #eeeeee !important;

    #eod-scheduler__body {
        tr.planner-row{
            th,td{
                background-color: #dddddd;
                border-right-color: #eeeeee;

                &.last-child{
                    border-right-color: #cccccc;
                }
            }
        }

        tr:nth-child(2n){
            th, td{
                background-color: #F8F8F8;
                border-right-color: #eeeeee;

                // &.last-child{
                //     border-right-color: #cccccc;
                // }
            }
            
        }

        .deletable-row{
            .btn-delete{
                display: none;
            }
            &:hover{
                .btn-delete{
                    display: inline-block;
                }
            }
        }

        .editable-row{
            .btn-edit{
                display: none;
            }
            &:hover{
                .btn-edit{
                    display: inline-block;
                }
            }
        }

        .draggable-row{
            .handle{
                display: none;
                &:hover{
                    cursor: grab;
                }
                
            }
            &:hover{
                .handle{
                    display: inline-block;
                }
            }
        }

        tr{
            td {
                transition: .2s all;
                border-right: 1px solid #F8F8F8;
                border-bottom: 1px solid #F8F8F8;

                &.last-child{
                    border-right-color: #cccccc;
                }

                &.unavailable{
                    background-color: #ffdddd !important;
                    border-color: #ffdddd;
                    &:hover{
                        background-color: #e2b3b3 !important;
                        cursor: not-allowed;
                    }
                }

                &:hover{
                    cursor: pointer;
                    background: #EFEFEF;
                }
            }
        }
    }

    .eod-scheduler__now {
        width: 1px;
        background-color: var(--v-secondary-base);
        position: absolute;
        z-index: 3;
        transition: .2s all;
        opacity: 1;

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid var(--v-secondary-base);
            position: absolute;
            left: -4px;
            top: -4px;
        }
    }

    >.eod-scheduler__wrapper {
        position: relative;
        //height: 100%;

        .eod-scheduler__spinner {
            position: absolute;
            background: #eeeeee;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        >.eod-scheduler__table {

            &,
            thead,
            tbody,
            tfoot,
            tr,
            th,
            td {
                width: auto;
                height: auto;
                margin: 0;
                padding: 0;
                border-collapse: inherit;
                border-spacing: 0;
                border-color: inherit;
                vertical-align: inherit;
                text-align: left;
                font-weight: inherit;
                -webkit-border-horizontal-spacing: 0;
                -webkit-border-vertical-spacing: 0;
            }

            min-height: 100%;

            thead {
                th {
                    font-weight: bold;
                }

                >tr:nth-child(2) {
                    th {
                        font-weight: normal;
                        color: #444444;
                    }
                }
            }

            tbody {
                th {
                    z-index: 3;
                }
            }

            .eod-scheduler__row-title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                display: block;
                padding: 5px;
                font-size: .8rem;
            }

            th {
                padding: 2px 5px;
                border-right: 1px solid #eeeeee;
                border-bottom: 1px solid #eeeeee;
                /*min-width: 80px;*/
                background: #ffffff;
                vertical-align: middle;

                &.col-header {
                    z-index: 4;
                    min-width: 180px;
                        max-width: 220px;

                    .content {
                        

                        >span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

        }

    }
}
</style>
<script>
import eodSchedulerItem from './eod-scheduler-item';
import eodWorkflowDialog from './eod-workflow-dialog.vue';
import eodWorkflow from './eod-workflow.vue';
import eodTaskList from './eod-task-list.vue';
import eodDialog from './eod-dialog.vue'
import eodDeleteDialog from './eod-delete-dialog.vue';
import eodProducttypesProductsSelector from './eod-producttypes-products-selector.vue';
import User from './../models/user';
import Project from './../models/project';
import Task from './../models/task';
import eodAvailabilities from './eod-availabilities.vue';
import draggable from "vuedraggable";
import { rrulestr } from 'rrule';

export default {
    components: {
        eodSchedulerItem,
        eodWorkflowDialog,
        eodWorkflow,
        eodTaskList,
        eodDialog,
        eodDeleteDialog,
        draggable,
        eodProducttypesProductsSelector,
        eodAvailabilities
    },
    props: {
        value: Array,
        from: Object,
        until: Object,
        rows: Array,
        getRowData: Function,
        setNewItemData: Function,
        setDraggedItemData: Function,
        view: Object,
        zoom: {
            type: Number,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeRowItem: null,
            activeRowItemEndpoint: null,
            isEditDialogVisible: false,
            isEditDialogLoading: false,
            isUnavailableItemDialogVisible: false,
            isPlanStepDialogVisible: false,
            isPlanStepValidationLoading: false,
            invalidDateCallback: () => {},
            cancelDateCallback: () => {},
            isUnavailableProjectDialogVisible: false,
            isUnavailableProjectDialogAction: () => {},
            isUnavailableProjectDialogCancelAction: () => {},
            createWorkflowData: null,
            createWorkflowNameAppend: null,
            startWorkflowDialog: false,
            dragging: false,
            deleteItemObject: null,
            availabilities: {},
            nowLeft: -2,
            nowTop: 0,
            nowShow: false,
            showWorkflowDialog: false,
            activeWorkflow: null,
            planStepValidation: null,
            headers: {},
            allHeaders: [],
            scheduledItems: [],
            scheduledItemsLoading: {},
            showContextMenu: false,
            showContextMenuNew: false,
            contextMenuNewDate: null,
            contextMenuNewRow: null,
            isDeleteTaskDialogVisible: false,
            deleteItemObjectForUsers: [],
            isAddTaskDialogVisible: false,
            isAddTaskDialogLoading: false,
            isAddTaskButtonVisible: false,
            addTaskFilters: null,
            addTaskSelection: [],
            contextMenuItemIndex: null,
            contextMenuX: 0,
            contextMenuY: 0,
            colWidth: 40,
            maxColWidth: 100,
            minColWidth: 10,
            config: {
                header: {
                    steps: {
                        value: 15,
                        type: 'minutes',
                    },
                    groups: {
                        format: 'ddd DD/MM',
                        children: {
                            format: 'HH:mm'
                        }
                    }
                }
            },
            startHour: 5,
            endHour: 19,
            plannableDays: [1,2,3,4,5],
            headerHeight: 0,
            rowInfo: [],
            titleWidth: 0,
            schedulerWidth: 0,
            resizeTimeout: null,
            loader: {
                show: false,
                style: ''
            },
            nowMarkerInterval: null,
            dragOffset: null,
            zoomTimeout: null,
            isNewWorkflow: false,
            taskFields: ['id', 'name', 'stepType', 'dueRequestDate', 'route{id date vehicle{id name}}', 'estimatedDuration', 'isJobActive', 'statusCode', 'dueBeforeDate', 'dueAfterDate', 'mustBePlannedOnRequestDate', 'responsibleUsers{id}', 'articles{id}', 'parent{id name company{id name} project{id name availabilities{id type allDay duration rrule start end title} locations{name streetAddress locality postalCode}} service{id name} taskType{id name configuration{settings{key value}}}}']
        }
    },
    watch: {
        value() {
            this.calculateItems();
        },
        dense() {
            this.calculateItems();
        },
        zoom(){
            clearTimeout(this.zoomTimeout);
            this.zoomTimeout = setTimeout(() => {
                this.calculateColWidth().then(() => {
                    this.positionNowMarker();
                    this.calculateItems();
                });
            }, 500);
            
        },
    },
    mounted() {
        this.refresh();
        this.nowMarkerInterval = setInterval(() => {
            this.positionNowMarker();
        }, 60000);
    },
    created(){
        window.addEventListener('resize', this.resizeWindow);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeWindow);
        clearInterval(this.nowMarkerInterval);
    },
    computed: {
        itemHeight(){
            return this.dense?40:120;
        }
    },
    methods: {
        showProjectAvailability(project){
            const item = new Project(project);
            this.activeRowItemEndpoint = 'projects';
            this.editRow(item);
        },
        showItemAvailability(item){
            this.activeRowItemEndpoint = this.view.value;
            this.editRow(item);
        },
        async saveAvailabilities(){
            const saveData = await this.activeRowItem.getSaveData();
            this.isEditDialogLoading = true;
            this.$eod.save(this.activeRowItem.constructor.itemName, saveData)
                .then(response => {
                    this.isEditDialogVisible = false;
                    this.activeRowItem = null;
                    this.$emit('reload');
                }).finally(() => {
                    this.isEditDialogLoading = false;
                })
        },
        editRow(row){
            this.activeRowItem = null;
            this.isEditDialogVisible = true;

            setTimeout(() => {
                this.activeRowItem = row;
            }, 200);
        },
        checkValidDate(item, from, until){

            if(!this.view.hasAvailabilities){
                return true;
            }

            if(this.availabilities[item.id]){
                for (let h = 0; h < this.allHeaders.length; h++) {
                    const header = this.allHeaders[h];
                    if(this.$moment(header).isBetween(from, until)){
                        if(!this.availabilities[item.id][header]){
                            return false;
                        }
                    }
                }
            }

            return true;
        },
        calculateAvailabilities(){
            return new Promise(resolve => {
                this.availabilities = {};
                if(this.rows){
                    for (let i = 0; i < this.rows.length; i++) {
                        const row = this.rows[i];
                        if(row.id){
                            this.availabilities[row.id] = {};
                            for (let h = 0; h < this.allHeaders.length; h++) {
                                const header = this.allHeaders[h];
                                if(this.allHeaders[h+1]){
                                    this.availabilities[row.id][header] = null;
                                }
                                
                            }
                            if(row.availabilities){
                                for (let i = 0; i < row.availabilities.length; i++) {
                                    const availability = row.availabilities[i];
                                    if(availability.rrule){
                                        const rruleSet =  rrulestr(availability.rrule);
                                        const dates = rruleSet.between(this.from.toDate(), this.until.toDate(), true);
                                        for (let d = 0; d < dates.length; d++) {
                                            const date = dates[d];
                                            const startDate = this.$moment(date);
                                            const endDate = this.$moment(date).add(availability.duration, 'millisecond');

                                            for (let h = 0; h < this.allHeaders.length; h++) {
                                                const header = this.allHeaders[h];
                                                const mHeader = this.$moment(header);
                                                if(mHeader.isSame(startDate) || mHeader.isBetween(startDate, endDate)){
                                                    if(availability.type == 'unavailable'){
                                                        this.availabilities[row.id][header] = false;
                                                    } else if(this.availabilities[row.id][header] == null) {
                                                        this.availabilities[row.id][header] = true;
                                                    }
                                                } else if (mHeader.isSame(startDate, 'day') && availability.type == 'available'){
                                                    this.availabilities[row.id][header] = false;
                                                }
                                            }
                                        }
                                    } else {
                                        const startDate = this.$moment(availability.start);
                                        const endDate = this.$moment(availability.end);

                                        for (let h = 0; h < this.allHeaders.length; h++) {
                                            const header = this.allHeaders[h];
                                            if(this.$moment(header).isBetween(startDate, endDate)){
                                                this.availabilities[row.id][header] = availability.type == 'available'?true:false;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                }

                resolve();
            });            
        },
        isAvailable(row, child){
            if(!this.view.hasAvailabilities){
                return true;
            }
            if(row.id && this.availabilities[row.id]){
                if(!this.availabilities[row.id][child]){
                    return false;
                }
            }

            return true;
        },
        async showCreateWorkflow(data, task, response) {

            let workflowData = {
                project: task.project,
                company: task.company,
                service: task.service,
            };

            this.createWorkflowNameAppend = null;
            if (response && response.reportedAt) {
                this.createWorkflowNameAppend = ' - ' + this.$moment(response.reportedAt).format('DD-MM-YYYY');
            }

            const preparedData = await this.$eod.prepareCreateWorkflowData(data);

            this.createWorkflowData = {
                ...workflowData,
                ...preparedData
            };

            this.$nextTick(() => {
                this.startWorkflowDialog = true;
            });

        },
        async workflowSaved(newWorkflow, newJob){

            if(this.isNewWorkflow && newJob.dueBeforeDate && newJob.dueAfterDate && newJob.stepType == 'PLAN'){
                await this.$eod.submitWorkflow(newWorkflow.id);
                this.isNewWorkflow = false;
            }

            this.showWorkflowDialog = false;
            this.$emit('workflowUpdated', this.activeWorkflow);
        },
        checkIfRowCanBeMoved(event){
            if(event.relatedContext.index==0){
                return false;
            }
            return event.draggedContext.element.id?true:false;
        },
        tasksSelectionChanged(val){
            this.addTaskSelection = val;
            if(this.addTaskSelection.length > 0){
                this.isAddTaskButtonVisible = true;
            }else{
                this.isAddTaskButtonVisible = false;
            }
        },
        setRowInfo(numberOfOverlappingInRow){
            let yPos = this.headerHeight;
            for (let rowIndex = 0; rowIndex < this.rows.length; rowIndex++) {
                this.rowInfo[rowIndex] = {};

                this.rowInfo[rowIndex].yPos = yPos;

                if(numberOfOverlappingInRow[rowIndex]){
                    this.rowInfo[rowIndex].height = numberOfOverlappingInRow[rowIndex]*this.itemHeight;
                }else {
                    this.rowInfo[rowIndex].height = this.itemHeight;
                }

                yPos += this.rowInfo[rowIndex].height;
            }
        },
        addExistingTaskAction(){
            let project = null;
            if(this.contextMenuNewRow instanceof Project){
                project = this.contextMenuNewRow;
            } else if(this.contextMenuItemIndex != null){
                project = this.scheduledItems[this.contextMenuItemIndex].parent.project;
            }

            if(project){
                this.addTaskFilters = [
                    {
                        "config": {
                            "icon": "mdi-folder-open-outline",
                            "label": "Project",
                            "type": "module",
                            "filter": "projectId",
                        },
                        "result": {
                            ...project,
                            search_title: project.name
                        }
                    }
                ];
            }

            this.addTaskSelection = [];
            this.isAddTaskDialogVisible = true;
        },
        addExistingTask(){
            this.addTaskFilters = null;

            const until = this.$moment(this.contextMenuNewDate);
            until.add(this.getClosest(until.minute(), this.config.header.steps.value), "minutes");
            this.checkAvailability(this.contextMenuNewDate, until.toISOString(), this.contextMenuNewRow)
                .then(isValid => {
                    if(isValid){
                        this.addExistingTaskAction();
                    }
                })
        },
        async checkVariantIsFilled(task){
            // Get the workflow template to get the jobTypeId of the form cell
            const extra = {job: task, workflow:task.parent};
            const workflowTemplate = await this.$eod.get('templates', ['id name content'], {
                    whereInRelated:[{
                        column:'task_id',
                        relation: 'tasks_templates',
                        array: [task.parent.id]
                    }],
                    where:[
                        {
                            column: 'type',
                            operator:'=',
                            value: 'WORKFLOW'
                        }
                    ]
                }).then(response => {
                    if(response.data.data && response.data.data.templates && response.data.data.templates.edges[0]){
                        return response.data.data.templates.edges[0];
                    }

                    return null;
                });
 
                if(!workflowTemplate){
                    return {valid: true}
                }

                extra.workflowTemplate = workflowTemplate;

                let formCell = null;
                for (let i = 0; i < workflowTemplate.content.pages.length; i++) {
                    const page = workflowTemplate.content.pages[i];
                    for (let j = 0; j < page.cells.length; j++) {
                        const cell = page.cells[j];
                        if(cell.typeId == 'form'){
                            extra.formCellIndex = j;
                            extra.formPageIndex = i;
                            formCell = cell;
                            break;
                        }
                    }
                    if(formCell){
                        break;
                    }
                }

                if(!formCell){
                    return {valid: true}
                }

                // If it has a variant defined, no need to check further
                if(formCell.settings.defaultVariant){
                    return {valid: true}
                }

                // Get the template of the form to check if it has variables
                if(formCell.settings.jobTypeId){
                    const jobTypeTemplate = await this.$eod.get('templates', ['id name content'], {
                        whereInRelated:[{
                            column:'jobType_id',
                            relation: 'jobTypes_templates',
                            array: [formCell.settings.jobTypeId]
                        }],
                        where:[
                            {
                                column: 'type',
                                operator:'=',
                                value: 'FORM'
                            }
                        ]
                    }).then(response => {
                        if(response.data.data && response.data.data.templates && response.data.data.templates.edges[0]){
                            return response.data.data.templates.edges[0];
                        }

                        return null;
                    });

                    if(!jobTypeTemplate){
                        return {valid: true}
                    }

                    extra.jobTypeTemplate = jobTypeTemplate;

                    // Only when there are variants defined and not set, let the user see planning step
                    if(jobTypeTemplate.content.variants && jobTypeTemplate.content.variants[0] && !formCell.settings.defaultVariant){
                        return {valid: false, errors:['variant'], extra:extra};
                    }
                }

            return {valid: true}
        },
        async checkStepHasArticleTypes(task){
            const job = await this.$eod.getById('task', task.id, ['id', 'name', 'articleTypes{id name quantity}', 'articles{id name quantity}', 'toolTypes{id name quantity}', 'tools{id name quantity}'])
                .then(response => {
                    if(response.data.data && response.data.data.task){
                        return response.data.data.task;
                    }

                    return null;
                });

            if((job.articleTypes && job.articleTypes.length > 0) || (job.toolTypes && job.toolTypes.length > 0)){
                return {valid: false, errors:['variant'], extra:{job: job}}; 
            }

            return {valid:true};
        },
        async checkRequiredFields(task){

            const returnVal = {valid: true, errors: [], extra: {job: task, workflow: task.parent}};

            const variantValid = await this.checkVariantIsFilled(task);
            if(!variantValid.valid){
                returnVal.valid = false;
                returnVal.errors.push('variant');
                returnVal.extra = {
                    ...returnVal.extra,
                    ...variantValid.extra,
                }
            }

            const articlesValid = await this.checkStepHasArticleTypes(task);
            if(!articlesValid.valid){
                returnVal.valid = false;
                returnVal.errors.push('articles');
                returnVal.extra = {
                    ...returnVal.extra,
                    ...articlesValid.extra,
                }
            }


            return returnVal;
        },
        async addExistingTasks(tasks){            
            if(tasks.length > 0){
                for (let i = 0; i < tasks.length; i++) {
                    const task = tasks[i];
                    try {
                        await this.addTaskToPlanning(task);
                    } catch (error) {
                        console.error(error);
                    }
                }
            }

            this.calculateItems();

            this.isAddTaskDialogVisible = false;
            this.isAddTaskDialogLoading = false;

            this.$emit('itemsAdded', tasks);
        },
        addTaskToPlanning(item){
            this.isAddTaskDialogLoading = true;

            let dueAfterDate = null;
            let dueBeforeDate = null;
            if(this.contextMenuItemIndex != null){
                const existingTask = this.scheduledItems[this.contextMenuItemIndex];
                dueAfterDate = existingTask.dueAfterDate;
                dueBeforeDate = existingTask.dueBeforeDate;
            }else{
                dueAfterDate = this.contextMenuNewDate;
                dueBeforeDate = this.$moment(this.contextMenuNewDate).add(1, 'hours').toISOString();
            }

            let duration = 3600000;
            if(item.estimatedDuration){
                duration = item.estimatedDuration;
                dueBeforeDate = this.$moment(dueAfterDate).add(duration, 'milliseconds').toISOString();
            }

            if(item.stepType == 'PLAN' && this.contextMenuNewRow.id){
                return this.checkIsProjectAvailable(item, dueAfterDate, dueBeforeDate)
                    .then(isValid => {
                        if(isValid){
                            return this.checkIsValidPlanStep(item)
                        }

                        return false;
                    })
                    .then(isValid => {
                        if(isValid){
                            return this.planTask(item, dueAfterDate, dueBeforeDate);
                        }
                    });
                
            } else {
                return this.checkIsProjectAvailable(item, dueAfterDate, dueBeforeDate)
                .then(isValid => {
                        if(isValid){
                            return this.planTask(item, dueAfterDate, dueBeforeDate);
                        }

                        return false;
                    })
                
            }
        },
        planTask(item, dueAfterDate, dueBeforeDate){
            let saveData = {
                id: item.id,
                dueAfterDate: dueAfterDate,
                dueBeforeDate: dueBeforeDate,
            };

            saveData = this.setNewItemData(saveData, this.contextMenuNewRow);

            return this.$eod.save('Task', {
                id: item.parent.id,
                dueAfterDate: dueAfterDate,
                dueBeforeDate: dueBeforeDate,
            }).then(() => {
                return this.$eod.save('Task', saveData, {
                    update: {
                        returnfields: this.taskFields
                    }
                });
            }).then(async (jobData) => {
                await this.$eod.submitWorkflow(item.parent.id);
                return jobData;
            }).then(task => {
                let items = this.value;
                items.push(task);
                this.$emit('input', items);

                return task;
            }).finally(() => {
                this.isPlanStepDialogVisible = false;
                this.isPlanStepValidationLoading = false;
            });
        },
        editWorkflow(existing){
            this.activeWorkflow = existing.parent;
            this.showWorkflowDialog = true;
        },
        startNewWorkflow(existing){

            this.isNewWorkflow = true;

            this.activeWorkflow = {
                dueRequestDate: existing.dueRequestDate,
            };

            if(existing.dueAfterDate && existing.dueBeforeDate) {
                const momentAfter = this.$moment(existing.dueAfterDate);
                const momentBefore = this.$moment(existing.dueBeforeDate);
                this.activeWorkflow.estimatedDuration = momentBefore.diff(momentAfter, 'milliseconds', true);
                this.activeWorkflow.dueBeforeDate = existing.dueBeforeDate;
                this.activeWorkflow.dueAfterDate = existing.dueAfterDate;
            }

            this.activeWorkflow.taskType = existing.parent.taskType;
            this.activeWorkflow.taskTypeId = existing.parent.taskType.id;
            this.activeWorkflow.project = existing.parent.project;
            this.activeWorkflow.company = existing.parent.company;
            this.activeWorkflow.service = existing.parent.service;
            this.activeWorkflow.responsibleUsers = existing.responsibleUsers;

            this.showWorkflowDialog = true;
        },
        addNewTaskAction(){
            if(this.contextMenuItemIndex != null){
                const item = this.scheduledItems[this.contextMenuItemIndex];
                this.activeWorkflow = {
                    project: item.parent.project,
                    company: item.parent.company,
                    service: item.parent.service,
                    taskType: item.parent.taskType,
                    responsibleUsers: item.responsibleUsers
                };
            } else {
                this.activeWorkflow = {
                    dueAfterDate: this.contextMenuNewDate,
                    //dueBeforeDate: this.$moment(this.contextMenuNewDate).add(1, 'hour').toISOString(),
                    dueRequestDate: this.$moment(this.contextMenuNewDate).format('YYYY-MM-DD'),
                };

                if(this.contextMenuNewRow instanceof Project){
                    this.activeWorkflow.project = this.contextMenuNewRow;
                    this.activeWorkflow.company = this.contextMenuNewRow.company;
                } else if(this.contextMenuNewRow.id){
                    this.activeWorkflow.responsibleUsers = [{id: this.contextMenuNewRow.id}];
                }
            }
            this.showWorkflowDialog = true;
        },
        addNewTask(){
            const until = this.$moment(this.contextMenuNewDate);
            until.add(this.getClosest(until.minute(), this.config.header.steps.value), "minutes");
            this.checkAvailability(this.contextMenuNewDate, until.toISOString(), this.contextMenuNewRow)
                .then(isValid => {
                    if(isValid){
                        this.addNewTaskAction();
                    }
                })
        },
        contextMenuNew(e, date, row){
            e.preventDefault();
            this.contextMenuItemIndex = null;
            this.showContextMenu = false;
            this.showContextMenuNew = false;
            this.contextMenuNewDate = date;
            this.contextMenuNewRow = row;
            this.contextMenuX = e.clientX;
            this.contextMenuY = e.clientY;
            this.$nextTick(() => {
                this.showContextMenuNew = true;
            });
        },
        refresh() {
            this.loadHeaders().then(() => {
                return this.setTableDimensions()
            }).then(() => {
                if(this.view.hasAvailabilities){
                    return this.calculateAvailabilities()
                }
                return true;
            }).then(() => {
                return this.calculateColWidth();
            }).then(() => {
                return this.calculateColWidth();
            }).then(() => {
                this.positionNowMarker();
                this.calculateItems();
                this.scrollToNow();
            });
        },
        contextMenu(e, index) {
            e.preventDefault();
            this.showContextMenuNew = false;
            this.showContextMenu = false;
            this.contextMenuItemIndex = index;

            const item = this.scheduledItems[index];
            this.contextMenuNewRow = this.rows[item.rowIndex];

            this.contextMenuX = e.clientX;
            this.contextMenuY = e.clientY;
            this.$nextTick(() => {
                this.showContextMenu = true;
            });
        },
        async deleteContextMenuItem() {
            this.deleteItemObject = this.scheduledItems[this.contextMenuItemIndex];
            const userIds = this.deleteItemObject.responsibleUsers.map(item => item.id);
            this.deleteItemObject.responsibleUsers = await this.$eod.get('resolveUsers', ['id', 'lastName', 'firstName', 'username'], {
                whereIn:[{
                    column: 'id',
                    array: userIds
                }]
            }).then(response => {
                if(response.data.data && response.data.data.resolveUsers){
                    return response.data.data.resolveUsers.edges.map(item => new User(item));
                }
                return [];
            });

            this.deleteItemObjectForUsers = userIds;
            this.isDeleteTaskDialogVisible = true;
        },
        deleteItemAction(){
            this.isDeleteTaskDialogVisible = false;
            this.$emit('deleteItem', this.deleteItemObject, this.deleteItemObjectForUsers);
        },
        resizeWindow() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }

            this.resizeTimeout = setTimeout(() => {
                this.setTableDimensions();
                this.calculateColWidth();
                this.positionNowMarker();
                this.$nextTick(this.calculateItems);
            }, 200);
        },
        calculateColWidth(){
            return new Promise((resolve, reject) => {

                if(this.zoom != null){
                    if(this.zoom > this.minColWidth){
                        this.colWidth = this.zoom;
                    }else{
                        this.colWidth = this.minColWidth;
                    }
                } else {
                    this.colWidth = this.minColWidth;
                }

                if(this.colWidth < 5){
                    this.colWidth = 5;
                }

                resolve();
            });
        },
        resize(newWidth, itemIndex) {
            this.scheduledItems[itemIndex].width = newWidth;
        },
        resized(itemIndex) {
            const item = this.scheduledItems[itemIndex];
            const dueAfterDate = this.allHeaders[item.columnIndex];
            const dueBeforeDate = this.allHeaders[item.columnIndex + item.width];

            let saveData = {
                id: item.id,
                dueAfterDate: dueAfterDate,
                dueBeforeDate: dueBeforeDate,
            };

            this.$eod.save('Task', saveData, {
                update: {
                    returnfields: ['id responsibleUsers{id} dueAfterDate dueBeforeDate']
                }
            })
                .then(response => {
                    if (response.data.data) {
                        this.refreshTask(response.data.data.updateTask, itemIndex);
                    }
                });
        },
        drag(e, itemIndex) {
            const containerX = (document.getElementById('eod-scheduler__body').getBoundingClientRect().left * -1) + e.pageX - this.titleWidth;
            const xIndex = Math.floor(containerX / this.colWidth);

            if (this.dragOffset == null) {
                this.dragOffset = xIndex - this.scheduledItems[itemIndex].columnIndex;
            }

            const newIndex = xIndex - this.dragOffset;

            if (newIndex >= 0) {
                this.scheduledItems[itemIndex].columnIndex = newIndex;
            } else {
                this.scheduledItems[itemIndex].columnIndex = 0;
            }

            const containerY = (document.getElementById('eod-scheduler').getBoundingClientRect().top * -1) + e.pageY - (window.scrollY || window.pageYOffset);
            let yIndex = Math.floor(containerY / this.itemHeight);

            this.scheduledItems[itemIndex].heightIndex = 0;
            
            for (let i = 0; i < this.rowInfo.length; i++) {
                const rowInfo = this.rowInfo[i];
                if(rowInfo.yPos <= containerY && (rowInfo.yPos + rowInfo.height) > containerY){
                    yIndex = i;
                }
            }
            if (yIndex >= 0) {
                this.scheduledItems[itemIndex].rowIndex = yIndex;
            } else {
                this.scheduledItems[itemIndex].rowIndex = 0;
            }
        },
        dragEnd(e, itemIndex, itemPos) {

            const options = {};

                if(e){
                    options.mouseEvent = {
                        which: e.which,
                        key: e.key,
                        code: e.code,
                        ctrlKey: e.ctrlKey,
                        shiftKey: e.shiftKey,
                    }
                }

            this.dragOffset = null;

            const item = this.scheduledItems[itemIndex];

            if (itemPos.originalColumnIndex != item.columnIndex || itemPos.originalRowIndex != item.rowIndex) {
                this.scheduledItemsLoading[item.id] = true;
                this.$forceUpdate();

                const dueAfterDate = this.allHeaders[item.columnIndex];
                const dueBeforeDate = this.allHeaders[item.columnIndex + item.width];
                const from = this.rows[itemPos.originalRowIndex];
                const to = this.rows[item.rowIndex];

                if(!to.id){
                    this.dragEndAction(item, itemIndex, dueAfterDate, dueBeforeDate, from ,to, options);
                } else {
                    this.checkAvailability(dueAfterDate, dueBeforeDate, to)
                    .then(isValid => {
                        if(isValid){
                            if(this.view.value != 'projects'){
                                return this.checkIsProjectAvailable(item, dueAfterDate, dueBeforeDate);
                            } else {
                                return true;
                            }
                            
                        }
                        return false;
                    })
                    .then(isValid => {
                        if(isValid){
                            return this.checkIsValidPlanStep(item);
                        } else {
                            this.scheduledItems[itemIndex].columnIndex = itemPos.originalColumnIndex;
                            this.scheduledItems[itemIndex].rowIndex = itemPos.originalRowIndex;
                            this.scheduledItemsLoading[item.id] = false;
                            this.$forceUpdate();
                        }
                        return false;
                    })
                    .then(isValid => {
                        if(isValid){
                            this.dragEndAction(item, itemIndex, dueAfterDate, dueBeforeDate, from ,to, options);
                        } else {
                            this.scheduledItems[itemIndex].columnIndex = itemPos.originalColumnIndex;
                            this.scheduledItems[itemIndex].rowIndex = itemPos.originalRowIndex;
                            this.scheduledItemsLoading[item.id] = false;
                            this.$forceUpdate();
                        }
                    });
                }

                          
            } else {
                this.activeWorkflow = item.parent;
                this.showWorkflowDialog = true;
            }
        },
        checkIsProjectAvailable(item, from, until){
            return new Promise(async resolve => {
                if(item && item.parent && item.parent.project){
                    if(!item.parent.project.hasOwnProperty('availabilities')){
                        item.parent.project = await this.$eod.getById('project', item.parent.project.id, ['id','name','availabilities{id type allDay duration rrule start end title}'])
                        .then(response => {
                            if(response.data.data && response.data.data.project){
                                return response.data.data.project;
                            }

                            return item.parent.project;
                        })
                    }
                    const project = new Project(item.parent.project);
                    if(!project.isAvailable(from, until)){
                        this.isUnavailableProjectDialogAction = () => {
                            this.isUnavailableProjectDialogVisible = false;
                            resolve(true);
                        }
                        this.isUnavailableProjectDialogCancelAction = () => {
                            this.isUnavailableProjectDialogVisible = false;
                            resolve(false);
                        }
                        this.isUnavailableProjectDialogVisible = true;
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(true);
                }
            });
        },
        checkIsValidPlanStep(item){
            return new Promise(async resolve => {
                const isValidPlanStep = await this.checkRequiredFields(item);

                if(!isValidPlanStep.valid){
                    this.planStepValidationAction = async () => {
                        if(this.$refs.planStepValidationForm){
                            const valid = this.$refs.planStepValidationForm.validate();

                            if(valid){
                                this.isPlanStepValidationLoading = true;
                                try {
                                    if(this.planStepValidation.errors.includes('variant')){
                                        await this.$eod.save('Template', {
                                            id: this.planStepValidation.extra.workflowTemplate.id,
                                            content: this.planStepValidation.extra.workflowTemplate.content
                                        });
                                    }
                                    if(this.planStepValidation.errors.includes('articles')){
                                        const job = new Task(this.planStepValidation.extra.job);
                                        const data = await job.getSaveData();
                                        await this.$eod.save('Task', data);
                                    }

                                    this.isPlanStepDialogVisible = false;
                                    this.planStepValidation = null;

                                    return resolve(true);
                                } catch (error) {
                                    this.isPlanStepValidationLoading = false;
                                    this.isPlanStepDialogVisible = false;
                                    this.planStepValidation = null;
                                    return resolve(false);
                                }
                            }
                        }                            
                    };
                    this.planStepValidationCancelAction = () => {
                        this.isPlanStepDialogVisible = false;
                        this.planStepValidation = null;
                        return resolve(false);
                    };

                    this.planStepValidation = isValidPlanStep;
                    this.isPlanStepDialogVisible = true;
                } else {
                    resolve(true);
                }
            });
            
        },
        checkAvailability(dueAfterDate, dueBeforeDate, to){
            return new Promise(resolve => {
                if(this.view.hasAvailabilities && !this.checkValidDate(to, dueAfterDate, dueBeforeDate)){
                    this.invalidDateCallback = () => {
                        this.isUnavailableItemDialogVisible = false;
                        return resolve(true);
                    };
                    this.cancelDateCallback = () => {
                        this.isUnavailableItemDialogVisible = false;
                        return resolve(false);
                    };
                    this.isUnavailableItemDialogVisible = true;
                } else {
                    return resolve(true);
                }
            });
            
        },
        dragEndAction(item, itemIndex, dueAfterDate, dueBeforeDate, from, to, options){
                let saveData = {
                    id: item.id,
                    dueAfterDate: dueAfterDate,
                    dueBeforeDate: dueBeforeDate,
                };

                saveData = this.setDraggedItemData(saveData, item, from, to, options);

                // Unplan workfow when no reponsible users are assigned
                if(item.stepType != 'PLAN' && saveData.responsibleUsers && saveData.responsibleUsers.length == 0){
                    return this.$eod.workflowReturnToPlan(item.parent.id)
                            .then(response => {
                                saveData.id = response.cell.jobId;
                                return this.$eod.save('Task', saveData, {
                                    update: {
                                        returnfields: this.taskFields
                                    }
                                }).then(response => {
                                    if(response.data.data){
                                        return this.replaceTask(item.id, response.data.data.updateTask);
                                    }
                                })
                            }).finally(() => {
                                this.scheduledItemsLoading[item.id] = false;
                            });
                }

                // Save new dates and responsibleUsers
                this.$eod.save('Task', saveData, {
                    update: {
                        returnfields: this.taskFields
                    }
                })
                    .then(response => {
                        if (response.data.data) {
                            const task = response.data.data.updateTask;

                            // If dragged from plan to user, go to form job
                            if(task.stepType == 'PLAN' && saveData.responsibleUsers.length > 0 &&  !(this.$eod.getOrganizationSetting('planning.optimizeWaypoints') && this.$eod.getOrganizationSetting('planning.optimizeWaypoints').value == true)){
                                return this.$eod.submitWorkflow(task.parent.id).then(newJob => {
                                    if(newJob){
                                        return this.$eod.getById('task', newJob.id, this.taskFields).then(response => {
                                            if(response.data.data){
                                                return this.replaceTask(item.id, response.data.data.task);
                                            }
                                        });
                                    }
                                });
                            }

                            return this.refreshTask(task, itemIndex);
                        }
                    }).finally(() => {
                        this.scheduledItemsLoading[item.id] = false;
                    });
        },
        refreshTask(updateItem, itemIndex) {
            let items = this.value;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.id == updateItem.id) {
                    items[i] = {
                        ...item,
                        ...updateItem
                    }
                }
            }

            this.$emit('input', items);

            this.calculateItems().then(() => {
                if(this.$refs[itemIndex+'_'+updateItem.id] && this.$refs[itemIndex+'_'+updateItem.id][0]){
                    this.$refs[itemIndex+'_'+updateItem.id][0].refresh();
                }
            });
        },
        replaceTask(originalId, replaceItem) {

            let items = this.value;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.id == originalId) {
                    items[i] = replaceItem;
                }
            }

            this.$emit('input', items);

            this.calculateItems();
        },
        setTableDimensions() {
            return new Promise((resolve, reject) => {
                const scheduler = window.document.getElementById('eod-scheduler');
                if(scheduler){
                    this.schedulerWidth = scheduler.offsetWidth;
                }

                const header = window.document.getElementById('eod-scheduler__header');
                if (header) {
                    this.headerHeight = header.offsetHeight;
                }

                const body = window.document.getElementById('eod-scheduler__body');
                if (body) {
                    this.titleWidth = body.childNodes[0].childNodes[0].offsetWidth;
                    //this.rowHeight = body.childNodes[0].offsetHeight;
                }

                const colNum = this.allHeaders.length;
                this.minColWidth = (this.schedulerWidth - this.titleWidth - 2)/colNum;

                resolve();
            });

        },
        getNextAvailableIndex(taskIndexes, indexItems) {
            let indexes = [];
            for (const itemId in taskIndexes) {
                const element = taskIndexes[itemId];
                for (let i = 0; i < indexItems.length; i++) {
                    const indexItemId = indexItems[i];
                    if (indexItemId == itemId) {
                        indexes.push(element.index);
                    }
                }

            }

            for (let i = 0; i < indexItems.length; i++) {
                if (!indexes.includes(i)) {
                    return i;
                }
            }
        },
        getIndexHeight(itemsPerCell) {
            let indexesPerRow = {};
            for (const rowIndex in itemsPerCell) {
                const rowColumns = itemsPerCell[rowIndex];
                let taskIndexes = {};

                for (const columnIndex in rowColumns) {
                    const itemIds = rowColumns[columnIndex];
                    let maxHeight = itemIds.length;

                    // Check if any task of the cell already has an index and height
                    for (let i = 0; i < itemIds.length; i++) {
                        const itemId = itemIds[i];

                        if (taskIndexes[itemId]) {
                            if (maxHeight < taskIndexes[itemId].height) {
                                maxHeight = taskIndexes[itemId].height;
                            }
                            taskIndexes[itemId].height = maxHeight;
                        } else {
                            taskIndexes[itemId] = {
                                index: this.getNextAvailableIndex(taskIndexes, itemIds),
                                height: maxHeight > itemIds.length ? maxHeight : itemIds.length
                            };
                        }
                    }
                }

                indexesPerRow[rowIndex] = taskIndexes;
            }

            return indexesPerRow;
        },
        calculateItems() {
            return new Promise(resolve => {
                let items = [];
                const numberOfOverlappingInRow = [];

                if (this.value) {

                    let tasks = this.value;

                    // calculate amount of events per cell
                    let itemsPerCell = {};
                    for (let i = 0; i < tasks.length; i++) {
                        const task = tasks[i];

                        const columnData = this.getColumnData(task);
                        const rowData = this.getRowData(task);

                        tasks[i].columnData = columnData;
                        tasks[i].rowData = rowData;

                        for (let j = 0; j < rowData.length; j++) {
                            const rowIndex = rowData[j];

                            if (!itemsPerCell[rowIndex]) {
                                itemsPerCell[rowIndex] = {};
                            }

                            const end = columnData.start + columnData.width;
                            for (let columnIndex = columnData.start; columnIndex < end; columnIndex++) {
                                if (!itemsPerCell[rowIndex][columnIndex]) {
                                    itemsPerCell[rowIndex][columnIndex] = [task.id];
                                } else {
                                    itemsPerCell[rowIndex][columnIndex].push(task.id);
                                }
                            }
                        }
                    }

                    let indexHeightPerCell = this.getIndexHeight(itemsPerCell);

                    for (let i = 0; i < tasks.length; i++) {
                        const task = tasks[i];
                        for (let j = 0; j < task.rowData.length; j++) {
                            const rowIndex = task.rowData[j];
                            const itemsData = indexHeightPerCell[rowIndex];

                            if (itemsData[task.id]) {
                                items.push({
                                    ...task,
                                    rowIndex: rowIndex,
                                    width: task.columnData.width,
                                    columnIndex: task.columnData.start,
                                    height: this.itemHeight,
                                    heightIndex: itemsData[task.id].index
                                });

                                if(!numberOfOverlappingInRow[rowIndex]){
                                    numberOfOverlappingInRow[rowIndex] = itemsData[task.id].index+1;
                                }else if(numberOfOverlappingInRow[rowIndex] < itemsData[task.id].index+1){
                                    numberOfOverlappingInRow[rowIndex] = itemsData[task.id].index+1;
                                }
                            }
                        }
                    }
                }

                this.setRowInfo(numberOfOverlappingInRow);

                this.$nextTick(() => {
                    this.scheduledItems = items;

                    resolve()
                });
            })
            
        },
        positionNowMarker() {
            // Set now marker
            const now = this.$moment();      
            const offset = this.getClosest(now.minute(), this.config.header.steps.value);      
            const header = now.add(offset, "minutes").startOf('minute').toISOString();
            const headerIndex = this.allHeaders.indexOf(header);

            if(headerIndex > -1){
                this.nowTop = this.headerHeight;
                const nowLeft = this.titleWidth + headerIndex * this.colWidth - (offset * (this.colWidth/this.config.header.steps.value));
                this.nowLeft = nowLeft;
                this.nowShow = true;
            } else {
                this.nowShow = false;
            }
        },
        getClosest(number, round) {
            let remainder = number % round;
            if (remainder > 7.5) {
                return round - remainder;
            }

            return -1 * remainder;
        },
        getColumnData(task) {
            let start = null;
            let width = 0;

            const from = this.$moment(task.dueAfterDate);
            // Round to nearest 15min
            from.add(this.getClosest(from.minute(), this.config.header.steps.value), "minutes");

            const until = this.$moment(task.dueBeforeDate);
            // Round to nearest 15min
            until.add(this.getClosest(until.minute(), this.config.header.steps.value), "minutes");

            for (let i = 0; i < this.allHeaders.length; i++) {
                const headerDate = this.$moment(this.allHeaders[i]);

                if (from.diff(headerDate, 'minutes') == 0) {
                    start = i;
                }

                if (start != null && until > headerDate) {
                    width++;
                }
            }

            return {
                start: start,
                width: width
            }
        },
        getSubHeaders(header) {
            let subHeaders = [];

            const columnCount = Object.keys(header.children).length;
            let dateIndex = 0;

            for (const date in header.children) {
                const childDate = header.children[date];
                const m = this.$moment(childDate);

                // Small rows -> column every 2 hours
                if (this.colWidth <= 12) {
                    if (dateIndex%(columnCount/4) == 0) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: (columnCount/4),
                            width: this.colWidth * (columnCount/4)
                        });
                    }
                } else if (this.colWidth <= 24) {
                    if (m.minutes() == 0) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: 4,
                            width: this.colWidth * 4
                        });
                    }
                } else {
                    if ([0, 30].includes(m.minutes())) {
                        subHeaders.push({
                            date: m.format(this.config.header.groups.children.format),
                            colspan: 2,
                            width: this.colWidth * 2
                        });
                    }
                }

                dateIndex++;
            }

            return subHeaders;
        },
        scrollToNow() {
            if (window.document.getElementById('eod-scheduler') && this.nowShow) {
                const wrapperWidth = window.document.getElementById('eod-scheduler').offsetWidth;
                const nowLeft = this.titleWidth + ((this.$moment().diff(this.from, 'minutes') / this.config.header.steps.value) * this.colWidth);
                const scrollLeft = nowLeft - (wrapperWidth / 2);
                document.getElementById('eod-scheduler').scrollLeft = scrollLeft;
            }
        },
        loadHeaders() {
            let headers = {};
            this.allHeaders = [];

            return new Promise((resolve, reject) => {
                for (var m = this.$moment(this.from).startOf('days'); m.diff(this.until, this.config.header.steps.type) < 0; m.add(this.config.header.steps.value, this.config.header.steps.type)) {

                    if(!this.plannableDays.includes(m.isoWeekday()) || m.hours() < this.startHour || m.hours() > this.endHour-1){
                        continue;
                    }

                    let header = headers;
                    let group = this.config.header.groups;

                    while (group && group.format) {
                        const name = m.format(group.format);
                        if (!header[name]) {
                            if (group.children) {
                                header[name] = { children: {} };
                            } else {
                                header[name] = m.toISOString();
                                this.allHeaders.push(m.startOf('minute').toISOString());
                            }
                        }

                        if (group.children) {
                            header = header[name].children;

                        }

                        group = group.children;

                    }
                }

                this.headers = headers;

                resolve();
            });


        }
    }
}
</script>
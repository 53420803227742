<template>
    <v-autocomplete :search-input.sync="search" :dense="dense" :disabled="disabled" :placeholder="placeholder"
        :clearable="clearable" :loading="isUpdating" :items="searchItems" :label="label" :item-text="getItemText"
        item-value="id" :value="value" @input="val => $emit('input', val)" @change="val => $emit('change', val)"
        :return-object="returnObject" outlined auto-select-first no-filter hide-details>
        <template v-slot:item="{ item }">
            <v-list-item-avatar color="indigo" class="headline font-weight-light white--text">
                {{ item.getInitials() }}
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title v-text="item.getFullName()"></v-list-item-title>
                <v-list-item-subtitle v-if="item.getRole()">{{ $t(item.getRole().name) }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>
<script>
import User from './../models/user';

export default {
    name: 'eod-user-selector',
    props: {
        value: String | Object,
        label: String,
        placeholder: String,
        disabled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        roleNames: {
            type: Array,
        },
        fields:{
            type: Array,
            default: () => (['id', 'firstName', 'lastName', 'username', 'email', 'assignedRealmRoles{id name}'])
        }
    },
    data() {
        return {
            search: '',
            isUpdating: false,
            searchResults: [],
            searchItem: null,
            timeout: null,
            initialized: false,
            items: [],
            config: {
                import: {
                    endpoint: 'Users',
                    objectName: 'User'
                }
            }
        }
    },
    mounted() {
        if (this.value) {
            this.loadUsers();
        } else {
            this.initialized = true;
        }
    },
    watch: {
        value(newVal, oldVal){
            if(oldVal && (newVal == oldVal || (newVal.id && newVal.id == oldVal.id))){
                return;
            }
            this.loadUsers();
        },
        search(val) {
            // Items have already been requested
            if (this.timeout) {
                clearInterval(this.timeout);
            }

            this.timeout = setTimeout(() => {
                if (this.isUpdating || !this.initialized) return;

                if (!val || val == '' || val.length < 2) {
                    this.searchResults = [];
                    return;
                };

                this.isUpdating = true;

                let variables = {
                    search: val,
                    //firstName: val,
                    //lastName: val,
                }

                if(this.roleNames){
                    variables.roleNames = this.roleNames;
                }

                // Filter users
                this.$eod.get('searchUsers', this.fields, variables)
                    .then(result => {
                        this.searchResults = [];
                        let count = result.data.data.searchUsers.edges.length;
                        for (let i = 0; i < count; i++) {
                            this.searchResults.push(new User(result.data.data.searchUsers.edges[i]));
                        }

                        this.isUpdating = false;
                        this.$forceUpdate();
                    });

                this.timeout = null;
            }, 500);

        },
    },
    computed: {
        searchItems() {
            let arr_items = Object.assign([], this.searchResults);

            for (let i = 0; i < arr_items.length; i++) {
                if (this.roles && !this.roles.includes(arr_items[i].getRole().name)) {
                    arr_items.splice(i, 1);
                }
            }

            return arr_items;
        }
    },
    methods: {
        getItemText(item) {
            if (item.getFullName) {
                return item.getFullName();
            }

            return item;
        },
        loadUsers() {
            if (this.value) {
                this.searchResults = [];

                let id = this.value;
                if (this.value.id) {
                    id = this.value.id;
                }

                this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username', 'email'], {
                    whereIn: {
                        column: 'id',
                        array: [id]
                    }
                })
                    .then(result => {
                        let count = result.data.data.resolveUsers.edges.length;
                        for (let i = 0; i < count; i++) {
                            this.searchResults.push(new User(result.data.data.resolveUsers.edges[i]));
                        }

                        this.$forceUpdate();
                        this.$nextTick(() => {
                            this.initialized = true;
                        });


                    });
            }

        },
    }
}
</script>
import { eod, helper } from '../..';

export default class TaskType {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    get defaultProjectId() {
        if (this.defaultProject) {
            return this.defaultProject.id;
        }
        return null;
    }
    set defaultProjectId(value) {
        this.defaultProject = {
            id: value
        };
    }
    get defaultServiceId() {
        if (this.defaultService) {
            return this.defaultService.id;
        }
        return null;
    }
    set defaultServiceId(value) {
        this.defaultService = {
            id: value
        };
    }
    get defaultCompanyId() {
        if (this.defaultCompany) {
            return this.defaultCompany.id;
        }
        return null;
    }
    set defaultCompanyId(value) {
        this.defaultCompany = {
            id: value
        };
    }
    loadProducts(){
        return eod.getById('taskType', this.id, ['defaultProducts{id name description quantity stockLocation{id name}}']).then(response => {
            this.products = response.data.data.taskType.defaultProducts;
            return this.products;
        });
    }
    loadProductTypes() {
        return eod.getById('taskType', this.id, ['defaultProductTypes{id name description quantity stockLocation{id name}}']).then(response => {
            this.productTypes = response.data.data.taskType.defaultProductTypes;
            return this.productTypes;
        })
    }
    getTemplatesMeasurements() {
        let measurements = [];

        if (this.templates) {
            for (let templateIndex = 0; templateIndex < this.templates.length; templateIndex++) {
                const template = this.templates[templateIndex];
                if(template.type == 'FORM'){
                    let templateMeasurements = helper.getTemplateMeasurements(template);
                    for (let measurementIndex = 0; measurementIndex < templateMeasurements.length; measurementIndex++) {
                        const measurement = templateMeasurements[measurementIndex];
                        measurements.push(measurement);
                    }
                }
            }
        }

        return measurements;

    }
    async getSaveData() {
        let data = Object.assign({}, this)

        if (data.estimatedDuration){
            data.estimatedDuration = parseInt(data.estimatedDuration);
        }
        

        data.defaultProjectId = this.defaultProjectId;
        data.defaultServiceId = this.defaultServiceId;
        data.defaultCompanyId = this.defaultCompanyId;

        delete data.defaultService;
        delete data.defaultProject;
        delete data.defaultCompany;

        data.measurementTypes = this.getTemplatesMeasurements();

        data.templates = [];
        if (this.templates) {
            for (let i = 0; i < this.templates.length; i++) {
                let template = this.templates[i];
                if (template.name == '') {
                    template.name = data.name;
                }

                if (!template.description || template.description == '') {
                    template.description = data.name;
                }

                let id = await eod.save('Template', template).then(result => {
                    if (result.data.data.createTemplate) {
                        return result.data.data.createTemplate.id;
                    } else {
                        return result.data.data.updateTemplate.id;
                    }
                });

                data.templates.push({
                    id: id,
                    default: template.default ? template.default : false
                });
            }
        }

        const manyToManyRelations = ['skills', 'articles','articleTypes'];
        for (let i = 0; i < manyToManyRelations.length; i++) {
            const relation = manyToManyRelations[i];
            if (data.hasOwnProperty(relation)) {
                if (data[relation]) {
                    const arr = [];
                    for (let j = 0; j < data[relation].length; j++) {
                        const related = data[relation][j];
                        if(related.id){
                            const relationData = {id:related.id};
                            if(!isNaN(related.quantity)){
                                relationData.quantity = parseFloat(related.quantity);
                            }
                            arr.push(relationData)
                        }
                    }
                    data[relation] = arr;
                }
            }
        }

        return data;
    }
}
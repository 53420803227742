<template>
    <v-form ref="form" v-if="item">
        <v-card-title>{{ item.name }}<v-btn v-if="item.id" target="_blank" :to="{name:'dashboard.product', params:{id:item.id}}" class="ml-4" depressed rounded color="gray" small><v-icon left small>mdi-view-dashboard-outline</v-icon>Dashboard</v-btn>
            <v-spacer></v-spacer>
            <v-alert dense type="warning" v-if="isSaveAlertVisible" class="mr-3 mb-0 py-1">Er zijn onopgeslagen wijzigingen</v-alert>
            <v-btn @click="saveItem" :loading="saving" v-if="isSaveAlertVisible" color="secondary" depressed rounded><v-icon left>mdi-content-save</v-icon>Opslaan</v-btn>
        </v-card-title>
        <template v-if="!['DEVICE_FOLDER'].includes(item.class)">
        <v-card-text>
            <v-tabs v-model="selectedtab">
                <v-tab>Algemeen</v-tab>
                <v-tab>Media</v-tab>
                <v-tab>Locatie</v-tab>
                <v-tab>Meetwaardes</v-tab>
            </v-tabs>
            </v-card-text>
            <v-card-text>
                <v-alert v-if="errors" color="error" text>
                <ul>
                    <li v-for="(error, index) in errors" :key="'error_'+index">{{ error }}</li>
                </ul>
                </v-alert>
        <v-tabs-items :value="selectedtab">
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
            <v-row class="mb-4">
                <v-col>
                    <v-row>
                    <v-col cols="12" :xl="6">
                        <v-text-field
                            v-model="item.name"
                            :rules="[val => $validator.required(val, 'Naam')]"
                            label="Naam*"
                            required
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <v-text-field
                            v-model="item.description"
                            label="Omschrijving"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="6">
                        <eod-object-autocomplete :default-filter="{whereIn:[{column:'class',array:[item.class]}]}" dense hide-details :rules="[val => $validator.required(val, 'Type')]" label="Type*" endpoint="productTypes" v-model="item.productType.id" required>
                            <template v-slot:append-item>
                                <v-divider></v-divider>
                                <v-list-item dense @click="isCreateTypeDialogVisible = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Type toevoegen</v-list-item-title>
                                        <v-list-item-subtitle>Voeg een type toe wanneer u deze
                                            niet in de lijst vindt.</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </eod-object-autocomplete>
                        <eod-dialog title="Type toevoegen" icon="mdi-plus" iconColor="primary" v-if="isCreateTypeDialogVisible" v-model="isCreateTypeDialogVisible" @okAction="createProductType" okText="Toevoegen">
                            <v-form ref="productTypeForm">
                                <v-alert type="error" v-if="typeFormErrors[0]" dense class="mt-2">
                                    <ul>
                                        <li v-for="(error, index) in typeFormErrors" :key="index">{{ error }}</li>
                                    </ul>
                                </v-alert>
                                <eod-product-type-detail class="mt-4" v-model="newProductType"></eod-product-type-detail>
                            </v-form>
                        </eod-dialog>
                    </v-col>
                    <v-col cols="12" :xl="6">
                        <eod-objects-treeview-input :classes="['COMPANY','PROJECT',item.class+'_FOLDER', item.class]" :rules="[val => $validator.required(val, 'Bovenliggend item')]" required v-model="item.treeParentId" @input="parentChanged" :exclude="item.id?[item.id]:null" label="Bovenliggend item*"></eod-objects-treeview-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="6">
                        <eod-object-autocomplete v-if="item.company" :default-filter="{where:[{column:'companyId', value:item.company.id}]}" dense hide-details :rules="[val => $validator.required(val, 'Dienst')]" label="Dienst*" endpoint="services" v-model="item.serviceId" required>
                            <template v-slot:append-item>
                                <v-divider></v-divider>
                                <v-list-item dense @click="isCreateTypeDialogVisible = true">
                                    <v-list-item-icon>
                                        <v-icon>mdi-plus</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Type toevoegen</v-list-item-title>
                                        <v-list-item-subtitle>Voeg een type toe wanneer u deze
                                            niet in de lijst vindt.</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </eod-object-autocomplete>
                    </v-col>
                </v-row>
                <v-row v-if="item.productType && item.productType.id">
                    <v-col>                        
                        <eod-product-type-preview :value="item.productType.id"></eod-product-type-preview>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="installedDateMenu"
                            v-model="datepickers.installedDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.installedDateFormatted"
                                label="Installatiedatum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.installedDateFormatted" no-title scrollable @input="datepickers.installedDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="warrantyEndDateMenu"
                            v-model="datepickers.warrantyEndDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.warrantyEndDateFormatted"
                                label="Vervaldatum garantie"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.warrantyEndDateFormatted" no-title scrollable @input="datepickers.warrantyEndDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <v-menu
                            ref="lastMaintenanceDateMenu"
                            v-model="datepickers.lastMaintenanceDate"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="item.lastMaintenanceDateFormatted"
                                label="Laatste onderhoud"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                hide-details
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="item.lastMaintenanceDateFormatted" no-title scrollable @input="datepickers.lastMaintenanceDate = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>                        
                        <eod-text-field label="Hardware ID" v-model="item.hardwareId"></eod-text-field>
                    </v-col>
                </v-row>
                 <v-row>
                    <v-col cols="12" :xl="4">
                        <v-text-field
                            v-model="item.code"
                            label="Code"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <eod-list-item-autocomplete dense outlined v-if="$eod.getOrganizationSetting('products.statusCode.listId')" v-model="item.statusCode.id" label="Status code" :listId="$eod.getOrganizationSetting('products.statusCode.listId').value"></eod-list-item-autocomplete>
                    </v-col>
                    <v-col cols="12" :xl="4">
                        <eod-color-picker label="Kleur" v-model="item.color" dot-size="15" hide-mode-switch mode="hexa"></eod-color-picker>
                    </v-col>
                </v-row>
                </v-col>
                <v-col cols="12" :lg="'auto'">
                    <v-img width="200" max-height="280" :src="qr_code" aspect-ratio="1" contain />
                </v-col>
            </v-row>
            
        </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
        <eod-storage-library v-if="item.id" :tags="{ productId: item.id }" :searchFilters="[{ field: 'productId', name: item.name, value: item.id }]"></eod-storage-library>
        <v-alert v-else type="info" text dense>Gelieve asset eerst te bewaren om media te kunnen toevoegen</v-alert>
      </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
        <eod-autocomplete :items="[{value:null, text:'Overnemen van project'}, {value: {}, text: 'Verschillend van project'}]" :value="item.location == null?null:{}" @input="val => {item.location = val;$forceUpdate();}" class="mb-4"></eod-autocomplete>
        <v-row v-if="item.location">
            <v-col cols="12" :md="8">
                <v-card>
              <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <eod-text-field
                            v-model="item.location.streetAddress"
                            label="Straat + nummer"></eod-text-field>
                    </v-col>
                    <v-col cols="4">
                        <eod-text-field v-model="item.location.postalCode"
                            label="Postcode"></eod-text-field>
                    </v-col>
                    <v-col cols="8">
                        <eod-text-field  v-model="item.location.locality"
                            label="Stad/Gemeente"></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field v-model="item.location.region"
                            label="Regio"></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field v-model="item.location.country" 
                            label="Land"></eod-text-field>
                    </v-col>
                </v-row>
              </v-card-text>
          </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="overflow-hidden">
                    <eod-location-form v-model="item" @update="updateLocation"></eod-location-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="!item.location && item.project && item.project.locations && item.project.locations[0]">
            <v-col cols="12" :md="8">
                <v-card>
              <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <eod-text-field disabled
                            :value="item.project.locations[0].streetAddress"
                            label="Straat + nummer"></eod-text-field>
                    </v-col>
                    <v-col cols="4">
                        <eod-text-field disabled :value="item.project.locations[0].postalCode"
                            label="Postcode"></eod-text-field>
                    </v-col>
                    <v-col cols="8">
                        <eod-text-field disabled  :value="item.project.locations[0].locality"
                            label="Stad/Gemeente"></eod-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-text-field disabled :value="item.project.locations[0].region"
                            label="Regio"></eod-text-field>
                    </v-col>
                    <v-col>
                        <eod-text-field disabled :value="item.project.locations[0].country" 
                            label="Land"></eod-text-field>
                    </v-col>
                </v-row>
              </v-card-text>
          </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="overflow-hidden">
                    <eod-location-form disabled :value="item.project.locations[0]"></eod-location-form>
                </v-card>
            </v-col>
        </v-row>
          
      </v-tab-item>
      <v-tab-item transition="expand-transition" reverse-transition="expand-transition">
      <eod-measurement-type-indicative show-hardware-id v-model="item.measurementTypes"></eod-measurement-type-indicative>
    </v-tab-item>
        </v-tabs-items>
    </v-card-text>
</template>
    <v-card-text v-else-if="['DEVICE_FOLDER'].includes(item.class)">
        <v-text-field label="Naam" dense outlined v-model="item.name"></v-text-field>
    </v-card-text>
        <v-snackbar right top v-model="isSavedSnackbarVisible" rounded="pill" timeout="5000" color="success"><v-icon left>mdi-content-save</v-icon>Item succesvol bewaard!</v-snackbar>
</v-form>
</template>
<script>
import QRCode from 'qrcode'
import eodMeasurementTypeIndicative from './eod-measurementType-indicative.vue';
import eodColorPicker from './eod-color-picker.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodStorageLibrary from './eod-storage-library.vue';
import eodLocationForm from './eod-location-form.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodProductTypePreview from './eod-product-type-preview.vue';
import eodProductTypeDetail from './eod-product-type-detail.vue';
import Product from './../models/product.js';

export default{
    name: 'eod-product-detail',
    components:{
        eodMeasurementTypeIndicative,
        eodColorPicker,
        eodListItemAutocomplete,
        eodObjectAutocomplete,
        eodStorageLibrary,
        eodLocationForm,
        eodObjectsTreeviewInput,
        eodProductTypePreview,
        eodProductTypeDetail,
    },
    props:{
        value: String|Object
    },
    data: () => ({
        item: null,
        loading: false,
        saving: false,
        isSaveAlertVisible: false,
        isSavedSnackbarVisible: false,
        errors: null,
        selectedtab: 'general',
        datepickers: {
            warrantyEndDate: false,
            installedDate: false,
            lastMaintenanceDate: false,
        },
        qr_code: null,
        isCreateTypeDialogVisible: false,
        newProductType: {},
        typeFormErrors: [],
    }),
    created(){
        if(this.value){
            if(typeof this.value == 'string'){
                this.loading = true;
                
                this.$eod.getById('product', this.value, ['id', 'company{id}', 'service{id}', 'name', 'treeParentId', 'hardwareId', 'project{id name locations{name description streetAddress postalCode locality region country lat lon}}', 'description', 'color', 'productType{id}', 'code', 'class', 'warrantyEndDate', 'installedDate', 'lastMaintenanceDate', 'statusCode{id}', 'measurementTypes{id name indicativeValue hardwareId unit{id name}}', 'lat','lon', 'location{name description streetAddress postalCode locality region country}'])
                    .then(response => {
                        if(response && response.data.data && response.data.data.product){
                            this.item = new Product(response.data.data.product);
                        }else{
                            this.item = null;
                        }

                        if(!this.item.statusCode){
                            this.item.statusCode = {};
                        }
                    }).finally(() => {
                        this.loading=false;
                    });
            }else{
                this.item = this.value

                if(this.$eod.getOrganizationSetting('products.statusCode.listId') && this.$eod.getOrganizationSetting('products.statusCode.listId').value){
                        this.$eod.get('listItems', ['id', 'name', 'threshold'], {
                            orderBy:{
                                "column": "name",
                                "type": "ASC"
                            },
                            where: [
                                {
                                    column: 'listId',
                                    operator: '=',
                                    value: this.$eod.getOrganizationSetting('products.statusCode.listId').value
                                },
                                {
                                    column: 'threshold',
                                    operator: '=',
                                    value: '1'
                                }
                            ]
                        }).then(response => {
                            if(response.data.data.listItems && response.data.data.listItems.edges && response.data.data.listItems.edges[0]){
                                this.item.statusCode = response.data.data.listItems.edges[0];
                            }
                        })
                    }
                
            }
        }
        

        // QR code
        QRCode.toDataURL(window.location.href).then(result => {
            this.qr_code = result;
        });
    },
    watch:{
        loading(val){
            this.$emit('loading', val);
        },
        item: {
            deep: true,
            handler() {
                if (!this.loading && !this.isSaveAlertVisible) {
                    this.isSaveAlertVisible = true;
                }
            }
        }
    },
    methods:{
        updateLocation(address){
            if(!address){
                return false;
            }

            if(address.road){
                this.item.location.streetAddress = address.road;

                if(address.house_number){
                    this.item.location.streetAddress += ' ' + address.house_number;
                }
            }
            
            if(address.country){
                this.item.location.country = address.country;
            }

            if(address.city){
                this.item.location.locality = address.city;
            }
            if(address.town){
                this.item.location.locality = address.town;
            }
            if(address.village){
                this.item.location.locality = address.village;
            }
            
            if(address.postcode){
                this.item.location.postalCode = address.postcode;
            }

            if(address.state){
                this.item.location.region = address.state;
            }

            this.$forceUpdate();
        },
        parentChanged(parentId){
            this.item.project = null;
            this.item.serviceId = null;

            if(parentId){
                this.$eod.getById('treeObject', parentId, ['id','class', 'parents{id name class}'])
                    .then(response => {
                        
                        if(response.data.data && response.data.data.treeObject){
                            const toCheck = [response.data.data.treeObject];
                            if( Array.isArray(response.data.data.treeObject.parents)){
                                toCheck.push(...response.data.data.treeObject.parents);
                                for (let i = 0; i < toCheck.length; i++) {
                                    const parent = toCheck[i];
                                    if(parent.class == 'PROJECT'){
                                        this.$eod.getById('project', parent.id, ['id', 'name','locations{name description streetAddress postalCode locality region country lat lon}'])
                                            .then(response => {
                                                if(response.data.data && response.data.data.project){
                                                    this.item.project =  response.data.data.project;
                                                }
                                            });
                                    } else if (parent.class == 'COMPANY'){
                                        this.$eod.getById('company', parent.id, ['id', 'defaultService{id}', 'name','locations{name description streetAddress postalCode locality region country lat lon}'])
                                            .then(response => {
                                                
                                                if(response.data.data && response.data.data.company){
                                                    this.item.company = response.data.data.company;
                                                    if(response.data.data.company.defaultService){
                                                        this.item.serviceId = response.data.data.company.defaultService.id;
                                                    }
                                                    this.$forceUpdate();
                                                }
                                            });
                                    }
                                }
                            }
                            
                        }
                    });
            }
        },
        createProductType(){
            if(this.$refs.productTypeForm){
                const valid = this.$refs.productTypeForm.validate();

                if(!valid){
                    this.typeFormErrors = [];
                    this.$refs.productTypeForm.inputs.forEach(e => {
                        if (e.errorBucket && e.errorBucket.length) {
                            this.typeFormErrors.push(...e.errorBucket);
                        }
                    });

                    return false;
                }

                this.$eod.save('ProductType', this.newProductType).then((response) => {
                    if(response.data && response.data.data && response.data.data.createProductType){
                        this.item.productType = response.data.data.createProductType;
                        this.isCreateTypeDialogVisible = false;
                        this.newProductType = {};
                    }
                });
            }
        },
        getFormErrors() {
            const errors = [];
            this.$refs.form.inputs.forEach(e => {
                if (e.errorBucket && e.errorBucket.length) {
                    errors.push(...e.errorBucket);
                }
            });
            return errors;
        },
        saveItem(){
            this.errors = null;
            if(!this.$refs.form){
                return false;
            }

            const validation = this.$refs.form.validate();
            if(!validation){
                console.error('Error!', this.getFormErrors());
                this.errors = this.getFormErrors();
                return false;
            }

            this.saving = true;

            const data = (new Product(this.item)).getSaveData();
            this.$eod.save('Product', data)
                .then(response => {
                    if(response && response.data.data){
                        if(response.data.data.createProduct){
                            this.item.id = response.data.data.createProduct.id;
                            this.$emit('saved', response.data.data.createProduct.id);
                        }else if (response.data.data.updateProduct){
                            this.item.id = response.data.data.updateProduct.id;
                            this.$emit('saved', response.data.data.updateProduct.id);
                        }

                        this.isSavedSnackbarVisible = true;
                    }
                })
                .finally(() => {
                    this.isSaveAlertVisible = false;
                    this.saving = false;
                });
        },
    }
}
</script>
<template>
    <div ref="item" class="eod-scheduler__item draggable"
        :class="{ 'eod-scheduler__item-unavailable': !isProjectAvailable || !available, 'eod-scheduler__item-proposal': value.stepType == 'PLAN', 'eod-scheduler__item-inactive': value.isJobActive == false }"
        :style="'left:' + (offsetX + (colWidth * columnIndex)) + 'px;width:' + (width * colWidth) + 'px;top:' + (rowY + (value.heightIndex * height)) + 'px;height:' + (height - 1) + 'px;z-index:' + zIndex + ';' + (value.visible != false ? '' : 'opacity:0.2')">
        <v-menu :disabled="dragging" open-on-hover close-delay="500" offset-y top left nudge-left="20" :close-on-content-click="false" max-width="400">
            <template v-slot:activator="{ on, attrs }">
                <div class="eod-scheduler__item-title single-line draggable"
                    :style="'background-color:' + style.background + ';'" v-bind="attrs" v-on="on">
                    <strong class="draggable">{{ value.parent.name }}</strong>
                    <div class="single-line draggable" v-if="dense && value.parent.project">
                        <v-icon class="draggable" v-if="!isProjectAvailable || !available" color="white" small>mdi-alert-circle-outline</v-icon>
                        {{ value.parent.project.name }}</div>
                    <div class="single-line draggable" v-else-if="!dense">
                        <v-icon v-if="!isProjectAvailable || !available" color="white" small>mdi-alert-circle-outline</v-icon>
                        {{ $moment(value.dueAfterDate).format('HH:mm') }} -
                        {{ $moment(value.dueBeforeDate).format('HH:mm') }}</div>
                </div>
            </template>
            <eod-card>
                <div :style="'color:#ffffff;background-color:' + style.background + ';'">
                    <div class="eod-scheduler__item-popup__title px-4 py-2">
                        <div class="flex-grow-1" @click="$emit('workflowClicked', value)">
                            <div class="font-weight-bold text-subtitle-2">{{ value.parent.name }}</div>
                            <div><v-icon dark small class="mr-1">mdi-clock-outline</v-icon>{{ $moment(value.dueAfterDate).format('HH:mm') }} -
                            {{ $moment(value.dueBeforeDate).format('HH:mm') }}</div>
                        </div>
                    </div>
                </div>
                <div class="px-4 py-2">
                    <v-chip v-if="!isProjectAvailable" class="mb-1" @click="$emit('showProjectAvailability', value.parent.project)" small color="warning"><v-icon dark small class="mr-1">mdi-alert-circle</v-icon>Project is niet beschikbaar op gekozen tijdstip!</v-chip>
                    <v-chip v-if="!available" class="mb-1" @click="$emit('showItemAvailability', row)" small color="warning"><v-icon dark small class="mr-1">mdi-alert-circle</v-icon>{{view.itemTitle}} is niet beschikbaar op gekozen tijdstip!</v-chip>
                    <div v-if="value.parent.project">
                        <v-chip color="grey lighten-4" small @click="isProjectDialogVisible = true"><v-icon small left>mdi-folder-open-outline</v-icon>{{ value.parent.project.name }}</v-chip>
                        <div v-if="value.parent.project.locations && value.parent.project.locations[0]" class="d-flex align-items-start px-3 py-1">
                            <v-icon small class="mr-1">mdi-map-outline</v-icon>
                            <div>
                            <div class="single-line">{{ value.parent.project.locations[0].streetAddress }}</div>
                            <div class="single-line">{{ value.parent.project.locations[0].postalCode }}
                                    {{ value.parent.project.locations[0].locality }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <v-chip class="mb-1" color="grey lighten-4" small @click="isCompanyDialogVisible = true" v-if="value.parent.company"><v-icon small left>mdi-office-building-outline</v-icon>{{ value.parent.company.name }}</v-chip><br>
                    <v-chip color="grey lighten-4" small @click="isServiceDialogVisible = true" v-if="value.parent.service"><v-icon small left>mdi-file-sign</v-icon>{{ value.parent.service.name }}</v-chip>
                    
                </div>
                <div class="d-flex grey px-2 py-1" :id="'vehicleMenu_'+value.id">
                            <v-tooltip top>
                                <template v-slot:activator="{attrs, on}">
                                    <v-btn @click="$emit('startNewWorkflow', value)" v-on="on" v-bind="attrs" dark icon depressed small><v-icon small>mdi-clipboard-plus-outline</v-icon></v-btn>
                                </template>
                                Nieuwe workflow met zelfde gegevens starten
                            </v-tooltip>
                            <v-tooltip top v-if="showRoute && value.route && value.route.date">
                                <template v-slot:activator="{attrs, on}">
                                    <v-btn v-on="on" v-bind="attrs" @click="gotoRoute(value.route)" dark icon small><v-icon small>mdi-map-marker-path</v-icon></v-btn>
                                </template>
                                Route bekijken
                            </v-tooltip>
                        </div>
            </eod-card>
        </v-menu>

        <eod-dialog cancel-text="Sluiten" hide-ok width="600" icon="mdi-folder-open-outline" iconColor="primary" :title="value.parent.project.name" v-if="isProjectDialogVisible" v-model="isProjectDialogVisible"><eod-project-info :value="value.parent.project.id"></eod-project-info></eod-dialog>
        <eod-dialog cancel-text="Sluiten" hide-ok width="600" icon="mdi-office-building-outline" iconColor="primary" :title="value.parent.company.name" v-if="isCompanyDialogVisible" v-model="isCompanyDialogVisible"><eod-company-info :value="value.parent.company.id"></eod-company-info></eod-dialog>
        <eod-dialog cancel-text="Sluiten" hide-ok width="600" icon="mdi-file-sign" iconColor="primary" :title="value.parent.service.name" v-if="isServiceDialogVisible" v-model="isServiceDialogVisible"><eod-service-info :value="value.parent.service.id"></eod-service-info></eod-dialog>

        <div style="pointer-events:none;">
            <div class="eod-scheduler__item-content">
                <slot name="content" v-bind:job="value" v-if="value.parent">
                    <template v-if="dense">
                        <div class="single-line">{{ $moment(value.dueAfterDate).format('HH:mm') }} -
                        {{ $moment(value.dueBeforeDate).format('HH:mm') }}</div>
                    </template>
                    <template v-else>
                        <div class="d-flex"
                            v-if="value.parent.project && value.parent.project.locations && value.parent.project.locations[0]">
                            <div><v-icon small class="mr-1">mdi-map-outline</v-icon></div>
                            <div>
                            <div class="single-line">{{ value.parent.project.locations[0].streetAddress }}</div>
                            <div class="single-line">{{ value.parent.project.locations[0].postalCode }}
                                {{ value.parent.project.locations[0].locality }}</div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <v-icon small class="mr-1">mdi-folder-open-outline</v-icon>
                            <span class="d-block single-line">{{ value.parent.project.name }}</span>
                        </div>

                    </template>
                </slot>
            </div>
        </div>
        <div ref="itemResizeHandler" v-if="value.isJobActive && !value.mustBePlannedOnRequestDate"
            class="eod-scheduler__item__resize">
            <v-icon>mdi-drag-vertical</v-icon>
        </div>
        <div v-if="value.stepType == 'PLAN' && value.responsibleUsers && value.responsibleUsers[0]" class="eod-scheduler__item-proposal__actions d-flex align-items-center px-1">
            <v-tooltip bottom v-if="$eod.getOrganizationSetting('planning.optimizeWaypoints') && $eod.getOrganizationSetting('planning.optimizeWaypoints').value == true">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="$emit('cancelItem', value)" icon color="error" v-bind="attrs" v-on="on"><v-icon
                            small>mdi-cancel</v-icon></v-btn>
                </template>
                <span>Afwijzen</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="$emit('acceptItem', value)" icon color="success" v-bind="attrs" v-on="on"><v-icon
                            small>mdi-check</v-icon></v-btn>
                </template>
                <span>Accepteren</span>
            </v-tooltip>
        </div>
        <div v-if="loading" class="eod-scheduler__item-loader">
            <v-progress-circular indeterminate color="white"></v-progress-circular>
        </div>
    </div>
</template>
<style lang="scss">
.eod-scheduler__item-popup__title{
    transition: .2s all;
        &:hover{
            cursor: pointer;
            background-color: rgba(240,240,240,0.2);
        }
    }

.eod-scheduler__item {
    position: absolute;

    //color: #ffffff;
    border-radius: 8px;
    font-size: .8rem;
    user-select: none;
    background-color: #ffffff;
    box-shadow: 0 0 1px #888888;
    //border: 1px solid #dddddd;
    overflow: hidden;

    .eod-scheduler__item-loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
    }

    &.eod-scheduler__item-unavailable{
        border: 1px solid #FF0000;
    }

    &.eod-scheduler__item-inactive {
        opacity: 0.4;

        .eod-scheduler__item-title {
            background-color: #444444 !important;
        }

        // &:hover {
        //     box-shadow: none;
        //     cursor: default;
        // }
    }

    &.eod-scheduler__item-proposal {
        .eod-scheduler__item-title {
            background-color: var(--v-warning-base) !important;
            color: #000000;
        }

        .eod-scheduler__item-content,
        .eod-scheduler__item-title,
        .eod-scheduler__item-actions {
            opacity: 0.4;
        }

        .eod-scheduler__item-proposal__actions {
            position: absolute;
            left: 0;
            height: 30px;
            bottom: 0;
            width: 100%;
            background: #EFEFEf;
            display: flex;
        }

        .eod-scheduler__item-actions {
            display: none;
        }
    }

    .eod-scheduler__item-title {
        padding: 5px;
        color: #ffffff;
        line-height: .9rem;
    }

    .eod-scheduler__item-content {
        padding: 5px 10px 5px 10px;
    }

    .eod-scheduler__item-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background-color: #EFEFEF;

        .eod-scheduler__item-actions-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .eod-scheduler__item__resize {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        height: 100%;
        width: 0;
        background: rgba(0, 0, 0, .2);
        border-radius: 0 8px 8px 0;
        z-index: 4;
        opacity: 0;
        transition: .2s all;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: ew-resize;
        }

        i {
            pointer-events: none;
        }
    }

    &:hover {
        box-shadow: 1px 2px 10px rgba(0, 0, 0, .2);
        cursor: grab;

        .eod-scheduler__item__resize {
            opacity: 1;
            width: 10px;
        }
    }
}
</style>
<script>
import eodProjectInfo from './eod-project-info.vue';
import eodServiceInfo from './eod-service-info.vue';
import eodCompanyInfo from './eod-company-info.vue';
import eodDialog from './eod-dialog.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import Project from '../models/project';

export default {
    name: 'eod-scheduler-item',
    components: {
        eodProjectInfo,
        eodServiceInfo,
        eodCompanyInfo,
        eodDialog,
        eodObjectAutocomplete
    },
    props: {
        value: Object,
        offsetX: Number,
        width: Number,
        offsetY: Number,
        height: Number,
        rowIndex: Number,
        columnIndex: Number,
        colWidth: Number,
        rowY: Number,
        row: Object,
        view: Object,
        available: Boolean,
        loading: {
            type: Boolean,
            default: false
        },
        itemStyle: {
            type: Object,
            default: null
        },
        locking: {
            type: Boolean,
            default: false
        },
        showRoute: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            zIndex: 1,
            dragging:false,
            style: {
                background: '#dddddd'
            },
            originalPos: {},
            isProjectDialogVisible: false,
            isCompanyDialogVisible: false,
            isServiceDialogVisible: false,
            project: null,
            isProjectAvailable: true,
        }
    },
    created() {
        if(this.value.stepType == 'PLAN'){
            this.style.background = 'var(--v-warning-base)';
        } else if (this.value.isJobActive == false) {
            this.style.background = '#444444';
        } else if (this.itemStyle) {
            this.style = this.itemStyle;
        } else {
            if (this.value && this.value.parent && this.value.parent.taskType) {
                if (this.value.parent.taskType.configuration && this.value.parent.taskType.configuration.settings) {
                    const setting = this.$helper.getConfigurationSetting(this.value.parent.taskType.configuration.settings, 'color');
                    if (setting) {
                        this.style.background = setting.value;
                    }
                }
            }
        }
        
        if(this.view.value != 'projects' && this.value && this.value.parent && this.value.parent.project){
            this.project = new Project(this.value.parent.project);
            this.isProjectAvailable = this.project.isAvailable(this.value.dueAfterDate, this.value.dueBeforeDate);
            
        }

    },
    mounted() {
        if (this.$refs.item) {
            this.$refs.item.removeEventListener('mousedown', this.mouseDown);
            this.$refs.item.addEventListener('mousedown', this.mouseDown);

            if (!this.value.mustBePlannedOnRequestDate) {
                if(this.$refs.itemResizeHandler){
                    this.$refs.itemResizeHandler.removeEventListener('mousedown', this.resizeDown);
                    this.$refs.itemResizeHandler.addEventListener('mousedown', this.resizeDown);
                }
                
                this.$refs.item.removeEventListener('contextmenu', this.contextmenu);
                this.$refs.item.addEventListener('contextmenu', this.contextmenu);
            }

        }
    },
    methods: {
        gotoRoute(route) {
            const routeDate = this.$moment.unix(route.date/1000);
            this.$router.push({ name: 'tasks.routes', query: { time_range: routeDate.startOf('day').unix()+'|'+routeDate.endOf('day').unix() } });
        },
        setInitialPos(e, rect) {

            this.originalPos = {
                rectLeft: rect.left,
                rectTop: rect.top,
                elementTop: e.pageY - rect.top,
                elementLeft: e.pageX - rect.left,
                originalColumnIndex: this.columnIndex,
                originalRowIndex: this.rowIndex
            }

            this.zIndex = 2;
        },
        contextmenu(e) {
            this.$emit('contextmenu', e);
        },
        refresh(){
            if(this.view.value != 'projects' && this.project){
                this.isProjectAvailable = this.project.isAvailable(this.value.dueAfterDate, this.value.dueBeforeDate);
            }
        },
        mouseDown(e) {
            e.preventDefault();
            if (e.target.className.includes('draggable') && e.which == 1) { // e.which = 1 -> left mouse button
                const rect = e.target.getBoundingClientRect();
                this.setInitialPos(e, rect);

                window.document.removeEventListener('mouseup', this.mouseUp);
                window.document.addEventListener('mouseup', this.mouseUp);

                if (!this.value.mustBePlannedOnRequestDate && this.value.isJobActive !== false) {
                    window.document.removeEventListener('mousemove', this.mouseMove);
                    window.document.addEventListener('mousemove', this.mouseMove);
                }
            }
        },
        mouseMove(e) {
            this.dragging = true;
            this.$emit('drag', e, this.originalPos);
        },
        mouseUp(e) {
            window.document.removeEventListener('mousemove', this.mouseMove);
            window.document.removeEventListener('mouseup', this.mouseUp);

            this.zIndex = 1;

            this.dragging = false;

            this.$emit('dragEnd', e, this.originalPos);
        },
        resizeDown(e) {
            e.preventDefault();
            if (e.target.className == 'eod-scheduler__item__resize' && e.which == 1) {
                // Start resizing and emit dragEnd to save
                const rect = e.target.parentElement.getBoundingClientRect();
                this.setInitialPos(e, rect);

                window.document.removeEventListener('mouseup', this.resizeUp);
                window.document.addEventListener('mouseup', this.resizeUp);

                window.document.removeEventListener('mousemove', this.resizeMove);
                window.document.addEventListener('mousemove', this.resizeMove);
            }
        },
        resizeMove(e) {

            let newWidth = Math.round((e.pageX - this.originalPos.rectLeft) / this.colWidth);
            if (newWidth <= 0) {
                newWidth = 1
            }

            this.$emit('resize', newWidth);
        },
        resizeUp(e) {
            window.document.removeEventListener('mouseup', this.resizeUp);
            window.document.removeEventListener('mousemove', this.resizeMove);

            this.zIndex = 1;

            this.$emit('resizeEnd');
        }
    }
}
</script>